import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";

const pdfjsVersion = "2.16.105";
GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.js`;

const getPDFPageCount = async (file) => {
  const fileURL = URL.createObjectURL(file);
  const doc = await getDocument({ url: fileURL }).promise;
  return doc.numPages;
};

export default getPDFPageCount;
