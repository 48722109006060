import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { animateCss } from "../../../pages/TosterAlert";
import {
  allUserRoleRouteAction,
  getUsersRoleRouterAction,
  newUserRoleRouteAction,
  singleUserRoleRouteAction,
  updateUserRoleRouteAction,
} from "../../actions/UserRouters/UserRoleRouteAction";
import { useDispatch } from "react-redux";

const userRoleRouteSliceIntial = {
  route: [],
  landingPage: [],
  allRoleRoute: [],
  editUserRole: [],
  newUserRole: [],
  listLoading: false,
  createRoleRoute: {
    loading: false,
    error: "",
  },
};

const usersRoleRouteSlice = createSlice({
  name: "userRoleRouters",
  initialState: userRoleRouteSliceIntial,
  reducers: {
    resetRoleRoute: (state, { payload }) => {
      state = userRoleRouteSliceIntial;
      return state;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getUsersRoleRouterAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getUsersRoleRouterAction.fulfilled, (state, action) => {
        state.loading = false;
        state.route = action.payload.data;
        state.landingPage = action.payload.landingPage;
        return state;
      })
      .addCase(getUsersRoleRouterAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(allUserRoleRouteAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(allUserRoleRouteAction.fulfilled, (state, action) => {
        state.loading = false;
        state.allRoleRoute = action.payload;
        return state;
      })
      .addCase(allUserRoleRouteAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(singleUserRoleRouteAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(singleUserRoleRouteAction.fulfilled, (state, action) => {
        state.loading = false;
        state.editUserRole = action.payload;
        return state;
      })
      .addCase(singleUserRoleRouteAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateUserRoleRouteAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(updateUserRoleRouteAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.data) {
          animateCss("success", "Updated Successfully");
        } else if (!action.payload.data) {
          animateCss("error", "Role Route Not Update");
        }

        return state;
      })
      .addCase(updateUserRoleRouteAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(newUserRoleRouteAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(newUserRoleRouteAction.fulfilled, (state, action) => {
        state.loading = false;
        state.newUserRole = action.payload.data;
        return state;
      })
      .addCase(newUserRoleRouteAction.rejected, (state, action) => {
        state.loading = false;
      }),
});

export const { resetRoleRoute } = usersRoleRouteSlice.actions;
export default usersRoleRouteSlice.reducer;
