import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Button,
  IconButton,
  styled,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  allotmentOrganizationCaseAction,
  allotmentOrganizationCaseTemplateAction,
  deleteAllotmentDeleteCaseDetailsAction,
  selectAllotmentDeleteCaseDetailsAction,
} from "../../../redux/actions/allotmentDocuments/allotmentDocumentsAction";
import { api } from "../../../api/api_route";
import { SiMicrosoftexcel } from "react-icons/si";
import { MdDelete, MdOutlineBackupTable } from "react-icons/md";
import { SnackbarProvider, useSnackbar } from "notistack";
import * as XLSX from "xlsx";
import { animateCss } from "../../TosterAlert";

const tableStyle = {
  WebkitAlignItems: "flex-start",
  WebkitBoxAlign: "flex-start",
  MsFlexAlign: "flex-start",
  alignItems: "flex-start",
  boxSizing: "border-box",
  display: "-webkit-box",
  display: "-webkit-flex",
  display: "-ms-flexbox",
  display: "flex",
  gap: "0.5rem",
  WebkitBoxPack: "justify",
  WebkitJustifyContent: "space-between",
  justifyContent: "flex-end",
  padding: "0.5rem",
  position: "relative",
  right: 0,
  top: 0,
  width: "100%",
};

const dateFormate = (dateString) => {
  const date = new Date(dateString);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

const columnHelper = createMRTColumnHelper();

const AllotmentCaseDetails = () => {
  const [newData, setNewData] = useState([]);

  const dispatch = useDispatch();

  const allotmentcaseTemplate = useSelector(
    (state) => state?.allotmentDocument?.allotment?.caseTemplate
  );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let notificationCount = 0;
  let change;

  useEffect(() => {
    setTimeout(() => {
      dispatch(allotmentOrganizationCaseAction());
    }, 2000);
  }, [dispatch, change]);

  useEffect(() => {
    dispatch(allotmentOrganizationCaseAction());
  }, []);

  const columns = [
    columnHelper.accessor("allotmentDocDate", {
      size: 120,
      id: "Date",
      header: "Date",
      filterVariant: "date-range",
      accessorFn: (originalRow) => new Date(originalRow.allotmentDocDate),
      Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
    }),
    columnHelper.accessor("organization.organizationName", {
      header: "Organization Name",
      size: 180,
    }),

    columnHelper.accessor("documentNewName", {
      header: "Download File",
      size: 200,
      Cell: ({ row }) => {
        return (
          <a
            href={`${api}/documents/allotmentDocument/${row.original.documentNewName}`}
            download="allotmentDocument.xlsx"
            style={{ textDecoration: "none" }}
          >
            {row.original.documentName}
          </a>
        );
      },
    }),
    columnHelper.accessor("creator.name", {
      header: "Created By",
      size: 180,
    }),
    columnHelper.accessor("updator.name", {
      header: "Last Modify By",
      size: 180,
    }),

    {
      accessorKey: "updatedAt",
      size: 180,
      id: "updatedAt",
      header: "Last Modify Date",
      filterVariant: "date-range",
      accessorFn: (originalRow) => new Date(originalRow.updatedAt),
      Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
    },
    {
      accessorKey: "isActive",
      header: "Action",
      size: 180,
      Cell: ({ row }, rowIndex) => {
        return (
          <Box className="px-2" display="flex" justifyContent="start">
            <Tooltip
              title="Allotment Delete"
              onClick={async () => {
                if (window.delete) {
                  change = true;
                  const action = dispatch(
                    selectAllotmentDeleteCaseDetailsAction({
                      allotmentID: row.original?.allotmentID,
                    })
                  );
                  const result = await action.unwrap();

                  const apiValues = result.data;

                  if (apiValues?.[0]?.allotmentDocumentID) {
                    enqueueSnackbar(
                      <>
                        <div>
                          Are you sure you want to delete this Allotment details
                          along with case reference ID’s from{" "}
                          {apiValues?.[0]?.minCaseDetailsID} to{" "}
                          {apiValues?.[0]?.maxCaseDetailsID} ?
                        </div>
                      </>,
                      {
                        variant: "warning",
                        onClose: () => {},
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        action: (key) => (
                          <>
                            <StyledButton
                              onClick={async () => {
                                try {
                                  const action = await dispatch(
                                    deleteAllotmentDeleteCaseDetailsAction({
                                      allotmentID:
                                        apiValues?.[0]?.allotmentDocumentID,
                                    })
                                  );

                                  const result = await action;

                                  if (result) {
                                    dispatch(allotmentOrganizationCaseAction());
                                  }
                                  closeSnackbar(key);
                                } catch (err) {}
                              }}
                            >
                              Confirm
                            </StyledButton>
                            <StyledButton
                              onClick={() => {
                                closeSnackbar(key);
                              }}
                            >
                              Cancel
                            </StyledButton>
                          </>
                        ),
                        autoHideDuration: null,
                        style: {
                          backgroundColor: "#f44336",
                          width: "600px",
                          color: "#fff",
                        },
                      }
                    );
                  } else if (!apiValues?.[0]?.allotmentDocumentID) {
                    enqueueSnackbar(
                      <>
                        <div>
                          There are no case ID's associated with this Allotment.
                          Are you sure you want to delete only the allotment
                          details?
                        </div>
                      </>,
                      {
                        variant: "warning",
                        onClose: () => {},
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        action: (key) => (
                          <>
                            <StyledButton
                              onClick={async () => {
                                try {
                                  const action = await dispatch(
                                    deleteAllotmentDeleteCaseDetailsAction({
                                      allotmentID: row.original?.allotmentID,
                                    })
                                  );

                                  const result = await action;

                                  if (result) {
                                    dispatch(allotmentOrganizationCaseAction());
                                  }
                                  closeSnackbar(key);
                                } catch (err) {}
                              }}
                            >
                              Confirm
                            </StyledButton>
                            <StyledButton
                              onClick={() => {
                                closeSnackbar(key);
                              }}
                            >
                              Cancel
                            </StyledButton>
                          </>
                        ),
                        autoHideDuration: null,
                        style: {
                          backgroundColor: "#f44336",
                          width: "600px",
                          color: "#fff",
                        },
                      }
                    );
                  }
                  change = false;
                } else {
                  animateCss("info", "You are not authorized to delete");
                }
              }}
            >
              <IconButton size="large" variant="circular" edge="start">
                <MdDelete className="text-danger" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const AllotmentCase = useSelector(
    (state) => state.allotmentDocument.allotment.allData
  );

  const exportData = async (rows) => {
    try {
      const tableData = await Promise.all(
        rows.map(async (item, index) => {
          return item.original;
        })
      );

      const valuesArray = tableData.map((data) => ({
        Date: dateFormate(data.allotmentDocDate),
        "Organization Name": data.organization.organizationName,
        "Document Name": data.documentName,
        "Created By": data?.creator?.name,
        "Last Modify By": data?.updator.name,
        "Last Modify Date": dateFormate(data?.updatedAt),
      }));

      const ws = XLSX.utils.json_to_sheet(valuesArray);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Allotment");
      XLSX.writeFile(wb, "Case_Allotment_Report.xlsx");
    } catch (error) {
      console.error("Export failed:", error);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: AllotmentCase ?? [],
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    enableFullScreenToggle: false,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    enableRowVirtualization: true,
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableStickyHeader: true,
    enableGlobalFilter: true,
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
    },
    keyAccessor: (row, rowIndex) =>
      row.orderStatus + "_" + row.id + "_" + rowIndex,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box style={tableStyle}>
        <Button
          onClick={async () => {
            await exportData(table.getPrePaginationRowModel().rows);
          }}
          startIcon={<MdOutlineBackupTable className="text-success" />}
        >
          Export
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};

export default AllotmentCaseDetails;
