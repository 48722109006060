import { createPDF, splitPDF, zipToBlob, pdfArrayToBlob } from "pdf-actions";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { PDFDocument } from "pdf-lib";

export const splitPDFHandler = async (files, asZip = true) => {
  let zip;
  if (asZip) {
    zip = new JSZip();
  }
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (file.deleted) {
      continue;
    }
    const pdfDocument = await createPDF.PDFDocumentFromFile(file);
    const split = await splitPDF(pdfDocument, file.splitRange, file.degrees);
    if (typeof split !== String) {
      const pdfFile = await split.save();
      if (asZip) {
        zip.file(`split-${file.name}`, pdfFile);
      } else {
        const pdfBlob = pdfArrayToBlob(pdfFile);
        saveAs(pdfBlob, `split-${file.name}`);
      }
    } else {
      alert("Error In Split Ranges");
    }
  }
  if (asZip) {
    const zipBlob = await zipToBlob(zip);
    saveAs(zipBlob, "splittedPDFFiles.zip");
  }
};

const mergePDFs = async (files) => {
  try {
    const mergedPdf = await PDFDocument.create();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const pdfBytes = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);

      let pageDegrees = file.degrees || 0;

      const pageCount = pdfDoc.getPageCount();
      for (let pageIndex = 0; pageIndex < pageCount; pageIndex++) {
        const [page] = await mergedPdf.copyPages(pdfDoc, [pageIndex]);

        if (pageDegrees !== 0 && pageDegrees >= 0 && pageDegrees <= 360) {
          page.setRotation(degreesToRadians(pageDegrees));
        } else {
          console.warn(
            `Invalid rotation angle ${pageDegrees} degrees. Skipping rotation.`
          );
        }

        mergedPdf.addPage(page);
      }
    }

    const mergedPdfBytes = await mergedPdf.save();

    return new Blob([mergedPdfBytes], { type: "application/pdf" });
  } catch (error) {
    console.error("Error merging PDF files:", error);
    throw error;
  }
};

const degreesToRadians = (degrees) => {
  return (degrees * Math.PI) / 180;
};

export const handleMergeAndDownload = async (files) => {
  try {
    const mergedBlob = await mergePDFs(files);

    const link = document.createElement("a");
    link.href = URL.createObjectURL(mergedBlob);
    link.download = "mergedPDFFile.pdf";
    link.click();
  } catch (error) {
    console.error("Failed to merge and download PDF files:", error);
  }
};
