import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  allCaseGrouping,
  createCaseGrouping,
  deleteCaseGrouping,
  updateCaseGrouping,
} from "../../../api/templateGrouping_api";

export const createCaseGroupingAction = createAsyncThunk(
  "caseGrouping/createCaseGroupingAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const checklist = await createCaseGrouping(values);
      return checklist;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const allCaseGroupingAction = createAsyncThunk(
  "caseGrouping/allCaseGroupingAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await allCaseGrouping();
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateCaseGroupingAction = createAsyncThunk(
  "caseGrouping/updateCaseGroupingAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateCaseGrouping(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const deleteCaseGroupingAction = createAsyncThunk(
  "caseGrouping/deleteCaseGroupingAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await deleteCaseGrouping(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
