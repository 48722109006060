import { createSlice } from "@reduxjs/toolkit";
import { animateCss } from "../../../pages/TosterAlert";
import {
  allotmentDocumentUploadAction,
  allotmentOrganizationCaseAction,
  allotmentOrganizationCaseTemplateAction,
  deleteAllotmentDeleteCaseDetailsAction,
  selectAllotmentDeleteCaseDetailsAction,
  selectAllotmentOrganizationCaseDetailsAction,
} from "../../actions/allotmentDocuments/allotmentDocumentsAction";
import { Button, styled } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { deleteAllotmentDeleteCaseDetails } from "../../../api/allotmentDocuments_api";

const allotmentDocumentInitialState = {
  allotment: {
    create: [],
    update: [],
    delete: [],
    allData: [],
    caseTemplate: [],
    loading: false,
    error: "",
  },
};

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

const allotmentDocumentSlice = createSlice({
  name: "allotmentDocuments",
  initialState: allotmentDocumentInitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(allotmentDocumentUploadAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(allotmentDocumentUploadAction.fulfilled, (state, action) => {
        if (action.payload.data) {
          animateCss("success", "Allotment Document Upload Successfully");
        }
        state.loading = false;
      })
      .addCase(allotmentDocumentUploadAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(allotmentOrganizationCaseAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(allotmentOrganizationCaseAction.fulfilled, (state, action) => {
        state.allotment.allData = action.payload.data;
        state.loading = false;
      })
      .addCase(allotmentOrganizationCaseAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(
        selectAllotmentOrganizationCaseDetailsAction.pending,
        (state, action) => {
          state.loading = true;
        }
      )
      .addCase(
        selectAllotmentOrganizationCaseDetailsAction.fulfilled,
        (state, action) => {
          state.allotment.allData = action.payload.data;
          state.loading = false;
        }
      )
      .addCase(
        selectAllotmentOrganizationCaseDetailsAction.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      .addCase(
        selectAllotmentDeleteCaseDetailsAction.pending,
        (state, action) => {
          state.loading = true;
        }
      )
      .addCase(
        selectAllotmentDeleteCaseDetailsAction.fulfilled,
        (state, action) => {
          let select = false;
          state.allotment.delete = action.payload.data?.[0];
          state.loading = false;
        }
      )
      .addCase(
        selectAllotmentDeleteCaseDetailsAction.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      .addCase(
        deleteAllotmentDeleteCaseDetailsAction.pending,
        (state, action) => {
          state.loading = true;
        }
      )
      .addCase(
        deleteAllotmentDeleteCaseDetailsAction.fulfilled,
        (state, action) => {
          state.allotment.delete = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        deleteAllotmentDeleteCaseDetailsAction.rejected,
        (state, action) => {
          state.loading = false;
        }
      ),
});

export default allotmentDocumentSlice.reducer;
