import * as React from "react";
import { useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MdOutlineBackupTable } from "react-icons/md";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { animateCss } from "../../TosterAlert";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoDisturbAltOutlinedIcon from "@mui/icons-material/DoDisturbAltOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import * as XLSX from "xlsx";
import {
  allOrganizationAction,
  deleteOrganizationAction,
  isActiveOrganizationAction,
} from "../../../redux/actions/OrganizationAction/OrganizationAction";
import CreateOrganization from "./OrganizationCreateForm";

const UserOrgnization = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [editValue, setEditValue] = useState({});

  const apiValues = useSelector((state) => state?.organization);

  const userId = useSelector((state) => state?.login.data);

  const tableStyle = {
    WebkitAlignItems: "flex-start",
    WebkitBoxAlign: "flex-start",
    MsFlexAlign: "flex-start",
    alignItems: "flex-start",
    boxSizing: "border-box",
    display: "-webkit-box",
    display: "-webkit-flex",
    display: "-ms-flexbox",
    display: "flex",
    gap: "0.5rem",
    WebkitBoxPack: "justify",
    WebkitJustifyContent: "space-between",
    justifyContent: "flex-end",
    padding: "0.5rem",
    position: "relative",
    right: 0,
    top: 0,
    width: "100%",
  };

  useEffect(
    () => {
      dispatch(allOrganizationAction());
    },
    [open] ?? []
  );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const columns = useMemo(() => [
    {
      accessorKey: "organizationName",
      header: "Organization",
      size: 200,
      Cell: ({ row }, rowIndex) => {
        return (
          <Link
            style={{ textDecoration: "none" }}
            to=""
            onClick={() => {
              if (window.edit) {
                setOpen(true);
                setEditValue(row.original);
              } else {
                animateCss("info", "You are not authorized to update");
              }
            }}
          >
            {row?.original?.organizationName}
          </Link>
        );
      },
    },
    {
      accessorKey: "email",
      header: "Email Id",
      size: 250,
    },
    {
      accessorKey: "mobileNo",
      header: "Mobile No",
      size: 250,
    },
    {
      accessorKey: "city",
      header: "City",
      size: 150,
    },
    {
      accessorKey: "state",
      header: "State",
      size: 160,
    },
    {
      accessorKey: "userStatus",
      header: "Status",
      size: 130,
      Cell: ({ row }, rowIndex) => {
        return (
          <Box display="flex" justifyContent="center">
            <Tooltip
              title={
                row?.original?.isActive
                  ? "Active Organization"
                  : "Inactive Organization"
              }
              onClick={() => {
                if (window.edit) {
                  const values = { isActiveData: row?.original, User: userId };
                  const alert = row?.original.isActive ? "error" : "success";
                  const Status = row?.original.isActive
                    ? "Organization Status Inactive"
                    : "Organization Status Active";
                  animateCss(alert, Status, 1000);
                  dispatch(isActiveOrganizationAction(values));
                  setTimeout(() => {
                    dispatch(allOrganizationAction());
                  }, 500);
                } else {
                  animateCss("info", "You are not authorized to update");
                }
              }}
            >
              <IconButton variant="circular" edge="end">
                {row?.original?.isActive ? (
                  <DoneAllIcon color="success" />
                ) : (
                  <DoDisturbAltOutlinedIcon color="error" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      accessorKey: "password",
      header: "Delete",
      size: 150,
      Cell: ({ row }, rowIndex) => {
        return (
          <Box display="flex" justifyContent="center">
            <Tooltip
              title="Delete Organization"
              onClick={async () => {
                dispatch(deleteOrganizationAction(row.original));
                setTimeout(async () => {
                  dispatch(allOrganizationAction());
                }, 1000);
              }}
            >
              <IconButton variant="circular" edge="end">
                <DeleteOutlinedIcon color="error" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ]);

  const exportData = async (rows) => {
    try {
      const tableData = await Promise.all(
        rows.map(async (item, index) => {
          return item.original;
        })
      );

      const valuesArray = tableData.map((data) => ({
        "Organization Name": data.organizationName,
        "Email Id": data.email,
        "Mobile No": data.mobileNo,
        City: data?.city,
        State: data?.state,
        "Contact Person": data?.contactPerson,
      }));

      const ws = XLSX.utils.json_to_sheet(valuesArray);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Users");
      XLSX.writeFile(wb, "Organization_Master.xlsx");
    } catch (error) {
      console.error("Export failed:", error);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: apiValues?.profile ?? [],
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    enableFullScreenToggle: false,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    enableRowVirtualization: true,
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableStickyHeader: true,
    enableGlobalFilter: true,
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
    },
    keyAccessor: (row, rowIndex) =>
      row.orderStatus + "_" + row.id + "_" + rowIndex,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box style={tableStyle}>
        {window.edit && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setEditValue({});
              setOpen(true);
            }}
            sx={{
              width: isSmallScreen ? "100%" : "auto",
              maxWidth: "150px",
              fontSize: "85%",
            }}
          >
            <Link
              className="text-white  text-decoration-none"
              sx={{ width: "100%", display: "inherit" }}
            >
              Add Organization
            </Link>
          </Button>
        )}

        <Button
          onClick={async () => {
            await exportData(table.getPrePaginationRowModel().rows);
          }}
          startIcon={<MdOutlineBackupTable className="text-success" />}
        >
          Export
        </Button>
      </Box>
    ),
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <CreateOrganization
        EditValue={editValue}
        open={open}
        setOpen={() => setOpen(false)}
      />
    </>
  );
};
export default UserOrgnization;
