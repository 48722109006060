import { createSlice } from "@reduxjs/toolkit";
import { animateCss } from "../../../pages/TosterAlert";
import {
  getOrganizationDocumentAction,
  getUserOrganizationDocumentAction,
  organizationAwardsAction,
  organizationDocumentIsActiveAction,
  organizationDocumentUploadAction,
} from "../../actions/OrganizationAction/organizationDocumentAction";
import {
  allOrganizationAction,
  createOrganizationAction,
  deleteOrganizationAction,
  isActiveOrganizationAction,
  updateOrganizationAction,
} from "../../actions/OrganizationAction/OrganizationAction";

const organization = {
  profile: [],
  name: [],
  documents: [],
  listLoading: false,
  createOrganizationDocument: {
    loading: false,
    error: "",
  },
};

const organizationSlice = createSlice({
  name: "organization",
  initialState: organization,
  reducers: {
    resetOrganization: (state, { payload }) => {
      state = { ...state, ...organizationSlice.createOrganizationDocument };
      return state;
    },
  },
  extraReducers: (builder) =>
    builder

      .addCase(allOrganizationAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(allOrganizationAction.fulfilled, (state, action) => {
        state.profile = action.payload?.data;
        state.name = action.payload?.orgName;
        state.loading = false;
        return state;
      })
      .addCase(allOrganizationAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createOrganizationAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createOrganizationAction.fulfilled, (state, action) => {
        if (action.payload.message) {
          animateCss(action?.payload?.alert, action?.payload?.message, 1000);
          state.loading = false;
          return state;
        } else if (!action.payload.message) {
          animateCss("error", "Organization Not Create", 1000);
        }
      })
      .addCase(createOrganizationAction.rejected, (state, action) => {
        animateCss(action?.payload?.alert, action?.payload?.message, 1000);
        state.loading = false;
      })
      .addCase(updateOrganizationAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateOrganizationAction.fulfilled, (state, action) => {
        if (action.payload.message) {
          animateCss(action?.payload?.alert, action?.payload?.message, 1000);
          state.loading = false;
          return state;
        } else if (!action.payload.message) {
          animateCss("error", "Organization Not Create", 1000);
        }
      })
      .addCase(updateOrganizationAction.rejected, (state, action) => {
        animateCss(action?.payload?.alert, action?.payload?.message, 1000);
        state.loading = false;
      })
      .addCase(isActiveOrganizationAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(isActiveOrganizationAction.fulfilled, (state, action) => {
        state.loading = false;
        return state;
      })
      .addCase(isActiveOrganizationAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteOrganizationAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteOrganizationAction.fulfilled, (state, action) => {
        if (action.payload.data) {
          animateCss("success", "Organization Delete Successfully");
        } else if (!action?.payload?.data) {
          animateCss("warn", "Organization Associate to Others");
        }
        state.loading = false;
        return state;
      })
      .addCase(deleteOrganizationAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getOrganizationDocumentAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOrganizationDocumentAction.fulfilled, (state, action) => {
        state.documents = action.payload;
        state.loading = false;
        return state;
      })
      .addCase(getOrganizationDocumentAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getUserOrganizationDocumentAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserOrganizationDocumentAction.fulfilled, (state, action) => {
        state.documents = action.payload;
        state.loading = false;
        return state;
      })
      .addCase(getUserOrganizationDocumentAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(organizationAwardsAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(organizationAwardsAction.fulfilled, (state, action) => {
        state.documents = action.payload;
        state.loading = false;
        return state;
      })
      .addCase(organizationAwardsAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(organizationDocumentUploadAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(organizationDocumentUploadAction.fulfilled, (state, action) => {
        if (action.payload.data.message == "success") {
          animateCss("success", "Organization Document Upload Successfully");
          return state;
        }
      })
      .addCase(organizationDocumentUploadAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(organizationDocumentIsActiveAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        organizationDocumentIsActiveAction.fulfilled,
        (state, action) => {
          if (action.payload.status) {
            animateCss("success", "Organization Document Active");
            return state;
          } else if (!action.payload.status) {
            animateCss("success", "Organization Document Deleted");
            return state;
          }
        }
      )
      .addCase(organizationDocumentIsActiveAction.rejected, (state, action) => {
        state.loading = false;
      }),
});

export const { resetOrganization } = organizationSlice.actions;
export default organizationSlice.reducer;
