import React, { useState, useEffect } from "react";
import imageDataURLfromFile from "../../methods/imageDataURLfromFile";
import styles from "../../styles/PDFFilePreview.module.css";
export default function FilePreview({ file, key, FilePreviewExtra }) {
  const [deleted, setDeleted] = useState(false);

  const fileThumbnailRef = React.createRef();
  const PDFThumbnail = React.forwardRef(({ src, deg }, ref) => {
    const imageSourceSet = async () => {
      const data = await imageDataURLfromFile(src, 1);
      ref.current.src = data;
      ref.current.classList.remove(styles.rotating);
      src.image = data;
    };
    useEffect(() => {
      if (src.image) {
        ref.current.classList.remove(styles.rotating);
        ref.current.src = src.image;
      } else {
        imageSourceSet();
      }
    }, []);

    return (
      <div className="grid place-items-center" key={key}>
        <img
          className={`${styles.rotating} filePreviewThumbnails transition-transform`}
          draggable={false}
          ref={ref}
          style={{
            transform: `rotate(${deg}deg)`,
            width: "120px",
            height: "150px",
            zIndex: -1,
          }}
        />
      </div>
    );
  });

  return (
    <div
      key={key}
      className={`flex flex-col h-full w-[200px] border-2 py-0 px-4 ${
        deleted ? "hidden" : ""
      }`}
    >
      <span className="z-40 overflow-hidden whitespace-nowrap text-ellipsis relative top-[-10px] left-[-18px] bg-yellow-200 text-gray-600 text-sm">
        {file.name}
      </span>
      <PDFThumbnail src={file} deg={file.degrees || 0} ref={fileThumbnailRef} />
      {FilePreviewExtra && (
        <FilePreviewExtra
          file={file}
          setDeleted={setDeleted}
          imageRef={fileThumbnailRef}
        />
      )}
    </div>
  );
}
