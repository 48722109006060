/* eslint-disable no-empty-pattern */
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  allUserRoutes,
  getUserRoleRoute,
  newUserRoleRoutes,
  singleUserRoutes,
  updateUserRoleRoutes,
} from "../../../api/userRoleRoute_api";

export const getUsersRoleRouterAction = createAsyncThunk(
  "userRoleRoute/getUsersRoleRouterAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await getUserRoleRoute(values);

      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const allUserRoleRouteAction = createAsyncThunk(
  "userRoleRoute/allUserRoleRoute",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await allUserRoutes();

      return users.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const singleUserRoleRouteAction = createAsyncThunk(
  "userRoleRoute/singleUserRoleRouteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await singleUserRoutes(values);

      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateUserRoleRouteAction = createAsyncThunk(
  "userRoleRoute/updateUserRoleRouteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await updateUserRoleRoutes(values);

      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const newUserRoleRouteAction = createAsyncThunk(
  "userRoleRoute/newUserRoleRouteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await newUserRoleRoutes(values);

      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
