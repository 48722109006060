import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Grid,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  singleCaseDetailsAction,
  updateCasePrimaryInformationAction,
} from "../../../redux/actions/Case/caseAction";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { animateCss } from "../../TosterAlert";
import { checklistDocumentUplodOptionsAction } from "../../../redux/actions/checklistDocuments/checklistDocumentsAction";
import { caseChecklistDocumentAction } from "../../../redux/actions/Checklist/checklistAction";

const CasePrimaryInformation = () => {
  // const rowData = {};

  const [editPrimaryInformation, setEditPrimaryInformation] = useState(false);

  const useStyles = makeStyles((theme) => ({
    textarea: {
      width: "100%",
      padding: theme.spacing(2),
      marginTop: "5%",
      fontSize: "1rem",
      borderRadius: "4px",
      border: "1px solid #ccc",
      resize: "vertical",
      minHeight: "100px",
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  function trackerFormatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  const { id } = useParams();
  const params = { caseID: id };
  const user = useSelector((state) => state.login.data);
  const settings = useSelector((state) => state?.ChecklistDocument?.settings);

  const rowData = useSelector((state) => state?.case?.editCaseDetails);

  return (
    <Card variant="outlined" className=" mt-3" sx={{ maxWidth: "100%" }}>
      <Box sx={{ p: 2 }}>
        {editPrimaryInformation ? (
          <Formik
            initialValues={{
              caseID: !rowData?.caseID ? null : rowData.caseID,
              caseName: !rowData?.caseID ? null : rowData.caseName,
              caseStatus: !rowData?.caseID
                ? null
                : rowData.caseStatusID.generalSettingsID,
              caseOpenedOn: !rowData?.caseID ? null : rowData.caseOpenedOn,
              caseCloseDate: !rowData?.caseID ? null : rowData.caseCloseDate,
              caseClosingReason: !rowData?.caseID
                ? null
                : rowData.caseClosingReason.generalSettingsID,
              plaintiff_Claimant_Petitioner_Appellant: !rowData?.caseID
                ? null
                : rowData.Plaintiff_Claimant_Petitioner_Appellant,
              poaLoaName: !rowData?.caseID ? null : rowData.poaLoaName,
              advocateForPlaintiff_Claimant_Petitioner_Appellant:
                !rowData?.caseID
                  ? null
                  : rowData.AdvocateForPlaintiff_Claimant_Petitioner_Appellant,
              defendant_Respondent: !rowData?.caseID
                ? null
                : rowData.Defendant_Respondent,
              legalRepresentativeOfDefendantRespondent: !rowData?.caseID
                ? null
                : rowData.LegalRepresentativeOfDefendantRespondent,
              advocateForDefendantRespondent: !rowData?.caseID
                ? null
                : rowData.AdvocateForDefendantRespondent,
              practiceArea: !rowData?.caseID
                ? null
                : rowData.PracticeArea.generalSettingsID,
              caseTypeID: !rowData?.caseID
                ? null
                : rowData.caseType.generalSettingsID,
              previousStage: !rowData?.caseID ? null : rowData.previousStage,
              currentStage: !rowData?.caseID ? null : rowData.currentStage,
              nextStage: !rowData?.caseID ? null : rowData.nextStage,
              courtForum: !rowData?.caseID ? null : rowData.CourtForum,
              jurisdictionType: !rowData?.caseID
                ? null
                : rowData.JurisdictionType.generalSettingsID,
              disputeValue: !rowData?.caseID ? null : rowData.DisputeValue,
              causeOfActionOn: !rowData?.caseID
                ? null
                : rowData.CauseOfActionOn,
              IsTheDisputeClaimWithinTheStatuteOfLimitations: !rowData?.caseID
                ? null
                : rowData.IsTheDisputeClaimWithinTheStatuteOfLimitations,
              remarks: !rowData?.caseID ? null : rowData.remarks,
              modeOfCommunications: !rowData?.caseID
                ? null
                : rowData.ModeOfCommunications.generalSettingsID,
              cityTalukVillage: !rowData?.caseID
                ? null
                : rowData.CityTalukVillage,
              district: !rowData?.caseID ? null : rowData.District,
              state: !rowData?.caseID ? null : rowData.state.generalSettingsID,
              country: !rowData?.caseID ? null : rowData.Country,
              params,
              user,
            }}
            // validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              try {
                dispatch(updateCasePrimaryInformationAction(values));
                setEditPrimaryInformation(false);
                setTimeout(() => {
                  dispatch(singleCaseDetailsAction(params));
                  dispatch(caseChecklistDocumentAction(params));
                }, 1000);
              } catch (error) {
                // Handle submission error if needed
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
              resetForm,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          backgroundColor: "white",
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="m-0 "
                        >
                          Case Primary Information
                        </Typography>

                        <div>
                          <Button
                            type="button"
                            color="primary"
                            onClick={(event) => {
                              // event.stopPropagation();
                              // resetForm();
                              setEditPrimaryInformation(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button color="primary" type="submit">
                            Submit
                          </Button>
                        </div>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        <Box
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "white",
                            flexWrap: "wrap",
                            alignItems: "stretch",
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Number
                              </Typography>

                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="caseID"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                value={values?.caseID}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Name
                              </Typography>

                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="caseName"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                value={values?.caseName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="caseStatus"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Status
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="caseStatus"
                                name="caseStatus"
                                value={values?.caseStatus}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                {settings
                                  ?.find(
                                    (item) => item.categoryName == "Case Status"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Case Opened On
                              </Typography>

                              <DatePicker
                                id="caseOpenedOn"
                                selected={values?.caseOpenedOn}
                                onChange={(date) => {
                                  setFieldValue("caseOpenedOn", date);
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Case Closed On
                              </Typography>

                              <DatePicker
                                id="caseCloseDate"
                                selected={values?.caseCloseDate}
                                onChange={(date) => {
                                  setFieldValue("caseCloseDate", date);
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="caseClosingReason"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Closing Reason
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="caseClosingReason"
                                name="caseClosingReason"
                                value={values?.caseClosingReason}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth // Ensures the Select is 100% width
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName == "Case Closing Reason"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Plaintiff /Claimant / Petitioner / Appellant
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="plaintiff_Claimant_Petitioner_Appellant"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                value={
                                  values?.plaintiff_Claimant_Petitioner_Appellant
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Legal Representative of Plaintiff /Claimant /
                                Petitioner / Appellant
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="poaLoaName"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.poaLoaName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Advocate for Plaintiff /Claimant / Petitioner /
                                Appellant
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="advocateForPlaintiff_Claimant_Petitioner_Appellant"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={
                                  values?.advocateForPlaintiff_Claimant_Petitioner_Appellant
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Defendant / Respondent
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="defendant_Respondent"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.defendant_Respondent}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Legal Representative of Defendant / Respondent
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="legalRepresentativeOfDefendantRespondent"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={
                                  values?.legalRepresentativeOfDefendantRespondent
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Advocate for Defendant / Respondent
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="advocateForDefendantRespondent"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.advocateForDefendantRespondent}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="caseClosingReason"
                                sx={{ textWrap: "balance" }}
                              >
                                Practice Area
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="practiceArea"
                                name="practiceArea"
                                value={values?.practiceArea}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth // Ensures the Select is 100% width
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName == "Practice Area"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="caseClosingReason"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Type
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="caseTypeID"
                                name="caseTypeID"
                                value={values?.caseTypeID}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth // Ensures the Select is 100% width
                              >
                                {settings
                                  ?.find(
                                    (item) => item.categoryName == "Case Type"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Previous Stage
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="previousStage"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.previousStage}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Current Stage
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="currentStage"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.currentStage}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Next Stage
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="nextStage"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.nextStage}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Court/Forum
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="courtForum"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.courtForum}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="caseClosingReason"
                                sx={{ textWrap: "balance" }}
                              >
                                Jurisdiction Type
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="jurisdictionType"
                                name="jurisdictionType"
                                value={values?.jurisdictionType}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth // Ensures the Select is 100% width
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName == "Jurisdiction Type"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Dispute Value
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="disputeValue"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.disputeValue}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Cause of Action On
                              </Typography>

                              <DatePicker
                                id="causeOfActionOn"
                                selected={values?.causeOfActionOn}
                                onChange={(date) => {
                                  setFieldValue("causeOfActionOn", date);
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="caseClosingReason"
                                sx={{ textWrap: "balance" }}
                              >
                                Is the dispute/claim within the Statute of
                                Limitations?
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="IsTheDisputeClaimWithinTheStatuteOfLimitations"
                                name="IsTheDisputeClaimWithinTheStatuteOfLimitations"
                                value={
                                  values?.IsTheDisputeClaimWithinTheStatuteOfLimitations
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth // Ensures the Select is 100% width
                              >
                                <MenuItem value={"Yes"} defaultValue>
                                  Yes
                                </MenuItem>
                                <MenuItem value={"No"} defaultValue>
                                  No
                                </MenuItem>
                              </Select>
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Description
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="remarks"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.remarks}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="caseClosingReason"
                                sx={{ textWrap: "balance" }}
                              >
                                Mode of Communications
                              </InputLabel>

                              <Select
                                labelId="demo-multiple-name-label"
                                id="modeOfCommunications"
                                name="modeOfCommunications"
                                value={values?.modeOfCommunications}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth // Ensures the Select is 100% width
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName == "Document Service"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                City / Taluk / Village
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="cityTalukVillage"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.cityTalukVillage}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                District
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-amount"
                                name="district"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.district}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="state"
                                sx={{ textWrap: "balance" }}
                              >
                                State
                              </InputLabel>

                              <Select
                                labelId="state"
                                id="state"
                                name="state"
                                value={values?.state}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth // Ensures the Select is 100% width
                              >
                                {settings
                                  ?.find((item) => item.categoryName == "State")
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "33%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-amount"
                                sx={{ textWrap: "balance" }}
                              >
                                Country
                              </InputLabel>
                              <OutlinedInput
                                id="country"
                                name="country"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                  textWrap: "balance",
                                }}
                                value={values?.country}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>
                          </LocalizationProvider>
                        </Box>
                      </>
                    </AccordionDetails>
                  </Accordion>
                </form>
              );
            }}
          </Formik>
        ) : (
          //   <CaseTracking
          //     classes={classes}
          //     rowData={caseDetailsData}
          //     settings={settings}
          //     handleFormSubmit={handleFormSubmit}
          //     dispatch={dispatch}
          //     setEditCaseTracker={setEditCaseTracker}
          //     newData={newData}
          //     initialValues={initialValues}
          //     validationSchema={validationSchema}
          //   />
          <>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="m-0 "
                  >
                    Case Primary Information
                  </Typography>

                  <div>
                    <Button
                      type="button"
                      color="primary"
                      onClick={(event) => {
                        event.stopPropagation();
                        if (window.edit) {
                          event.stopPropagation();
                          // resetForm();
                          setEditPrimaryInformation(true);
                        } else {
                          animateCss(
                            "info",
                            "You are not authorized to update"
                          );
                        }
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Number"
                        secondary={rowData?.caseID}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Name"
                        secondary={rowData?.caseName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Status"
                        secondary={rowData?.caseStatusID?.categoryName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Opened On"
                        secondary={
                          rowData?.caseOpenedOn
                            ? trackerFormatDate(new Date(rowData?.caseOpenedOn))
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Closed On"
                        secondary={
                          rowData?.caseCloseDate
                            ? trackerFormatDate(
                                new Date(rowData?.caseCloseDate)
                              )
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Closing Reason"
                        secondary={rowData?.caseClosingReason?.categoryName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Plaintiff /Claimant / Petitioner / Appellant"
                        secondary={
                          rowData?.Plaintiff_Claimant_Petitioner_Appellant
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Legal Representative of Plaintiff /Claimant / Petitioner / Appellant"
                        secondary={rowData?.poaLoaName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Advocate for Plaintiff /Claimant / Petitioner / Appellant"
                        secondary={
                          rowData?.AdvocateForPlaintiff_Claimant_Petitioner_Appellant
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Defendant / Respondent"
                        secondary={rowData?.Defendant_Respondent}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Legal Representative of Defendant / Respondent"
                        secondary={
                          rowData?.LegalRepresentativeOfDefendantRespondent
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Advocate for Defendant / Respondent"
                        secondary={rowData?.AdvocateForDefendantRespondent}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Practice Area"
                        secondary={rowData?.PracticeArea?.categoryName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Type"
                        secondary={rowData?.caseType?.categoryName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Previous Stage"
                        secondary={rowData?.previousStage}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Current Stage"
                        secondary={rowData?.currentStage}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Next Stage"
                        secondary={rowData?.nextStage}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Court/Forum"
                        secondary={rowData?.CourtForum}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Jurisdiction Type"
                        secondary={rowData?.JurisdictionType?.categoryName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Dispute Value"
                        secondary={rowData?.DisputeValue}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Cause of Action On"
                        secondary={
                          rowData?.CauseOfActionOn
                            ? trackerFormatDate(
                                new Date(rowData?.CauseOfActionOn)
                              )
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Is the dispute/claim within the Statute of Limitations?"
                        secondary={
                          rowData?.IsTheDisputeClaimWithinTheStatuteOfLimitations
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Description"
                        secondary={rowData?.remarks}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Mode of Communications"
                        secondary={rowData?.ModeOfCommunications?.categoryName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="City / Taluk / Village"
                        secondary={rowData?.CityTalukVillage}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="District"
                        secondary={rowData?.District}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="State"
                        secondary={rowData?.state?.categoryName}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Country"
                        secondary={rowData?.Country}
                      />
                    </List>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Box>
    </Card>
  );
};

export default CasePrimaryInformation;
