import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { caseMappingDetailUpdateAction } from "../../../redux/actions/Case/caseMappingAction";
import { resetMappingDetailsReducer } from "../../../redux/slices/Case/caseSlice";

const fakeData = [];

export const usStates = ["No", "Yes"];

const Example = (excelData) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [editedUsers, setEditedUsers] = useState({});

  const caseMappingID = useSelector(
    (state) => state.case?.caseMappingDetails?.mappingDetail
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "header",
        header: "Header",
        enableEditing: false,
      },
    ],
    [editedUsers]
  );
  const dispatch = useDispatch();
  const caseDeatilsData = excelData;

  useEffect(() => {
    const template = caseDeatilsData?.[0];

    if (template) {
      fakeData.push(...template);
    }
  }, []);

  const table = useMaterialReactTable(
    {
      columns,
      data: excelData?.excelData?.excelData?.[0] ?? [],
      enableRowNumbers: true,
      rowNumberDisplayMode: "original",
      enableFullScreenToggle: false,
      enableRowSelection: false,
      columnFilterDisplayMode: "popover",
      enableRowVirtualization: true,
      paginationDisplayMode: "pages",
      positionToolbarAlertBanner: "bottom",
      enableStickyHeader: true,
      enableGlobalFilter: true,
      editDisplayMode: "cell" || "table",

      initialState: {
        columnVisibility: { id: false },
        density: "compact",
      },
      keyAccessor: (row, rowIndex) =>
        row.orderStatus + "_" + row.id + "_" + rowIndex,

      createDisplayMode: "table",
      enableEditing: true,

      positionActionsColumn: "last",
      getRowId: (row) => row?.id,
      onCreatingRowCancel: () => setValidationErrors({}),
    },
    [caseDeatilsData]
  );

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

const queryClient = new QueryClient();

const ViewUploadHeader = (excelData) => {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Example excelData={excelData} />
      </QueryClientProvider>
    </div>
  );
};

export default ViewUploadHeader;

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

function validateUser(user) {
  return {
    header: !validateRequired(user.header) ? "Header Name is Required" : "",
    alterHeader: !validateRequired(user.alterHeader)
      ? "Alter Header Name is Required"
      : "",
  };
}
