import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

// project import
import Logo from "./LogoMain";
import config from "../../config";
import { useSelector } from "react-redux";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ sx, to }) => {
  const userRoute = useSelector((state) => state?.userRoleRouters?.landingPage);

  return (
    <ButtonBase
      disableRipple
      component={Link}
      to={userRoute?.[0]?.navPath ?? "/"}
      sx={sx}
    >
      <Stack direction="row" spacing={1} alignItems="start">
        <Logo />
      </Stack>
    </ButtonBase>
  );
};

LogoSection.propTypes = {
  sx: PropTypes.object,
  to: PropTypes.string,
};

export default LogoSection;
