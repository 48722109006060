import axios from "axios";
import { api } from "./api_route";

export const getUserDesignation = async (values) => {
  try {
    const result = await axios.get(`${api}/userDesignation/`);
    return result.data;
  } catch (error) {
    return [];
  }
};

export const cretaeUserDesignation = async (values) => {
  try {
    const result = await axios.post(
      `${api}/userDesignation/createDesignation`,
      {
        values,
      }
    );

    return result.data;
  } catch (error) {
    return [];
  }
};

export const updateUserDesignation = async (values) => {
  try {
    const result = await axios.post(
      `${api}/userDesignation/updateDesignation`,
      {
        values,
      }
    );

    return result.data;
  } catch (error) {
    return [];
  }
};

export const updateUserDesignationIsActive = async (values) => {
  try {
    const result = await axios.post(`${api}/userDesignation/updateIsActive`, {
      values,
    });

    return result;
  } catch (error) {
    return [];
  }
};

export const deleteUserDesignation = async (values) => {
  try {
    const result = await axios.post(
      `${api}/userDesignation/deleteDesignation`,
      {
        values,
      }
    );

    return result;
  } catch (error) {
    return [];
  }
};
