import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  allAgreementDocumentDelete,
  allAgreementDocumentList,
  allCaseDetails,
  allCaseType,
  caseBulkFilter,
  caseDetailHistory,
  caseTrackingUpdate,
  organizationCaseDetails,
  singleCaseDetails,
  updateAppointmentOfArbitrator,
  updateAppointmentOfArbitratorSec11and12,
  updateArbitrationClausAndAgreement,
  updateArbitrationDurationAndTimelineManagement,
  updateArbitrationEnforcementAndAppeals,
  updateArbitrationFeePaymentStatus,
  updateArbitrationHoldWithdrawalAndSettlement,
  updateArbitrationInterimOrdersReliefsAwards,
  updateArbitrationNoticesKeyDates,
  updateArbitrationWitnessEvidenceAndExpertReport,
  updateCaseDetails,
  updateCasePrimaryInformation,
  updateInitiationOfArbitration,
} from "../../../api/case_api";
import { json } from "react-router-dom";

export const allCaseTypeAction = createAsyncThunk(
  "case/allCaseTypeAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await allCaseType();
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const singleCaseDetailsAction = createAsyncThunk(
  "case/singleCaseTypeAction",
  async (values, { rejectWithValue }) => {
    try {
      const result = await singleCaseDetails(values);

      const data = result[0];

      return data ?? [];
      // return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const allCaseDetailsAction = createAsyncThunk(
  "case/allCaseDetails",
  async (values, { rejectWithValue }) => {
    try {
      const result = await allCaseDetails(values);

      const serializedHeaders = {
        contentType: result.headers["content-type"],
      };

      const destructureData = result?.data?.result?.map((item) => {
        let allCaseDetails = null;
        if (item && item?.["Alter Case Details"]) {
          try {
            const parsedDetails = JSON.parse(item?.["Alter Case Details"]);

            // allCaseDetails = Object.entries(parsedDetails).reduce(
            //   (acc, [key, value]) => {
            //     acc[value.alterHeader] = value.value;
            //     return acc;
            //   },
            //   {}
            // );

            allCaseDetails = Object.entries(parsedDetails).reduce(
              (acc, [key, value]) => {
                let formattedValue = value.value;
                const referenceDate = new Date("1970-01-01");

                // Check if value.value is a valid date
                if (
                  value.value instanceof Date &&
                  !isNaN(value.value.getTime()) &&
                  value.value > referenceDate
                ) {
                  const day = String(value.value.getDate()).padStart(2, "0");
                  const month = String(value.value.getMonth() + 1).padStart(
                    2,
                    "0"
                  ); // Months are zero-indexed
                  const year = value.value.getFullYear();
                  formattedValue = `${day}-${month}-${year}`;
                }

                acc[value.alterHeader] = formattedValue;
                return acc;
              },
              {}
            );

            // return allCaseDetails;
          } catch (error) {
            console.error("Failed to parse alterCaseDetails:", error);
          }
        }

        return {
          ...item,
          ...allCaseDetails,
        };
      });

      return {
        data: destructureData,
        filter: result?.data?.filter,
        pageCount: result?.data.totalRecords,
        headers: serializedHeaders,
      };
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

export const OrganizationCaseDetailsAction = createAsyncThunk(
  "case/OrganizationCaseDetailsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await organizationCaseDetails(values);

      const serializedHeaders = {
        contentType: result.headers["content-type"],
      };

      const newData = result?.data?.data?.map((item) => {
        try {
          let allCaseDetails = null;
          if (item && item?.["Alter Case Details"]) {
            try {
              const parsedDetails = JSON.parse(item?.["Alter Case Details"]);

              // allCaseDetails = Object.entries(parsedDetails).reduce(
              //   (acc, [key, value]) => {
              //     acc[value.alterHeader] = value.value;
              //     return acc;
              //   },
              //   {}
              // );

              allCaseDetails = Object.entries(parsedDetails).reduce(
                (acc, [key, value]) => {
                  let formattedValue = value.value;
                  // const referenceDate = new Date("1970-01-01");

                  // Check if value.value is a valid date
                  // if (
                  //   value.value instanceof Date &&
                  //   !isNaN(value.value.getTime()) &&
                  //   value.value > referenceDate
                  // ) {
                  //   const day = String(value.value.getDate()).padStart(2, "0");
                  //   const month = String(value.value.getMonth() + 1).padStart(
                  //     2,
                  //     "0"
                  //   ); // Months are zero-indexed
                  //   const year = value.value.getFullYear();
                  //   formattedValue = `${day}-${month}-${year}`;
                  // }

                  acc[value.alterHeader] = formattedValue;
                  return acc;
                },
                {}
              );

              // return allCaseDetails;
            } catch (error) {
              console.error("Failed to parse alterCaseDetails:", error);
            }
          }

          return {
            ...item,
            ...allCaseDetails,
          };
        } catch (err) {
          console.log(err);
        }
      });

      // const transformedName = newData?.reduce((acc, item) => {

      //   return {
      //     ...acc, // Accumulate existing properties
      //     ...item, // Spread the current item properties
      //   };
      // }, {});

      return {
        // data: allData,
        data: newData,
        filter: result?.data?.filter,
        pageCount: result?.data.totalRecords,
        headers: serializedHeaders,
      };
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateCaseDetailsAction = createAsyncThunk(
  "case/updateCaseDetailsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateCaseDetails(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const caseDetailsHistoryAction = createAsyncThunk(
  "case/caseDetailsHistoryAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await caseDetailHistory(values);

      const { data, headers } = result;

      const serializedHeaders = {
        contentType: headers["content-type"],
      };

      return { data, headers: serializedHeaders };
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const caseTrackingUpdateAction = createAsyncThunk(
  "case/caseTrackingUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await caseTrackingUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateCasePrimaryInformationAction = createAsyncThunk(
  "case/updateCasePrimaryInformationAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateCasePrimaryInformation(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateInitiationOfArbitrationAction = createAsyncThunk(
  "case/updateInitiationOfArbitrationAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateInitiationOfArbitration(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateAppointmentOfArbitratorSec11and12Action = createAsyncThunk(
  "case/updateAppointmentOfArbitratorSec11and12Action",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateAppointmentOfArbitratorSec11and12(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateArbitrationInterimOrdersReliefsAwardsAction =
  createAsyncThunk(
    "case/updateArbitrationInterimOrdersReliefsAwardsAction",
    async (values, { rejectWithValue, getState }) => {
      try {
        const result =
          await updateArbitrationInterimOrdersReliefsAwards(values);

        return result.data;
      } catch (error) {
        return rejectWithValue(error.message || "something went wrong");
      }
    }
  );

export const updateArbitrationWitnessEvidenceAndExpertReportAction =
  createAsyncThunk(
    "case/updateArbitrationWitnessEvidenceAndExpertReportAction",
    async (values, { rejectWithValue, getState }) => {
      try {
        const result =
          await updateArbitrationWitnessEvidenceAndExpertReport(values);

        return result.data;
      } catch (error) {
        return rejectWithValue(error.message || "something went wrong");
      }
    }
  );

export const updateArbitrationHoldWithdrawalAndSettlementAction =
  createAsyncThunk(
    "case/updateArbitrationHoldWithdrawalAndSettlementAction",
    async (values, { rejectWithValue, getState }) => {
      try {
        const result =
          await updateArbitrationHoldWithdrawalAndSettlement(values);

        return result.data;
      } catch (error) {
        return rejectWithValue(error.message || "something went wrong");
      }
    }
  );

export const updateArbitrationEnforcementAndAppealsAction = createAsyncThunk(
  "case/updateArbitrationEnforcementAndAppealsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateArbitrationEnforcementAndAppeals(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateArbitrationFeePaymentStatusAction = createAsyncThunk(
  "case/updateArbitrationFeePaymentStatusAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateArbitrationFeePaymentStatus(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateArbitrationNoticesKeyDatesAction = createAsyncThunk(
  "case/updateArbitrationNoticesKeyDatesAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateArbitrationNoticesKeyDates(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateArbitrationDurationAndTimelineManagementAction =
  createAsyncThunk(
    "case/updateArbitrationDurationAndTimelineManagementAction",
    async (values, { rejectWithValue, getState }) => {
      try {
        const result =
          await updateArbitrationDurationAndTimelineManagement(values);

        return result.data;
      } catch (error) {
        return rejectWithValue(error.message || "something went wrong");
      }
    }
  );

export const updateArbitrationClausAndAgreementAction = createAsyncThunk(
  "case/updateArbitrationClausAndAgreementAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateArbitrationClausAndAgreement(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const allAgreementDocumentListAction = createAsyncThunk(
  "case/allAgreementDocumentList",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await allAgreementDocumentList(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const allAgreementDocumentDeleteAction = createAsyncThunk(
  "case/allAgreementDocumentDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await allAgreementDocumentDelete(values);
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

// Case Bulk Filter

export const caseBulkFilterAction = createAsyncThunk(
  "case/caseBulkFilterAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await caseBulkFilter(values);
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
