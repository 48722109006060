import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  allotmentOrganizationCaseAction,
  allotmentOrganizationCaseTemplateAction,
} from "../../../redux/actions/allotmentDocuments/allotmentDocumentsAction";
import { Formik } from "formik";
import AllotmentCaseDetails from "../Allotment/AllotmentCaseDetails";

import * as Yup from "yup";
import { allOrganizationAction } from "../../../redux/actions/OrganizationAction/OrganizationAction";
import {
  OrganizationCaseDetailsAction,
  allCaseDetailsAction,
  allCaseTypeAction,
  singleCaseDetailsAction,
} from "../../../redux/actions/Case/caseAction";
import CaseChecklist from "./CaseChecklist";
import { caseChecklistDocumentAction } from "../../../redux/actions/Checklist/checklistAction";
import { checklistDocumentUplodOptionsAction } from "../../../redux/actions/checklistDocuments/checklistDocumentsAction";
import { MdOutlineBackupTable } from "react-icons/md";
import * as XLSX from "xlsx";
import { animateCss } from "../../TosterAlert";
import { userUpdateCaseFilterAction } from "../../../redux/actions/UserAction/usersAction";
import { set, setDate } from "date-fns";
import { Typography } from "antd";
import { Triangle } from "react-loader-spinner";

const dateFormate = (dateString) => {
  const date = new Date(dateString);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

const tableStyle = {
  WebkitAlignItems: "flex-start",
  WebkitBoxAlign: "flex-start",
  MsFlexAlign: "flex-start",
  alignItems: "flex-start",
  boxSizing: "border-box",
  display: "-webkit-box",
  display: "-webkit-flex",
  display: "-ms-flexbox",
  display: "flex",
  gap: "0.5rem",
  WebkitBoxPack: "justify",
  WebkitJustifyContent: "space-between",
  justifyContent: "flex-end",
  padding: "0.5rem",
  position: "relative",
  right: 0,
  top: 0,
  width: "100%",
};

// const filterColumn = {
//   "#": true,
//   caseID: true,
//   allotmentDocDate: true,
//   "organization.organizationName": true,
//   "caseType.categoryName": true,
//   "caseDetails.ARCNumber": false,
//   "caseDetails.PAN/BankAttachmentIADate": false,
//   "caseDetails.PAN/BankAttachmentOrderDate": false,
//   "caseDetails.RTOIADate": false,
//   "caseDetails.RTOOrderDate": false,
//   "caseDetails.agreementNumber": false,
//   "caseDetails.arbitratorAppointedBy": false,
//   "caseDetails.caseName": false,
//   "caseDetails.claimAmount": false,
//   "caseDetails.claimant": false,
//   "caseDetails.nameofArbitrator": false,
//   "caseDetails.product": false,
//   "caseDetails.productType": false,
//   "caseDetails.propertyAttachmentIADate": false,
//   "caseDetails.propertyAttachmentOrderDate": false,
//   "caseDetails.repoIADate": false,
//   "caseDetails.repoOrderDate": false,
//   "caseDetails.respondent": false,
//   "caseDetails.salaryAttachmentIADate": false,
//   "caseDetails.salaryAttachmentOrderDate": false,
//   "caseDetails.sec17OrderDate": false,
//   "caseDetails.sec17Status": false,
//   "caseDetails.tahsildarIADate": false,
//   "caseDetails.tahsildarOrderDate": false,
//   allocationDate: false,
//   caseCloseDate: false,
//   categoryName: false,
//   "caseStatusID.categoryName": false,
//   lrnNoticeDate: false,
//   refNoticeDate: false,
//   claimFillingDate: false,
//   firstHearing: false,
//   secondHearing: false,
//   thirdHearing: false,
//   finalHearing: false,
//   awardDate: false,
//   stage: false,
//   "creator.name": true,
//   "updator.name": true,
//   updatedAt: true,
// };

const columnHelper = createMRTColumnHelper();

const OrganizationCaseDetails = () => {
  const [newData, setNewData] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filterOrganizationName, setFilterOrganizationName] = useState();
  const [filterCaseType, setFilterCaseType] = useState();
  const [data, seData] = useState([]);

  const user = useSelector((state) => state.login.data);

  const casefilter = useSelector((state) => state?.case?.caseFilter);

  const pageCount = useSelector((state) => state?.case?.caseDetails?.pageCount);
  const [columnVisibility, setColumnVisibility] = useState(casefilter);

  const dispatch = useDispatch();

  const AllotmentCase = useSelector((state) => state.case.caseDetails.allData);

  const isLoading = useSelector((state) => state.case.loading);

  useEffect(() => {
    setDate(AllotmentCase);
  }, [pagination]);

  const allotmentcaseTemplate = useSelector(
    (state) => state?.allotmentDocument?.allotment?.caseTemplate
  );
  const organizationName = useSelector((state) => state?.organization?.profile);
  const caseType = useSelector((state) => state?.case?.settings);

  const columns = useMemo(() => {
    if (!AllotmentCase || AllotmentCase.length === 0) {
      return [];
    }

    const firstRow = AllotmentCase.find((row) => Object.keys(row).length > 0);
    if (!firstRow) {
      return [];
    }

    // Define a column for dynamic row numbers
    const rowNumberColumn = {
      header: "#",
      disableSortBy: true,
      Cell: ({ row }) => {
        const startRowIndex = pagination.pageIndex * pagination.pageSize;
        return startRowIndex + row.index + 1;
      },
    };

    const caseIDColumn = {
      header: "Case ID",
      accessor: "Case ID",
      id: "caseID",
      Cell: ({ row }) => {
        return (
          <Link
            to={`/case/caseDetails/${row.original?.["Case ID"]}`}
            onClick={() => {
              setNewData(row.original);
              caseIDDetails(row.original);
            }}
            style={{ textDecoration: "none" }}
          >
            {row.original?.["Case ID"]}
          </Link>
        );
      },
    };

    // Create dynamic columns based on firstRow keys, excluding Case ID
    const dynamicColumns = Object.keys(firstRow)
      .filter(
        (columnId) =>
          columnId !== "Case ID" && columnId !== "Alter Case Details"
      )
      .map((columnId) => {
        return {
          header: columnId,
          accessor: columnId,
          id: columnId,
          Cell: ({ row }) => {
            const cellValue = row.original[columnId];
            let checkType;

            // const dateValidation = new Date(row.original[columnId]);

            // if (
            //   !isNaN(dateValidation.getTime()) &&
            //   dateValidation.toDateString() !== "Thu Jan 01 1970"
            // ) {
            //   // Format as DD-MM-YYYY
            //   const day = String(dateValidation.getDate()).padStart(2, "0");
            //   const month = String(dateValidation.getMonth() + 1).padStart(
            //     2,
            //     "0"
            //   ); // Months are zero-indexed
            //   const year = dateValidation.getFullYear();
            //   checkType = `${day}-${month}-${year}`;
            // } else

            if (typeof cellValue === "string") {
              if (isValidJson(cellValue)) {
                try {
                  const parsedObject = JSON.parse(cellValue);
                  checkType =
                    parsedObject.categoryName ||
                    parsedObject.organizationName ||
                    parsedObject.name ||
                    cellValue ||
                    null;
                } catch (error) {
                  console.error("Failed to parse cellValue:", error);
                  checkType = cellValue;
                }
              } else {
                checkType = cellValue;
              }
            } else if (typeof cellValue === "object" && cellValue !== null) {
              checkType =
                cellValue.categoryName ||
                cellValue.organizationName ||
                cellValue.name ||
                null;
            } else {
              checkType = cellValue;
            }

            return <Typography variant="body1">{checkType}</Typography>;
          },
        };
      });

    return [rowNumberColumn, caseIDColumn, ...dynamicColumns];
  }, [AllotmentCase, pagination]);

  function isValidJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    const values = { user, columnVisibility };

    if (columnVisibility !== null) {
      dispatch(userUpdateCaseFilterAction(values));
    }
  }, [columnVisibility]);

  useEffect(() => {
    const values = {
      organization: filterOrganizationName,
      caseType: filterCaseType,
      user,
    };
    const value = { values, pagination, user };

    filterOrganizationName
      ? dispatch(OrganizationCaseDetailsAction(value))
      : dispatch(allCaseDetailsAction(caseDetailsFetchData));
  }, [pagination.pageIndex, pagination.pageSize, filterOrganizationName]);

  const caseDetailsFetchData = {
    pagination,
    user,
  };

  const table = useMaterialReactTable(
    {
      columns,
      data: AllotmentCase ?? [],
      state: { pagination, columnVisibility, isLoading },
      manualPagination: true,
      onPaginationChange: setPagination,
      enableColumnFilterModes: true,
      enableColumnOrdering: true,
      enableFacetedValues: true,
      rowCount: pageCount,
      enableRowActions: false,
      enableColumnResizing: true,
      enableResizing: true,
      rowCount: pageCount,
      rowNumberDisplayMode: "original",
      enableFullScreenToggle: false,
      enableRowSelection: false,
      columnFilterDisplayMode: "popover",
      enableRowVirtualization: true,
      paginationDisplayMode: "pages",
      positionToolbarAlertBanner: "bottom",
      enableStickyHeader: true,
      enableGlobalFilter: true,
      initialState: {
        pagination: { pageSize: 0, pageIndex: 0 },
        columnVisibility: casefilter,
        showColumnFilters: true,
      },
      muiCircularProgressProps: {
        color: "secondary",
        thickness: 5,
        size: 55,
      },
      muiSkeletonProps: {
        animation: "pulse",
        height: 28,
      },
      onColumnVisibilityChange: setColumnVisibility,

      renderTopToolbarCustomActions: ({ column, table }) => (
        <Box style={tableStyle}>
          <Button
            onClick={async () => {
              try {
                // AllotmentCase = [];

                exportToExcel();

                // const keys = table
                //   .getVisibleLeafColumns()
                //   ?.map((item, index) => {
                //     return item?.id;
                //   });

                // let tableData = await Promise.all(
                //   // table.getPreSelectedRowModel().rows
                //   table.getFilteredRowModel().rows.map(async (item) => {
                //     const selectColumn = await keys.flatMap((column) => {
                //       return table.options.columns
                //         .filter((headerItem) => headerItem.id === column)
                //         .map((headerItem) => ({ [column]: headerItem.header }));
                //     });

                //     const alterValue = item._valuesCache;
                //     const exportData = await selectColumn.reduce(
                //       (acc, selectValue) => {
                //         const key = Object.values(selectValue)?.[0];
                //         const columnKey = Object.keys(selectValue)?.[0];
                //         const value = alterValue?.[columnKey];

                //         if (key && value !== undefined) {
                //           if (value instanceof Date && !isNaN(value)) {
                //             const formattedDate =
                //               value.toLocaleDateString("en-GB");
                //             acc[key] = formattedDate;
                //           } else if (
                //             typeof value === "string" &&
                //             /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(value)
                //           ) {
                //             const dateObj = new Date(value);

                //             if (!isNaN(dateObj.getTime())) {
                //               const formattedDate = dateObj
                //                 .toLocaleDateString("en-GB")
                //                 .replace(/\//g, "-");
                //               acc[key] = formattedDate;
                //             } else {
                //               acc[key] = value;
                //             }
                //           } else {
                //             acc[key] = value;
                //           }
                //         }
                //         return acc;
                //       },
                //       {}
                //     );

                //     return exportData;
                //   })
                // );

                // const ws = XLSX.utils.json_to_sheet(tableData);
                // const wb = XLSX.utils.book_new();
                // XLSX.utils.book_append_sheet(wb, ws, "Case Details");
                // XLSX.writeFile(wb, "Case_Details_Report.xlsx");
              } catch (error) {
                console.error("Export failed:", error);
              }
            }}
            startIcon={<MdOutlineBackupTable className="text-success" />}
          >
            Export
          </Button>
        </Box>
      ),
    },
    [AllotmentCase, columnVisibility, casefilter]
  );

  const truncateString = (str, maxLength) => {
    return str && str.length > maxLength ? str.slice(0, maxLength) : str;
  };

  const exportToExcel = () => {
    // Filter visible columns based on the columnVisibility object
    const visibleColumns = columns.filter(
      (col) => columnVisibility[col.accessor]
    ); // Adjust according to how columnVisibility is structured

    const dataToExport = AllotmentCase.map((item) => {
      const row = {};

      visibleColumns.forEach((col) => {
        const cellValue = item[col.accessor];

        // Log the original cellValue and its type

        let extractedValue = null;

        // Check if cellValue is a string and a valid JSON
        if (typeof cellValue === "string") {
          try {
            const parsedValue = JSON.parse(cellValue);
            // Check if the parsedValue is an object
            if (typeof parsedValue === "object" && parsedValue !== null) {
              // Extract the relevant property or default to null
              extractedValue =
                parsedValue.categoryName ||
                parsedValue.organizationName ||
                parsedValue.name ||
                null;
            }
          } catch (e) {
            // If parsing fails, fallback to the original cellValue
            extractedValue = cellValue;
          }
        }

        // Truncate the extracted string if it exists or fallback to the original cellValue
        row[col.accessor] = truncateString(extractedValue || cellValue, 32767);
      });

      return row;
    });

    // Create a worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    // Generate a file and prompt for download
    XLSX.writeFile(workbook, "AllotmentCaseData.xlsx");
  };

  useEffect(() => {
    dispatch(allOrganizationAction());
    dispatch(allCaseTypeAction());
    const fetchCaseDetails = async () => {
      try {
        const result = await dispatch(
          allCaseDetailsAction(caseDetailsFetchData)
        ).unwrap();
        const caseFilterData = result;
        if (caseFilterData.filter)
          setColumnVisibility(JSON.parse(caseFilterData.filter));
        // else setColumnVisibility(filterColumn);
      } catch (error) {
        console.error("Failed to fetch case details:", error);
      }
    };

    fetchCaseDetails();
  }, []);

  useEffect(() => {
    dispatch(allOrganizationAction());
    dispatch(allCaseTypeAction());
    dispatch(allCaseDetailsAction(caseDetailsFetchData));
  }, [newData]);

  const caseIDDetails = async (caseID) => {
    try {
      await dispatch(singleCaseDetailsAction(caseID));
      await dispatch(caseChecklistDocumentAction(caseID));

      await dispatch(allCaseDetailsAction(caseDetailsFetchData));
      await dispatch(checklistDocumentUplodOptionsAction());
    } catch (err) {}
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="p-0 m-0">
        {!newData && (
          <>
            <div
              className=" bg-white shadow p-3 mb-5 rounded"
              sx={{
                width: 400,
                "@media (min-width: 300px)": {
                  width: 300,
                },
                "@media (min-width: 400px)": {
                  width: 350,
                },
                "@media (min-width: 600px)": {
                  width: 280,
                },
                "@media (min-width: 900px)": {
                  width: 400,
                },
                "@media (min-width: 1200px)": {
                  width: 400,
                },
                "@media (min-width: 1536px)": {
                  width: 400,
                },
              }}
            >
              <h5>Case Details </h5>

              <hr />
              <Formik
                initialValues={{
                  organization: "",
                  caseType: "",
                }}
                validationSchema={Yup.object().shape({
                  organization: Yup.string().required(
                    "Organization Name is required"
                  ),
                  caseType: Yup.string().required("Case Type is required"),
                })}
                onSubmit={(values, actions) => {
                  try {
                    setFilterOrganizationName(values?.organization);
                    setFilterCaseType(values?.caseType);

                    const value = { values, pagination, user };

                    dispatch(OrganizationCaseDetailsAction(value));
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  resetForm,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        flexWrap: "wrap",
                      }}
                    >
                      <FormControl
                        style={{
                          minWidth: "200px",
                          marginRight: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="organization">
                          Organization Name
                        </InputLabel>
                        <Select
                          labelId="organization"
                          placeholder="Organization Name"
                          id="organization"
                          label="Organization Name"
                          name="organization"
                          value={values.organization}
                          onChange={async (event) => {
                            handleChange(event);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.organization && errors.organization
                          )}
                        >
                          {organizationName?.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {item.organizationName}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.organization && errors.organization && (
                          <FormHelperText error>
                            {errors.organization}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl
                        style={{
                          minWidth: "200px",
                          marginRight: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="caseType">Case Type</InputLabel>
                        <Select
                          labelId="caseType"
                          id="caseType"
                          label="Case Type"
                          name="caseType"
                          value={values.caseType}
                          onChange={async (event) => {
                            handleChange(event);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(touched.caseType && errors.caseType)}
                        >
                          {caseType?.map((item, index) => (
                            <MenuItem
                              key={item.generalSettingsID}
                              value={item.generalSettingsID}
                            >
                              {item.categoryName}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.caseType && errors.caseType && (
                          <FormHelperText error>
                            {errors.caseType}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <Button
                        className="p-0"
                        style={{
                          padding: "10px",
                          height: "40px",
                        }}
                        variant="contained"
                        type="submit"
                      >
                        Search
                      </Button>
                      <Button
                        className="p-0"
                        style={{ padding: "10px", height: "40px" }}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          resetForm(); // Reset Formik form to initial values
                          setFilterOrganizationName(null); // Clear custom state
                          setFilterCaseType(null); // Clear custom state
                        }}
                        type="button" // Ensure it's not a submit button
                      >
                        Clear
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>

            <MaterialReactTable table={table} />
          </>
        )}
      </Box>
    </>
  );
};

export default OrganizationCaseDetails;
