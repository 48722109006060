import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

// material-ui
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";

// project import
import MainCard from "../../components/MainCard";
import { useSelector } from "react-redux";

export default function Breadcrumbs({
  landingPage,
  navigation,
  title,
  ...others
}) {
  const location = useLocation();
  const [main, setMain] = useState();
  const [item, setItem] = useState();

  const navigate = useNavigate();

  let mainContent;
  let itemContent;
  let breadcrumbContent = <Typography />;
  let itemTitle = "";

  // set active item state
  const getCollapse = (menu) => {
    if (menu.children) {
      menu.children.filter((collapse) => {
        if (collapse.type && collapse.type === "collapse") {
          getCollapse(collapse);
        } else if (collapse.type && collapse.type === "item") {
          if (location.pathname === collapse.url) {
            setMain(menu);
            setItem(collapse);
          }
        }
        return false;
      });
    }
  };

  useEffect(() => {
    navigation?.items?.map((menu) => {
      if (menu.type && menu.type === "group") {
        getCollapse(menu);
      }
      if (menu.type && menu.type === "none") {
        getCollapse(menu);
      }
      return false;
    });
  });

  // only used for component demo breadcrumbs
  if (location.pathname === "/breadcrumbs") {
    location.pathname = "/dashboard/analytics";
  }

  // collapse item
  if (main && main.type === "collapse") {
    mainContent = (
      <Typography
        component={Link}
        to={document.location.pathname}
        variant="h6"
        sx={{ textDecoration: "none" }}
        color="textSecondary"
      >
        {main.title}
      </Typography>
    );
  }

  // items
  if (item && item.type === "item") {
    itemTitle = item.title;
    itemContent = (
      <Typography variant="subtitle1" color="textPrimary">
        {itemTitle}
      </Typography>
    );

    // main
    if (item.breadcrumbs !== false) {
      breadcrumbContent = (
        <MainCard
          border={false}
          sx={{ mb: 3, bgcolor: "transparent" }}
          {...others}
          content={false}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item>
              <MuiBreadcrumbs aria-label="breadcrumb">
                <Typography
                  component={Link}
                  to={landingPage}
                  color="textSecondary"
                  variant="h6"
                  sx={{ textDecoration: "none" }}
                >
                  Home
                </Typography>
                {mainContent}
                {itemContent}
              </MuiBreadcrumbs>
            </Grid>
            {title && (
              <Grid item sx={{ mt: 2 }}>
                <Typography variant="h5">{item.title}</Typography>
              </Grid>
            )}
          </Grid>
        </MainCard>
      );
    }
  }

  //user path check
  const userRoleRouters = useSelector((state) => state.userRoleRouters);
  // const routes = useSelector((state) => state?.userRoleRouters?.landingPage[0]);

  // const userPath = userRoleRouters?.route?.some((userNav) =>
  //   userNav.url.some((urls) => urls?.url === window.location.pathname)
  // );

  // if (!userPath) {
  //   navigate("/404");
  // }

  const currentPath = window.location.pathname;
  const login = useSelector((state) => state?.login || []);

  const userPath = userRoleRouters?.route?.some((userNav) => {
    return userNav.url.some((routeItem) => {
      if (typeof routeItem.url === "string") {
        window.edit = routeItem.edit;
        window.delete = routeItem.delete;
        const routeRegex = new RegExp(
          `^${routeItem.url.replace("/case/caseDetails", "/case/caseDetails(/([A-Za-z0-9!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>?`~]+))?$")}$`
        );

        return routeRegex.test(currentPath);
      } else if (
        window.location.pathname == "/resetPassword" &&
        login.data.role.role.toLowerCase() == "admin"
      ) {
        window.edit = routeItem.edit;
        window.delete = routeItem.delete;
        return true;
      }
      return false;
    });
  });

  useEffect(() => {
    if (!userPath) {
      // navigate("/404");
    }
  }, [userPath, navigate]);

  return breadcrumbContent;
}

Breadcrumbs.propTypes = {
  card: PropTypes.bool,
  custom: PropTypes.bool,
  divider: PropTypes.bool,
  heading: PropTypes.string,
  icon: PropTypes.bool,
  icons: PropTypes.bool,
  links: PropTypes.array,
  maxItems: PropTypes.number,
  rightAlign: PropTypes.bool,
  separator: PropTypes.any,
  title: PropTypes.bool,
  titleBottom: PropTypes.bool,
  sx: PropTypes.any,
  others: PropTypes.any,
};
