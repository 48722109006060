import React, { useEffect, useState } from "react";
import {
  Card,
  List,
  ListItem,
  TextField,
  Grid,
  Button,
  Container,
  Autocomplete,
} from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
// import { changePasswordAction } from "../../../redux/actions/UserAction/usersAction";
import { useNavigate } from "react-router-dom";
import {
  getUsersAction,
  resetPasswordAction,
} from "../../redux/actions/UserAction/usersAction";
import { animateCss } from "../TosterAlert";

const UserResetPassword = () => {
  const user = useSelector((state) => state?.login?.data);
  const dispatch = useDispatch();

  const usersName = useSelector((state) => state.users.list);

  useEffect(() => {
    dispatch(getUsersAction());
  }, []);
  return (
    <>
      <h6>Reset Password</h6>
      <Container
        maxWidth="lg"
        sx={{ mt: 4, mb: 4 }}
        style={{
          height: "100%",
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{ boxShadow: 3 }}
          justifyContent={"center"}
          height={"100%"}
        >
          <Grid item lg={6} md={6} xs={12}>
            <Card xs={6} sx={{ mb: 4, mx: 3, boxShadow: 2 }}>
              <List sx={{ p: 3 }} xs={9} sm={6}>
                <Formik
                  initialValues={{
                    userName: null,
                    newPassword: "",

                    user,
                  }}
                  validationSchema={Yup.object().shape({
                    userName: Yup.object()
                      .shape({
                        userLogID: Yup.string()
                          .min(3, "Username must be at least 3 characters long")
                          .required("Username is required"),
                      })
                      .required("User must be selected"),
                    newPassword: Yup.string()
                      .min(8, "Password must be at least 8 characters long")
                      .max(20, "Password cannot exceed 20 characters")
                      .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
                      )
                      .required("Password is required"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    try {
                      const sentValue = await dispatch(
                        resetPasswordAction(values)
                      );
                      if (sentValue.payload.data) {
                        setTimeout(() => {
                          animateCss(
                            "success",
                            "Password has been reset successfully!"
                          );
                          setSubmitting(false);
                          resetForm();
                        }, 1000);
                      }
                    } catch (error) {}
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <ListItem>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12}>
                            {/* <Autocomplete
                              name="userName"
                              id="combo-box-demo"
                              options={organizationName}
                              sx={{ width: 300 }}
                              onChange={(e, val) => {
                                setFieldValue("userName", val);
                              }}
                              getOptionLabel={(option) =>
                                option.organizationName || ""
                              }
                              value={values?.userName}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  autoFocus={true}
                                  label="User Name"
                                  error={
                                    touched.userName && Boolean(errors.userName)
                                  }
                                  helperText={
                                    touched.userName && errors.userName
                                  }
                                />
                              )}
                              error={
                                touched.userName && Boolean(errors.userName)
                              }
                              helperText={touched.userName && errors.userName}
                            /> */}

                            {/* <Autocomplete
                              fullWidth
                              autoFocus={true}
                              name="userName"
                              id="combo-box-demo"
                              options={usersName}
                              sx={{ width: "100%" }}
                              onChange={(e, val) => {
                                setFieldValue("userName", val);
                              }}
                              getOptionLabel={(option) =>
                                option.userLogID || ""
                              }
                              value={values.userName || null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  autoFocus={true}
                                  label="User Name"
                                  error={
                                    touched.userName?.name &&
                                    Boolean(errors.userName?.name)
                                  }
                                  helperText={
                                    touched.userName?.name &&
                                    errors.userName?.name
                                  }
                                />
                              )}
                            /> */}

                            <Autocomplete
                              fullWidth
                              autoFocus
                              name="userName"
                              options={usersName}
                              onChange={(e, val) =>
                                setFieldValue("userName", val)
                              }
                              getOptionLabel={(option) =>
                                option.userLogID || ""
                              }
                              value={values.userName || null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="User Name"
                                  error={Boolean(
                                    touched.userName && errors.userName
                                  )}
                                  helperText={
                                    touched.userName && errors.userName
                                      ? errors.userName.name
                                      : ""
                                  }
                                />
                              )}
                            />
                            {values?.userName?.name && (
                              <p>
                                User :
                                <span className="text-success">
                                  {values?.userName?.name}
                                </span>
                              </p>
                            )}
                            <TextField
                              className="mt-4"
                              label="New Password"
                              placeholder="Enter Reset Password"
                              fullWidth
                              value={values?.newPassword}
                              name="newPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(
                                touched.newPassword && errors.newPassword
                              )}
                              helperText={
                                touched.newPassword && errors.newPassword
                              }
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={12}
                            container
                            justifyContent="center"
                          >
                            <Button
                              xs={4}
                              sm={6}
                              className="text-white text-decoration-none"
                              sx={{
                                width: "50%",
                                fontSize: "100%",
                                display: "flex",
                                alignItems: "center",
                              }}
                              disabled={isSubmitting}
                              xl={{
                                fontSize: "90%",
                              }}
                              variant="contained"
                              type="submit"
                              color="primary"
                            >
                              {isSubmitting
                                ? "Submitting..."
                                : "Reset Password"}
                            </Button>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </form>
                  )}
                </Formik>
              </List>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default UserResetPassword;
