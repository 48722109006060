// assets
import {
  ChromeOutlined,
  QuestionOutlined,
  ContainerOutlined,
  DownOutlined,
  UserOutlined,
  UserSwitchOutlined,
  ApartmentOutlined,
  FileDoneOutlined,
  CodepenOutlined,
} from "@ant-design/icons";

import { FaDatabase } from "react-icons/fa";

// icons
// const icons = {
//   ChromeOutlined,
//   QuestionOutlined,
//   FaDatabase,
// };

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const Repository = {
  id: "Repository Central",
  title: "Organization",
  type: "group",
  children: [
    {
      id: "Repository Central",
      title: "Repository Central",
      type: "collapse",
      url: "/repositorycentral",
      icon: FaDatabase,
      children: [
        {
          id: "Repository Central",
          title: "Master Repository",
          type: "item",
          url: "/repositorycentral/masterrepository",
          icon: UserOutlined,
        },
        {
          id: "Repository Central",
          title: "Awards & Orders",
          type: "item",
          url: "/repositorycentral/awardsandorders",
          icon: UserOutlined,
        },
      ],
    },
  ],
};

export default Repository;
