import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  allCaseMappingDetails,
  caseMapping,
  caseMappingDetailUpdate,
  caseMappingDetailisActive,
  caseMappingDetails,
  organizationActiveCaseTemplate,
  selectedCaseMappingDetails,
} from "../../../api/case_api";
import { animateCss } from "../../../pages/TosterAlert";

export const caseMappingUploadAction = createAsyncThunk(
  "case/caseMappingUploadAction",
  async (values, { rejectWithValue }) => {
    try {
      const data = {
        excelData: values?.excelData,
        mappingData: values?.values,
      };
      const formData = new FormData();

      if (values.uploadedFiles) {
        for (const file of values.uploadedFiles) {
          formData.append("files", file);
        }
      }

      formData.append("data", JSON.stringify(data));

      const result = await caseMapping(formData);
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

export const allCaseMappingDetailAction = createAsyncThunk(
  "caseMapping/allCaseMappingDetailAction",
  async (values, { rejectWithValue }) => {
    try {
      const result = await allCaseMappingDetails(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

export const selectedCaseMappingDetailAction = createAsyncThunk(
  "caseMapping/selectedCaseMappingDetailAction",
  async (values, { rejectWithValue }) => {
    try {
      const result = await selectedCaseMappingDetails(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

export const caseMappingDetailUpdateAction = createAsyncThunk(
  "case/caseMappingDetailUpdateAction",
  async (values, { rejectWithValue }) => {
    try {
      const result = await caseMappingDetailUpdate(values);
      const serializedHeaders = {
        contentType: result.headers["content-type"],
      };
      return { data: result.data, headers: serializedHeaders };
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

export const caseMappingDetailIsActiveAction = createAsyncThunk(
  "caseMappings/caseMappingDetailIsActiveAction",
  async (values, { rejectWithValue }) => {
    try {
      const result = await caseMappingDetailisActive(values);
      const { data, headers } = result;

      const serializedHeaders = {
        contentType: headers["content-type"],
      };

      return { data, headers: serializedHeaders };
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

export const organizationActiveCaseTemplateAction = createAsyncThunk(
  "caseMappings/organizationActiveCaseTemplateAction",

  async (values, { rejectWithValue }) => {
    try {
      if (
        values?.template !== undefined &&
        values?.organizationName !== null &&
        values?.caseType !== null
      ) {
        const result = await organizationActiveCaseTemplate(values);

        return result.data;
      }
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);
