import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  TextField,
} from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  Tab,
  Typography,
  styled,
} from "@mui/material";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import * as XLSX from "xlsx";
import { animateCss } from "../../TosterAlert";
import { useDispatch, useSelector } from "react-redux";
import { allOrganizationAction } from "../../../redux/actions/OrganizationAction/OrganizationAction";
import { allCaseTypeAction } from "../../../redux/actions/Case/caseAction";
import {
  caseMappingDetailAction,
  caseMappingUploadAction,
} from "../../../redux/actions/Case/caseMappingAction";
import { useNavigate } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import CaseTemplateList from "./CaseTemplateList";
import {
  mappingTemplateReducer,
  resetMappingAllDetailsReducer,
  resetMappingDetailsReducer,
} from "../../../redux/slices/Case/caseSlice";
import ViewUploadHeader from "./ViewUploadHeader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditCaseTemplate from "./EditCaseDetails";
import UploadCaseTemplateDetails from "./UploadCaseTemplateDetails";
import TemplateGroupList from "./TemplateGroup/TemplateGroupList";
import { allCaseGroupingAction } from "../../../redux/actions/TemplateGrouping/TemplateGroupingAction";

const DatePickerField = ({ field, form, ...other }) => {
  const { name, value } = field;

  const handleChange = (val) => {
    form.setFieldValue(name, val);
  };

  return (
    <React.Fragment>
      <ReactDatePicker
        sx={{ m: 0, fontSize: "90%", minWidth: "150px" }}
        style={{ marginRight: "20px", minWidth: "150px", fontSize: "90%" }}
        className="p-2 form-control"
        {...other}
        selected={value}
        onChange={handleChange}
        dateFormat="dd-MM-yyyy"
        placeholderText="Select Date"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        error={form.touched[name] && form.errors[name]}
      />
      {form.touched[name] && form.errors[name] && (
        <FormHelperText error>{form.errors[name]}</FormHelperText>
      )}
    </React.Fragment>
  );
};

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const MAX_COUNT = 1;
const CaseTemplate = () => {
  const [excelData, setExcelData] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [uploadDocument, setUploadDocument] = useState({
    organizationName: "",
    caseType: "",
    uploadDate: "",
  });
  const user = useSelector((state) => state.login.data);
  const organizationName = useSelector((state) => state?.organization?.profile);
  const caseType = useSelector((state) => state?.case?.settings);
  const [duplicateHeader, setDuplicateHeader] = useState([]);
  const [duplicateHeaderModal, setDuplicateHeaderModal] = useState(false);

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    dispatch(resetMappingAllDetailsReducer());
    setValue(newValue);
  };

  const dispatch = useDispatch();

  const usStates = useSelector((state) => state.templateGroupting.list);

  const handleUploadFiles = (files) => {
    try {
      const uploaded = [uploadedFiles];
      let limitExceeded = false;

      const file = files[0];

      if (!file) {
        return;
      }

      if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        uploaded.splice(0, 0, file);
        handleFileUpload(file);
      } else {
        animateCss("error", "Please Upload Excel Document Only");
      }

      setUploadedFiles(uploaded);
      handleFileUpload(uploaded);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleFileUpload = (files) => {
    setDuplicateHeader([]);
    Array.from(files).forEach((file) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const arrayBuffer = event?.target?.result;
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX?.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const headers = jsonData?.[0] || [];
        const alterHeader = jsonData?.[1] || [];
        const groups = jsonData?.[2] || [];
        const uniqueHeaders = new Set();

        const parsedData = headers?.map((header, index) => {
          if (uniqueHeaders.has(header)) {
            setDuplicateHeader((prevDuplicateHeaders) => {
              const updatedDuplicates = new Set([
                ...prevDuplicateHeaders,
                header,
              ]);
              return Array.from(updatedDuplicates);
            });

            setDuplicateHeaderModal(true);
            return null;
          }

          uniqueHeaders.add(header);

          const groupValue = groups[index];
          const group = usStates.includes(groupValue) ? groupValue : "";

          const altHeader =
            typeof alterHeader[index] === "string"
              ? alterHeader[index].trim()
              : alterHeader[index] || "";

          const rowData = {
            id: index + 1,
            header: header?.trim(),
            group: group,
            alterHeader: altHeader,
            fieldNotNeeded: false,
            reportsAndTemplates: false,
          };

          return rowData;
        });

        const filteredData = parsedData.filter((row) => row !== null);

        let values = [filteredData];

        setExcelData(values);
        dispatch(mappingTemplateReducer(values));
      };

      reader.readAsArrayBuffer(file);
    });
  };

  useEffect(() => {
    dispatch(allCaseGroupingAction());
    dispatch(resetMappingDetailsReducer());
    dispatch(allOrganizationAction());
    dispatch(allCaseTypeAction());
  }, []);

  useEffect(() => {
    dispatch(resetMappingDetailsReducer());
  }, [value]);

  const navigate = useNavigate();
  const caseDeatilsData = useSelector(
    (state) => state.case?.caseMappingDetails
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const initialValues = {
    organizationName: "",
    caseType: "",
    templateName: "",
    date: new Date(),
    document: [],
    user,
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }} className="px-0">
      <Dialog
        open={duplicateHeaderModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Duplicate Header</DialogTitle>
        <DialogContent>
          {duplicateHeader?.map((item, index) => {
            return (
              <h6 key={index} className="text-danger">
                {index + 1}. {item}
              </h6>
            );
          })}

          <DialogContentText id="alert-dialog-description">
            Please Remove Duplicate Header
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button>Disagree</Button> */}
          <Button
            onClick={() => {
              setDuplicateHeaderModal(false);
            }}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Case Templates " value="1" />

            <Tab
              label="Document Upload"
              value={window.edit && "2"}
              disabled={!window.edit}
            />
          </TabList>
        </Box>
        <TabPanel value="1" className="px-0">
          <CaseTemplateList />
        </TabPanel>
        {window?.edit && (
          <TabPanel value="2" className=" d-flex row justify-content-center ">
            <div
              className=" bg-white shadow p-3 mb-5 rounded"
              sx={{
                width: 400,
                "@media (min-width: 300px)": {
                  width: 300,
                },
                "@media (min-width: 400px)": {
                  width: 350,
                },
                "@media (min-width: 600px)": {
                  width: 280,
                },
                "@media (min-width: 900px)": {
                  width: 400,
                },
                "@media (min-width: 1200px)": {
                  width: 400,
                },
                "@media (min-width: 1536px)": {
                  width: 400,
                },
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <h5>Document Upload</h5>
                <Button variant="contained" onClick={handleClickOpen}>
                  View Grouping
                </Button>
              </Box>

              <hr />

              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  organizationName: Yup.string().required(
                    "Organization Name is required"
                  ),
                  caseType: Yup.string().required("Case Type is required"),
                  templateName: Yup.string().required(
                    "Template Name is required"
                  ),
                  date: Yup.string().required("Document Date is required"),
                })}
                onSubmit={async (values, actions) => {
                  try {
                    const data = { values, uploadedFiles, excelData };

                    if (uploadedFiles?.[0]) {
                      await dispatch(caseMappingUploadAction(data));
                      setValue("1");
                      actions.setSubmitting(false);

                      actions.resetForm({
                        values: {
                          organizationName: "",
                          caseType: "",
                          date: new Date(),
                          templateName: "",
                          document: [],
                        },
                      });
                      setUploadedFiles([]);
                      setExcelData();
                    } else if (!uploadedFiles?.[0]) {
                      animateCss("warn", "Please Upload Excel Document");
                    }
                  } catch (error) {
                    console.error("Error uploading:", error);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "start",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <FormControl
                        style={{
                          minWidth: "100px",
                          fontSize: "90%",
                        }}
                      >
                        <Field
                          name="date"
                          sx={{ p: 0 }}
                          style={{ fontSize: "90%" }}
                        >
                          {({ field, form }) => (
                            <DatePickerField
                              error={Boolean(touched.date && errors.date)}
                              field={field}
                              form={form}
                            />
                          )}
                        </Field>
                      </FormControl>

                      <FormControl style={{ minWidth: "150px" }} sx={{ my: 2 }}>
                        <InputLabel
                          id="organization"
                          style={{ fontSize: "90%" }}
                        >
                          Organization Name
                        </InputLabel>
                        <Select
                          style={{ height: "45px" }}
                          labelId="organization"
                          id="organization"
                          label="Organization Name"
                          name="organizationName"
                          value={values.organizationName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.organizationName && errors.organizationName
                          )}
                        >
                          {organizationName?.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {item.organizationName}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.organizationName &&
                          errors.organizationName && (
                            <FormHelperText error>
                              {errors.organizationName}
                            </FormHelperText>
                          )}
                      </FormControl>

                      <FormControl style={{ minWidth: "150px" }} sx={{ my: 1 }}>
                        <InputLabel id="caseType" style={{ fontSize: "90%" }}>
                          Case Type
                        </InputLabel>
                        <Select
                          style={{ height: "45px" }}
                          labelId="caseType"
                          id="caseType"
                          label="Case Type"
                          name="caseType"
                          value={values.caseType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.caseType && errors.caseType)}
                        >
                          {caseType?.map((item, index) => (
                            <MenuItem
                              key={index}
                              value={item.generalSettingsID}
                            >
                              {item.categoryName}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.caseType && errors.caseType && (
                          <FormHelperText error>
                            {errors.caseType}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl
                        style={{
                          minWidth: "150px",
                          display: "grid",
                          marginTop: "5px",
                          fontSize: "12px",
                        }}
                      >
                        <TextField
                          style={{ fontSize: "12px" }}
                          id="outlined-multiline-flexible"
                          name="templateName"
                          value={values.templateName}
                          placeholder="Template Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.templateName && errors.templateName
                          )}
                          helperText={
                            touched.templateName && errors.templateName
                          }
                          variant="outlined"
                          size="small"
                        />
                      </FormControl>

                      <Grid className="justify-content-center">
                        <Button
                          component="label"
                          style={{ minWidth: "150px", height: "45px" }}
                          variant="outlined"
                          color="primary"
                          type="file"
                          accept=".csv, .xlsx, .xls"
                          onChange={async (e) => {
                            const chosenFiles = Array.prototype.slice.call(
                              e.target.files
                            );
                            await handleUploadFiles(chosenFiles);
                          }}
                          startIcon={
                            <SvgIcon>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                />
                              </svg>
                            </SvgIcon>
                          }
                        >
                          Upload Excel File
                          <VisuallyHiddenInput
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          />
                        </Button>
                        <p className="m-0" style={{ fontSize: "10px" }}>
                          {uploadedFiles?.[0]?.name}
                        </p>
                      </Grid>
                    </div>
                    <hr />
                    <div
                      className="m-2"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "end",
                        justifyContent: "flex-end",
                      }}
                    >
                      {!duplicateHeader?.[0] && (
                        <Button
                          variant="contained"
                          type="submit"
                          className="mx-2"
                        >
                          Submit
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        type="button"
                        onClick={() => {
                          resetForm();
                          setUploadedFiles([]);
                          setExcelData([]);
                        }}
                        disabled={isSubmitting}
                      >
                        Clear
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            {excelData?.[0] && !duplicateHeader?.[0] && (
              <UploadCaseTemplateDetails setExcelData={setExcelData} />
            )}
          </TabPanel>
        )}
      </TabContext>
      {open && <TemplateGroupList open={open} setOpen={setOpen} />}
    </Box>
  );
};

export default CaseTemplate;
