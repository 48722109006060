import * as React from "react";
import { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MdOutlineBackupTable } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { animateCss } from "../../TosterAlert";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoDisturbAltOutlinedIcon from "@mui/icons-material/DoDisturbAltOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import * as XLSX from "xlsx";
import ChecklistForm from "./ChecklistForm";
import {
  allChecklistAction,
  deleteChecklistAction,
  isActiveChecklistAction,
} from "../../../redux/actions/Checklist/checklistAction";

const Checklist = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [editValue, setEditValue] = useState({});

  const apiValues = useSelector((state) => state?.checklist?.list);

  const userId = useSelector((state) => state?.login.data);

  const tableStyle = {
    WebkitAlignItems: "flex-start",
    WebkitBoxAlign: "flex-start",
    MsFlexAlign: "flex-start",
    alignItems: "flex-start",
    boxSizing: "border-box",
    display: "-webkit-box",
    display: "-webkit-flex",
    display: "-ms-flexbox",
    display: "flex",
    gap: "0.5rem",
    WebkitBoxPack: "justify",
    WebkitJustifyContent: "space-between",
    justifyContent: "flex-end",
    padding: "0.5rem",
    position: "relative",
    right: 0,
    top: 0,
    width: "100%",
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(allChecklistAction());
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "checklistName",
        header: "Checklist Name",
        size: 400,
        Cell: ({ row }, rowIndex) => {
          return (
            <Link
              style={{ textDecoration: "none" }}
              to=""
              onClick={() => {
                if (window.edit) {
                  setOpen(true);
                  setEditValue(row.original);
                } else {
                  animateCss("info", "You are not authorized to update");
                }
              }}
            >
              {row?.original?.checklistName}
            </Link>
          );
        },
      },
      {
        accessorKey: "CaseType.categoryName",
        header: "Case Type",
        size: 300,
      },
      {
        accessorKey: "order",
        header: "Checklist Order",
      },
      {
        accessorKey: "isActive",
        header: "Status",
        Cell: ({ row }, rowIndex) => {
          const values = {
            checklist: row.original,
            userId,
          };
          return (
            <Box display="flex" justifyContent="center">
              <Tooltip
                title={
                  row?.original?.isActive
                    ? "Active Checklist"
                    : "Inactive Checklist"
                }
                onClick={() => {
                  if (window.edit) {
                    dispatch(isActiveChecklistAction(values));
                    setTimeout(() => {
                      dispatch(allChecklistAction());
                    }, 1000);
                  } else {
                    animateCss("info", "You are not authorized to update");
                  }
                }}
              >
                <IconButton variant="circular" edge="end">
                  {row?.original?.isActive ? (
                    <DoneAllIcon color="success" />
                  ) : (
                    <DoDisturbAltOutlinedIcon color="error" />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        accessorKey: "delete",
        header: "Delete",
        Cell: ({ row }, rowIndex) => {
          const values = {
            checklist: row.original,
            userId,
          };
          return (
            <Box display="flex" justifyContent="center">
              <Tooltip
                title="Delete User"
                onClick={async () => {
                  if (window.delete) {
                    dispatch(deleteChecklistAction(values));
                    setTimeout(() => {
                      dispatch(allChecklistAction());
                    }, 1000);
                  } else {
                    animateCss("info", "You are not authorized to delete");
                  }
                }}
              >
                <IconButton variant="circular" edge="end">
                  <DeleteOutlinedIcon color="error" />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    []
  );

  const exportData = async (rows) => {
    try {
      const tableData = await Promise.all(
        rows.map(async (item, index) => {
          return item.original;
        })
      );

      const valuesArray = tableData.map((data) => ({
        "Checklist Name": data.checklistName,
        Order: data.order,
        Status: data.isActive ? "Active" : "InActive",
      }));

      const ws = XLSX.utils.json_to_sheet(valuesArray);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Checklist");
      XLSX.writeFile(wb, "Checklist_Data.xlsx");
    } catch (error) {
      console.error("Export failed:", error);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: apiValues ?? [],
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    enableFullScreenToggle: false,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    enableRowVirtualization: true,
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableStickyHeader: true,
    enableGlobalFilter: true,
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
    },
    keyAccessor: (row, rowIndex) =>
      row.orderStatus + "_" + row.id + "_" + rowIndex,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box style={tableStyle}>
        {window.edit && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setEditValue({});
              setOpen(true);
            }}
            sx={{
              width: isSmallScreen ? "100%" : "auto",
              maxWidth: "150px",
              fontSize: "85%",
            }}
          >
            <Link
              className="text-white  text-decoration-none"
              sx={{ width: "100%", display: "inherit" }}
            >
              Add Checklist
            </Link>
          </Button>
        )}

        <Button
          onClick={async () => {
            await exportData(table.getPrePaginationRowModel().rows);
          }}
          startIcon={<MdOutlineBackupTable className="text-success" />}
        >
          Export
        </Button>
      </Box>
    ),
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <ChecklistForm
        EditValue={editValue}
        open={open}
        setOpen={() => setOpen(false)}
      />
    </>
  );
};
export default Checklist;
