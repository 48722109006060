// import React from "react";
// import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";

// const NotFound = () => {
//   const userRoute = useSelector((state) => state?.userRoleRouters?.landingPage);

//   return (
//     <div>
//       <h1>Oops! You seem to be lost.</h1>
//       <p>The page you are looking for does not exist.</p>
//       <p>
//         Here are some helpful links:{" "}
//         <Link to={userRoute && userRoute?.[0]?.navPath}>Back</Link>
//       </p>
//     </div>
//   );
// };

// export default NotFound;

import React from "react";
import { Box, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";

// Define the keyframes for animations
const smallMove = keyframes`
  0% { top: 10px; left: 45%; opacity: 1; }
  25% { top: 300px; left: 40%; opacity:0.7; }
  50% { top: 240px; left: 55%; opacity:0.4; }
  75% { top: 100px; left: 40%; opacity:0.6; }
  100% { top: 10px; left: 45%; opacity: 1; }
`;

const medMove = keyframes`
  0% { top: 0px; left: 20%; opacity: 1; }
  25% { top: 300px; left: 80%; opacity:0.7; }
  50% { top: 240px; left: 55%; opacity:0.4; }
  75% { top: 100px; left: 40%; opacity:0.6; }
  100% { top: 0px; left: 20%; opacity: 1; }
`;

const bigMove = keyframes`
  0% { top: 0px; right: 4%; opacity: 0.5; }
  25% { top: 100px; right: 40%; opacity:0.4; }
  50% { top: 240px; right: 45%; opacity:0.8; }
  75% { top: 100px; right: 35%; opacity:0.6; }
  100% { top: 0px; right: 4%; opacity: 0.5; }
`;

export default function NotFound() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#48A9E6",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "'Raleway', sans-serif",
        fontWeight: 300,
        margin: 0,
        padding: 0,
      }}
    >
      <Typography
        id="title"
        variant="h1"
        sx={{
          fontSize: "40px",
          marginTop: "40px",
          marginBottom: "-40px",
          color: "#fff",
          textAlign: "center",
        }}
      >
        Simple Pure CSS3 &bull; 404 Error Page
      </Typography>

      <Box
        className="circles"
        sx={{
          position: "relative",
          marginTop: "-60px",
          backgroundColor: "#fff",
          textAlign: "center",
          boxShadow: "inset -1px -4px 4px rgba(0,0,0,0.2)",
          width: "100%",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: "240px",
            color: "#48A9E6",
            paddingTop: "60px",
            position: "relative",
            zIndex: 9,
            lineHeight: "100%",
          }}
        >
          404
          <Typography
            variant="h6"
            component="small"
            sx={{
              fontSize: "40px",
              lineHeight: "100%",
              verticalAlign: "top",
            }}
          >
            PAGE NOT FOUND
          </Typography>
        </Typography>

        {/* Circles */}
        <Box
          className="circle big"
          sx={{
            width: "400px",
            height: "400px",
            borderRadius: "50%",
            backgroundColor: "#48A9E6",
            position: "absolute",
            top: "200px",
            right: 0,
            animation: `${bigMove} 8s infinite`,
            zIndex: 1,
          }}
        ></Box>
        <Box
          className="circle med"
          sx={{
            width: "200px",
            height: "200px",
            borderRadius: "50%",
            backgroundColor: "#48A9E6",
            position: "absolute",
            top: 0,
            left: "10%",
            animation: `${medMove} 7s infinite`,
            zIndex: 1,
          }}
        ></Box>
        <Box
          className="circle small"
          sx={{
            width: "140px",
            height: "140px",
            borderRadius: "50%",
            backgroundColor: "#48A9E6",
            position: "absolute",
            top: "80px",
            left: "50%",
            animation: `${smallMove} 7s infinite`,
            zIndex: 1,
          }}
        ></Box>
      </Box>
    </Box>
  );
}
