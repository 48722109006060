import { createSlice } from "@reduxjs/toolkit";
import { animateCss } from "../../../pages/TosterAlert";
import {
  allCaseGroupingAction,
  createCaseGroupingAction,
  deleteCaseGroupingAction,
  updateCaseGroupingAction,
} from "../../actions/TemplateGrouping/TemplateGroupingAction";

const templateGrouping = {
  data: [],
  list: [],
  listLoading: false,
  create: {
    loading: false,
    error: "",
  },
  loading: true,
};

const templateGroupingSlice = createSlice({
  name: "templateGrouping",
  initialState: templateGrouping,
  reducers: {
    resettemplateGrouping: (state, { payload }) => {
      state = { ...state, ...templateGrouping.create };
      return state;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createCaseGroupingAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createCaseGroupingAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.data) {
          animateCss("success", "Grouping Created Successfully");
        } else if (!action.payload.data) {
          animateCss("warn", "Grouping Name Already Exists");
        }
        return state;
      })
      .addCase(createCaseGroupingAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(allCaseGroupingAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(allCaseGroupingAction.fulfilled, (state, action) => {
        const objects = action.payload;
        let result = objects?.map((obj) => obj.groupingName);

        state.data = action.payload;
        state.list = result;
        state.loading = false;
        return state;
      })
      .addCase(allCaseGroupingAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateCaseGroupingAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateCaseGroupingAction.fulfilled, (state, action) => {
        state.loading = false;
        animateCss("success", "Grouping Updated Successfully");
        return state;
      })
      .addCase(updateCaseGroupingAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteCaseGroupingAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteCaseGroupingAction.fulfilled, (state, action) => {
        state.loading = false;
        animateCss("success", "Deleted Successfully");
        return state;
      })
      .addCase(deleteCaseGroupingAction.rejected, (state, action) => {
        state.loading = false;
      }),
});

export default templateGroupingSlice.reducer;
