import axios from "axios";
import { api } from "./api_route";

// Organization Master

export const allOrganization = async (values) => {
  try {
    const result = await axios.get(`${api}/organization/`);

    return result.data;
  } catch (error) {
    return [];
  }
};

export const createOrganization = async (values) => {
  try {
    const result = await axios.post(`${api}/organization/create`, { values });
    return result.data;
  } catch (error) {
    return [];
  }
};

export const updateOrganization = async (values) => {
  try {
    const result = await axios.post(`${api}/organization/update`, { values });
    return result.data;
  } catch (error) {
    return [];
  }
};

export const isActiveOrganization = async (values) => {
  try {
    const result = await axios.post(`${api}/organization/isActive`, { values });
    return result.data;
  } catch (error) {
    return [];
  }
};

export const deleteOrganization = async (values) => {
  try {
    const result = await axios.post(`${api}/organization/delete`, { values });
    return result.data;
  } catch (error) {
    return [];
  }
};

//Organization Document Upload and View Delete Option

export const getOrganizationDocuments = async (values) => {
  try {
    const result = await axios.get(`${api}/organization/documentData`);
    return result.data;
  } catch (error) {
    return [];
  }
};

export const organizationUploadDocuments = async (values) => {
  try {
    var files = new FormData();

    values?.uploadedFiles?.map((document, i) => {
      files.append("file", document);
    });

    files.append("organization", JSON.stringify(values));
    const result = await axios.post(`${api}/organization/upload`, files);

    return result;
  } catch (error) {
    return [];
  }
};

export const organizationAwards = async (values) => {
  try {
    const result = await axios.get(`${api}/organization/repositoryAwards`, {
      params: { values },
    });
    return result.data;
  } catch (error) {
    return [];
  }
};

export const organizationDocumentIsActive = async (values) => {
  try {
    const result = await axios.post(`${api}/organization/isActive`, values);

    return result.data;
  } catch (error) {
    return [];
  }
};
