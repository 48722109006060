import { Button, ConfigProvider, Form } from "antd";
import RTEditor from "./RTEditor.js";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CreateCaseTemplate = () => {
  const [form] = Form.useForm();
  return (
    <div>
      <h1>Hello CodeSandbox</h1>
      <h2>Start editing to see some magic happen!</h2>

      <Form
        form={form}
        onFinish={(values) => {
         
        }}
        onFinishFailed={(values) => {
         
        }}
        layout="vertical"
      >
        <Form.Item
          name={"CKEditor"}
          label="CKEditor"
          rules={[{ required: true }]}
        >
          <RTEditor />
        </Form.Item>

        <Form.Item>
          <Button type="primary" block htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateCaseTemplate;
