import axios from "axios";
import { deleteCookie, getCookie, setCookie } from "./cookie";
import { api } from "../api/api_route";

export const handleAuthentication = async (errorConfig) => {
  if (errorConfig?.config?.url.includes("refreshToken")) {
    resetDataAndLogout();
  } else {
    return await getRefreshToken(errorConfig);
  }
};

export const getRefreshToken = async (errorConfig) => {
  try {
    const headerToken = `Bearer ${getCookie("refreshToken")}`;

    const refreshTokenResult = await axios.post(
      `${api}/users/refreshToken`,
      {},
      {
        headers: {
          Authorization: headerToken,
        },
      }
    );
    if (refreshTokenResult.data?.accessToken) {
      setLoginCookies(refreshTokenResult.data);
      const errorResult = await axios.request(errorConfig.config);

      return errorResult;
    } else {
      resetDataAndLogout();
    }
  } catch (error) {
    resetDataAndLogout();
  }
};

export const setLoginCookies = (user) => {
  setCookie("accessToken", user.accessToken, user.refreshExpiresIn);
  setCookie("accessExpiresIn", user.accessExpiresIn, user.refreshExpiresIn);
  setCookie("refreshToken", user.refreshToken, user.refreshExpiresIn);

  setCookie("refreshExpiresIn", user.refreshExpiresIn, user.refreshExpiresIn);
};
export const resetDataAndLogout = () => {
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      ?.replace(/^ +/, "")
      ?.replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};
