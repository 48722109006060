import * as React from "react";
import { useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { Dialog, DialogTitle } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserAction,
  getUsersAction,
  updateUserAction,
} from "../../../redux/actions/UserAction/usersAction";
import { animateCss } from "../../TosterAlert";
import {
  allOrganizationAction,
  createOrganizationAction,
  updateOrganizationAction,
} from "../../../redux/actions/OrganizationAction/OrganizationAction";

const state = [
  { name: "Andaman and Nicobar Islands", abbreviation: "AN" },
  { name: "Andhra Pradesh", abbreviation: "AP" },
  { name: "Arunachal Pradesh", abbreviation: "AR" },
  { name: "Assam", abbreviation: "AS" },
  { name: "Bihar", abbreviation: "BR" },
  { name: "Chandigarh", abbreviation: "CH" },
  { name: "Chhattisgarh", abbreviation: "CT" },
  { name: "Dadra and Nagar Haveli", abbreviation: "DN" },
  { name: "Daman and Diu", abbreviation: "DD" },
  { name: "Delhi", abbreviation: "DL" },
  { name: "Goa", abbreviation: "GA" },
  { name: "Gujarat", abbreviation: "GJ" },
  { name: "Haryana", abbreviation: "HR" },
  { name: "Himachal Pradesh", abbreviation: "HP" },
  { name: "Jammu and Kashmir", abbreviation: "JK" },
  { name: "Jharkhand", abbreviation: "JH" },
  { name: "Karnataka", abbreviation: "KA" },
  { name: "Kerala", abbreviation: "KL" },
  { name: "Ladakh", abbreviation: "LA" },
  { name: "Lakshadweep", abbreviation: "LD" },
  { name: "Madhya Pradesh", abbreviation: "MP" },
  { name: "Maharashtra", abbreviation: "MH" },
  { name: "Manipur", abbreviation: "MN" },
  { name: "Meghalaya", abbreviation: "ML" },
  { name: "Mizoram", abbreviation: "MZ" },
  { name: "Nagaland", abbreviation: "NL" },
  { name: "Odisha", abbreviation: "OR" },
  { name: "Puducherry", abbreviation: "PY" },
  { name: "Punjab", abbreviation: "PB" },
  { name: "Rajasthan", abbreviation: "RJ" },
  { name: "Sikkim", abbreviation: "SK" },
  { name: "Tamil Nadu", abbreviation: "TN" },
  { name: "Telangana", abbreviation: "TG" },
  { name: "Tripura", abbreviation: "TR" },
  { name: "Uttar Pradesh", abbreviation: "UP" },
  { name: "Uttarakhand", abbreviation: "UT" },
  { name: "West Bengal", abbreviation: "WB" },
];

const CreateOrganization = ({ open, setOpen, EditValue }) => {
  const userId = useSelector((state) => state?.login.data);
  const organizationName = useSelector((state) => state?.organization?.name);

  const dispatch = useDispatch();

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} className=" col-12">
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          disableRestoreFocus
        >
          <Grid
            className="m-0 p-4 "
            sx={{
              width: 400,
              "@media (min-width: 300px)": {
                width: 300,
              },
              "@media (min-width: 400px)": {
                width: 350,
              },
              "@media (minn-width: 600px)": {
                width: 280,
              },
              "@media (min-width: 900px)": {
                width: 400,
              },
              "@media (min-width: 1200px)": {
                width: 500,
              },
              "@media (min-width: 1536px)": {
                width: 600,
              },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <DialogTitle className="fs-5">
                {EditValue.id ? "Update Organization" : "Create Organization"}
              </DialogTitle>
              <IconButton
                onClick={() => setOpen(false)}
                aria-label="delete"
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <hr />

            <Formik
              initialValues={{
                organizationName: EditValue.id
                  ? EditValue.organizationName
                  : "",
                clientCode: EditValue.clientCode ? EditValue.clientCode : null,
                address1: EditValue.id ? EditValue.address1 : "",
                address2: EditValue.id ? EditValue.address2 : "",
                city: EditValue.id ? EditValue.city : "",
                state: EditValue.id ? EditValue.state : "",
                email: EditValue.id ? EditValue.email : "",
                contactEmail: EditValue.id ? EditValue.contactEmail : "",
                mobileNo: EditValue.id ? EditValue.mobileNo : "",
                contactPerson: EditValue.id ? EditValue.contactPerson : "",
                notes: EditValue.id ? EditValue.notes : "",
                id: EditValue.id || null,
                user: userId,
              }}
              validationSchema={Yup.object().shape({
                organizationName: Yup.string()
                  .trim()
                  .min(3)
                  .required("organization Name is required"),
                clientCode: Yup.string()
                  .trim()
                  .min(1, "client Code  min 1 Character")
                  .required("Client Code is required"),
                city: Yup.string().trim().min(3).required("City is required"),
                state: Yup.string().trim().min(3).required("State is required"),
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
                contactEmail: Yup.string()
                  .email("Must be a valid Contact Email")
                  .max(255)
                  .required("Contact Email is required"),
                mobileNo: Yup.string()
                  .trim()
                  .matches(/^[0-9\s+\-()]*$/, "Invalid phone number")
                  .length(10, "Phone number must be exactly 10 characters")
                  .required("Phone is required"),
                contactPerson: Yup.string()
                  .trim()
                  .min(3, "Contact Person min 3 Character")
                  .required("Contact Person is required"),
              })}
              onSubmit={async (values, actions) => {
                try {
                  if (!values?.id) {
                    if (
                      !organizationName?.includes(
                        values.organizationName.toLowerCase()
                      )
                    ) {
                      dispatch(createOrganizationAction(values));
                      setOpen(false);
                      dispatch(allOrganizationAction());
                    } else if (
                      organizationName?.includes(
                        values?.organizationName.toLowerCase()
                      )
                    ) {
                      animateCss(
                        "warn",
                        "Organization Name Already Existing",
                        1000
                      );
                    }
                  } else if (values.id) {
                    if (
                      !organizationName?.includes(
                        values?.organizationName.toLowerCase()
                      ) ||
                      EditValue?.organizationName.toLowerCase() ==
                        values?.organizationName?.toLowerCase()
                    ) {
                      dispatch(updateOrganizationAction(values));
                      dispatch(allOrganizationAction());
                      setOpen(false);
                    } else if (
                      organizationName.includes(values.organizationName) ||
                      EditValue.organizationName !== values.organizationName
                    ) {
                      animateCss(
                        "warn",
                        "Organization Name Already Existing",
                        1000
                      );
                    }
                  }
                } catch (error) {}
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Stack spacing={1}>
                    <TextField
                      required
                      autoFocus={true}
                      id="organizationName"
                      type="text"
                      label="Organization Name"
                      value={values.organizationName}
                      onChange={handleChange}
                      name="organizationName"
                      onBlur={handleBlur}
                      placeholder="Enter The Name"
                      fullWidth
                      error={Boolean(
                        touched.organizationName && errors.organizationName
                      )}
                    />

                    <FormHelperText className="text-danger">
                      {touched?.organizationName && errors?.organizationName}
                    </FormHelperText>

                    <TextField
                      required
                      id="clientCode"
                      type="text"
                      label="Client Code"
                      value={values.clientCode}
                      onChange={handleChange}
                      name="clientCode"
                      onBlur={handleBlur}
                      placeholder="Enter The Client Code"
                      fullWidth
                      error={Boolean(touched.clientCode && errors.clientCode)}
                    />

                    <FormHelperText className="text-danger">
                      {touched?.clientCode && errors?.clientCode}
                    </FormHelperText>

                    <TextField
                      id="address1"
                      type="text"
                      label="Address Door No"
                      name="address1"
                      value={values.address1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.address1 && errors.address1)}
                    />
                    <FormHelperText className="text-danger">
                      {touched?.address1 && errors?.address1}
                    </FormHelperText>

                    <TextField
                      id="address2"
                      type="text"
                      label="Address Street / Area"
                      name="address2"
                      value={values.address2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.address2 && errors.address2)}
                    />

                    <FormHelperText className="text-danger">
                      {touched?.address2 && errors?.address2}
                    </FormHelperText>

                    <TextField
                      required
                      type="text"
                      id="city"
                      label="City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.city && errors.city)}
                    />
                    <FormHelperText className="text-danger">
                      {touched?.city && errors?.city}
                    </FormHelperText>

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        State
                      </InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                        id="state"
                        label="State"
                        name="state"
                        defaultValue={null}
                        value={values.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.state && errors.state)}
                      >
                        {state.map((item) => {
                          return (
                            <MenuItem key={item.name} value={item.name}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {touched.state && errors.state && (
                      <FormHelperText
                        className="text-danger"
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.state}
                      </FormHelperText>
                    )}

                    <FormHelperText className="text-danger">
                      {touched?.state && errors?.state}
                    </FormHelperText>

                    <TextField
                      required
                      id="email"
                      type="email"
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.email && errors.email)}
                    />

                    <FormHelperText className="text-danger">
                      {touched?.email && errors?.email}
                    </FormHelperText>

                    <TextField
                      required
                      id="contactPerson"
                      type="text"
                      label="Primary Point of Contact Name"
                      name="contactPerson"
                      value={values.contactPerson}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.contactPerson && errors.contactPerson
                      )}
                    />

                    <FormHelperText className="text-danger">
                      {touched?.contactPerson && errors?.contactPerson}
                    </FormHelperText>

                    <TextField
                      required
                      id="contactEmail"
                      type="email"
                      label="Primary Point of Contact Email"
                      value={values.contactEmail}
                      onChange={handleChange}
                      name="contactEmail"
                      onBlur={handleBlur}
                      placeholder="Enter The Contact Email"
                      fullWidth
                      error={Boolean(
                        touched.contactEmail && errors.contactEmail
                      )}
                    />

                    <FormHelperText className="text-danger">
                      {touched?.contactEmail && errors?.contactEmail}
                    </FormHelperText>

                    <TextField
                      required
                      id="mobileNo"
                      type="mobile"
                      label="Primary Point of Phone"
                      name="mobileNo"
                      value={values.mobileNo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.mobileNo && errors.mobileNo)}
                    />

                    <FormHelperText className="text-danger">
                      {touched?.mobileNo && errors?.mobileNo}
                    </FormHelperText>

                    <TextField
                      id="notes"
                      name="notes"
                      label="Notes"
                      multiline
                      rows={3}
                      value={values.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.notes && Boolean(errors.notes)}
                    />

                    <FormHelperText className="text-danger">
                      {touched?.notes && errors?.notes}
                    </FormHelperText>

                    <hr className="mt-4" />
                    <Stack
                      flexDirection={"row"}
                      display={"flex"}
                      justifyContent="flex-end"
                    >
                      <Button
                        className="btn btn-secondary mx-2 my-1"
                        type="cancel"
                        onClick={() => setOpen(false)}
                      >
                        cancel
                      </Button>

                      <Button
                        className="btn btn-primary mx-2 my-1"
                        type="submit"
                      >
                        {EditValue.id ? "Update" : "Submit"}
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              )}
            </Formik>
          </Grid>
        </Dialog>
      </Modal>
    </>
  );
};
export default CreateOrganization;
