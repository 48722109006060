import * as React from "react";
import { useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { Dialog, DialogTitle } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { animateCss } from "../../TosterAlert";
import {
  allChecklistAction,
  createChecklistAction,
  updateChecklistAction,
} from "../../../redux/actions/Checklist/checklistAction";
import { checklistDocumentUplodOptionsAction } from "../../../redux/actions/checklistDocuments/checklistDocumentsAction";

const ChecklistForm = ({ open, setOpen, EditValue }) => {
  const userId = useSelector((state) => state?.login.data);
  const checklistName = useSelector((state) => state?.checklist?.name);

  const dispatch = useDispatch();

  const settings = useSelector((state) => state?.ChecklistDocument?.settings);

  React.useEffect(() => {
    dispatch(checklistDocumentUplodOptionsAction());
  }, []);

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} className=" col-12">
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          disableRestoreFocus
        >
          <Grid
            className="m-0 p-4 "
            sx={{
              width: 400,
              "@media (min-width: 300px)": {
                width: 300,
              },
              "@media (min-width: 400px)": {
                width: 350,
              },
              "@media (minn-width: 600px)": {
                width: 280,
              },
              "@media (min-width: 900px)": {
                width: 400,
              },
              "@media (min-width: 1200px)": {
                width: 400,
              },
              "@media (min-width: 1536px)": {
                width: 400,
              },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <DialogTitle className="fs-5">
                {EditValue.checklistID
                  ? "Update Checklist"
                  : "Create Checklist"}
              </DialogTitle>
              <IconButton
                onClick={() => setOpen(false)}
                aria-label="delete"
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <hr />

            <Formik
              initialValues={{
                caseType: EditValue.checklistID
                  ? EditValue.CaseType.generalSettingsID
                  : "",
                checklistName: EditValue.checklistID
                  ? EditValue.checklistName
                  : "",
                order: EditValue.checklistID ? EditValue.order : "",
                id: EditValue.checklistID || null,
                user: userId,
              }}
              validationSchema={Yup.object().shape({
                caseType: Yup.number()
                  .min(1, "Please Select The Option")
                  .required("Case Type is required"),
                checklistName: Yup.string()
                  .min(3, "Please Enter 3 Character")
                  .required("Checklist Name is required"),
                order: Yup.number()
                  .min(1, "Please Enter valid Order")
                  .required("Order is required"),
              })}
              onSubmit={async (values, actions) => {
                try {
                  if (!EditValue.checklistID) {
                    // if (
                    //   !checklistName.includes(
                    //     values.checklistName.toLowerCase()
                    //   )
                    // ) {
                    await dispatch(createChecklistAction(values));
                    setOpen(false);
                    // } else {
                    //   animateCss("warn", "Checklist Name Already Existing");
                    // }
                  } else if (EditValue.checklistID) {
                    if (
                      checklistName.includes(
                        values.checklistName.toLowerCase()
                      ) &&
                      EditValue.checklistName.toLowerCase() ==
                        values.checklistName.toLowerCase()
                    ) {
                      await dispatch(updateChecklistAction(values));
                      setOpen(false);
                    } else if (
                      !checklistName.includes(
                        values.checklistName.toLowerCase()
                      ) ||
                      EditValue.checklistName.toLowerCase() !==
                        values.checklistName.toLowerCase()
                    ) {
                      await dispatch(updateChecklistAction(values));
                      setOpen(false);
                    }
                  }
                  await dispatch(allChecklistAction());
                } catch (error) {}
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Stack spacing={2}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.caseType && errors.caseType)}
                      sx={{ mb: 2 }}
                    >
                      <InputLabel
                        htmlFor="caseType"
                        sx={{ textWrap: "balance" }}
                      >
                        Case Type
                      </InputLabel>

                      <Select
                        autoFocus={true}
                        labelId="caseType"
                        id="caseType"
                        name="caseType"
                        value={values?.caseType}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                      >
                        {settings
                          ?.find((item) => item.categoryName == "Case Type")
                          ?.Children?.map((data, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={data?.generalSettingsID}
                              >
                                {data?.categoryName}
                              </MenuItem>
                            );
                          })}
                      </Select>

                      {touched.caseType && errors.caseType && (
                        <FormHelperText className="text-danger">
                          {errors.caseType}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <TextField
                      required
                      id="checklistName"
                      type="text"
                      label="Checklist Name"
                      value={values.checklistName}
                      onChange={handleChange}
                      name="checklistName"
                      onBlur={handleBlur}
                      placeholder="Enter The Checklist Name"
                      fullWidth
                      error={Boolean(
                        touched.checklistName && errors.checklistName
                      )}
                    />

                    <FormHelperText className="text-danger">
                      {touched?.checklistName && errors?.checklistName}
                    </FormHelperText>

                    <TextField
                      required
                      id="order"
                      type="number"
                      label="Order"
                      name="order"
                      value={values.order}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.order && errors.order)}
                    />

                    <FormHelperText className="text-danger">
                      {touched?.order && errors?.order}
                    </FormHelperText>

                    <hr className="mt-4" />
                    <Stack
                      flexDirection={"row"}
                      display={"flex"}
                      justifyContent="flex-end"
                    >
                      <Button
                        className="btn btn-secondary mx-2 my-1"
                        type="cancel"
                        onClick={() => setOpen(false)}
                      >
                        cancel
                      </Button>

                      <Button
                        className="btn btn-primary mx-2 my-1"
                        type="submit"
                      >
                        {EditValue.id ? "Update" : "Submit"}
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              )}
            </Formik>
          </Grid>
        </Dialog>
      </Modal>
    </>
  );
};
export default ChecklistForm;
