// assets
import { HomeOutlined } from "@ant-design/icons";

// icons
const icons = {
  HomeOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const home = {
  id: "Home",
  title: "Home",
  type: "group",
  children: [
    // {
    //   id: "dashboard",
    //   title: "Dashboard",
    //   type: "item",
    //   url: "/dashboard/default",
    //   icon: icons.DashboardOutlined,
    //   breadcrumbs: false,
    // },
    {
      id: "Home",
      title: "Home",
      type: "item",
      url: "/home",
      icon: icons.HomeOutlined,
      breadcrumbs: false,
    },
  ],
};

export default home;
