import { createSlice } from "@reduxjs/toolkit";
import {
  createUserRoleAction,
  deleteUserRoleAction,
  getUserRoleAction,
  updateUserRoleAction,
  updateUserRoleIsActiveAction,
} from "../../actions/UserAction/userRoleAction";
import { animateCss } from "../../../pages/TosterAlert";

const userRoleSliceIntial = {
  roles: [],
  listLoading: false,
  createUserRoles: {
    loading: false,
    error: "",
  },
};

const usersSlice = createSlice({
  name: "roles",
  initialState: userRoleSliceIntial,
  reducers: {
    resetCreateUserRoles: (state, { payload }) => {
      state = { ...state, ...userRoleSliceIntial.createUserRoles };
      return state;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getUserRoleAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getUserRoleAction.fulfilled, (state, action) => {
        const value = [];
        state.loading = false;
        action?.payload?.data?.forEach(({ role }) => {
          value.push(role.toLowerCase().trim());
        });
        state.roles = value;
        return state;
      })
      .addCase(getUserRoleAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createUserRoleAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(createUserRoleAction.fulfilled, (state, action) => {
        state.loading = false;

        return state;
      })
      .addCase(createUserRoleAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateUserRoleAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(updateUserRoleAction.fulfilled, (state, action) => {
        state.loading = false;

        return state;
      })
      .addCase(updateUserRoleAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateUserRoleIsActiveAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(updateUserRoleIsActiveAction.fulfilled, (state, action) => {
        const isACtiveStatus = action.payload?.isActive;
        if (isACtiveStatus) {
          animateCss("success", "User Status Active");
        } else if (!isACtiveStatus) {
          animateCss("error", "User Status Inactive");
        }
        state.loading = false;

        return state;
      })
      .addCase(updateUserRoleIsActiveAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteUserRoleAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(deleteUserRoleAction.fulfilled, (state, action) => {
        const isACtiveStatus = action.payload?.deleteRole;
        if (isACtiveStatus) {
          animateCss("success", "User Role Deleted Successfully");
        } else if (!isACtiveStatus) {
          animateCss("error", "User Role had use to the User Profile", 2500);
        }
        state.loading = false;

        return state;
      })
      .addCase(deleteUserRoleAction.rejected, (state, action) => {
        state.loading = false;
      }),
});

export const { resetCreateUserRoles } = usersSlice.actions;
export default usersSlice.reducer;
