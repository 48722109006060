import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allOrganizationAction } from "../../../../redux/actions/OrganizationAction/OrganizationAction";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  FormHelperText,
  Grid,
  Slide,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useMediaQuery } from "@mantine/hooks";
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { IoCloseOutline } from "react-icons/io5";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  allCaseGroupingAction,
  createCaseGroupingAction,
  deleteCaseGroupingAction,
  updateCaseGroupingAction,
} from "../../../../redux/actions/TemplateGrouping/TemplateGroupingAction";
import { MdDelete } from "react-icons/md";
import { animateCss } from "../../../TosterAlert";

const TemplateGroupList = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [openGrouping, setOpeningGrouping] = useState(false);

  const [editValue, setEditValue] = useState({});

  const apiValues = useSelector((state) => state?.users);
  const templateCaseGrouping = useSelector(
    (state) => state.templateGroupting?.data
  );

  const userId = useSelector((state) => state?.login.data);

  const tableStyle = {
    WebkitAlignItems: "flex-start",
    WebkitBoxAlign: "flex-start",
    MsFlexAlign: "flex-start",
    alignItems: "flex-start",
    boxSizing: "border-box",
    display: "-webkit-box",
    display: "-webkit-flex",
    display: "-ms-flexbox",
    display: "flex",
    gap: "0.5rem",
    WebkitBoxPack: "justify",
    WebkitJustifyContent: "space-between",
    justifyContent: "flex-end",
    padding: "0.5rem",
    position: "relative",
    right: 0,
    top: 0,
    width: "100%",
  };

  useEffect(() => {
    dispatch(allOrganizationAction());
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const columns = useMemo(
    () => [
      {
        accessorKey: "groupingName",
        header: "grouping Name",
        size: 150,
        Cell: ({ row }, rowIndex) => {
          return (
            <Link
              style={{ textDecoration: "none" }}
              to=""
              onClick={() => {
                if (window.edit) {
                  setEditValue(row.original);
                  setOpeningGrouping(true);
                } else {
                  animateCss("info", "You are not authorized to update");
                }
              }}
            >
              {row?.original?.groupingName}
            </Link>
          );
        },
      },
      {
        accessorKey: "templateGroupingID",
        header: "Action",
        size: 150,
        Cell: ({ row }, rowIndex) => {
          return (
            <>
              <IconButton
                aria-label="delete"
                size="large"
                onClick={() => {
                  if (window.delete) {
                    dispatch(deleteCaseGroupingAction(row.original));
                    setTimeout(() => {
                      dispatch(allCaseGroupingAction());
                    }, []);
                  } else {
                    animateCss("info", "You are not authorized to delete");
                  }
                }}
              >
                <MdDelete className="text-danger" />
              </IconButton>
            </>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: templateCaseGrouping ?? [],
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    enableFullScreenToggle: false,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    enableRowVirtualization: true,
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableStickyHeader: true,
    enableGlobalFilter: true,
    initialState: {
      pagination: { pageSize: 5, pageIndex: 0 },
      density: "compact",
    },
    keyAccessor: (row, rowIndex) =>
      row.orderStatus + "_" + row.id + "_" + rowIndex,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        // style={tableStyle}
        className="text-primary"
      >
        <Button onClick={() => setOpeningGrouping(true)}>Create</Button>
      </Box>
    ),
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        maxWidth={"sm"}
      >
        <AppBar
          sx={{
            position: "relative",
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <IoCloseOutline />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Template Grouping
            </Typography>
          </Toolbar>
        </AppBar>
        {openGrouping === true && (
          <div className=" bg-white shadow p-1 mt-2 mb-1 rounded">
            <h5>
              {editValue?.groupingName
                ? "Update Template Grouping"
                : "Create Template Grouping"}
            </h5>
            <Formik
              initialValues={{
                templateGrouping: editValue?.groupingName
                  ? editValue.groupingName
                  : "",
                userId,
              }}
              validationSchema={Yup.object().shape({
                templateGrouping: Yup.string()
                  .trim()
                  .min(3, "Template Group must be at least 3 characters")
                  .required("Template Group is required"),
              })}
              onSubmit={async (values, actions) => {
                try {
                  if (editValue.groupingName) {
                    const value = { values, editValue };
                    const createValue = await dispatch(
                      updateCaseGroupingAction(value)
                    ).unwrap();

                    if (createValue.data) {
                      setOpeningGrouping(false);
                      setEditValue({});
                      setTimeout(() => {
                        dispatch(allCaseGroupingAction());
                      }, 1000);
                    }
                  } else if (!editValue?.groupingName) {
                    const createValue = await dispatch(
                      createCaseGroupingAction(values)
                    ).unwrap();

                    if (createValue.data) {
                      setOpeningGrouping(false);
                      setTimeout(() => {
                        dispatch(allCaseGroupingAction());
                      }, 1000);
                    }
                  }
                } catch (error) {
                  console.error("Error uploading:", error);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                resetForm,
              }) => (
                <form className="my-4 mx-4" onSubmit={handleSubmit}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Grid className="justify-content-center">
                      <TextField
                        required
                        id="templateGrouping"
                        type="text"
                        label="Template Grouping"
                        name="templateGrouping"
                        value={values.templateGrouping}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.templateGrouping && errors.templateGrouping
                        )}
                      />

                      <FormHelperText className="error-text text-danger">
                        {touched?.templateGrouping && errors?.templateGrouping}
                      </FormHelperText>
                    </Grid>

                    <Button className=" text-primary " type="submit">
                      Submit
                    </Button>
                    <Button
                      className=" text-secondary "
                      onClick={() => {
                        setOpeningGrouping(false);
                        setEditValue({});
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        )}
        <MaterialReactTable table={table} />
      </Dialog>
    </>
  );
};

export default TemplateGroupList;
