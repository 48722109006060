import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getOrganizationDocuments,
  organizationAwards,
  organizationDocumentIsActive,
  organizationUploadDocuments,
} from "../../../api/organization_api";

export const getOrganizationDocumentAction = createAsyncThunk(
  "organizationDocuments/getOrganizationDocuments",
  async (values, { rejectWithValue, getState }) => {
    try {
      const organizationDocuments = await getOrganizationDocuments();
      return organizationDocuments.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const getUserOrganizationDocumentAction = createAsyncThunk(
  "organizationDocuments/getUserOrganizationDocumentAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const organizationDocuments = await getOrganizationDocuments();
      let data =
        organizationDocuments.data?.filter(
          (item) =>
            item?.organisationID === values.organisationName &&
            item.isActive == true
        ) || [];
      return data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
export const organizationAwardsAction = createAsyncThunk(
  "organizationDocuments/organizationAwardsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const organizationDocuments = await organizationAwards(values);

      return organizationDocuments?.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const organizationDocumentUploadAction = createAsyncThunk(
  "organizationDocuments/organizationDocumentUploadAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const organizationDocuments = await organizationUploadDocuments(values);
      return organizationDocuments;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const organizationDocumentIsActiveAction = createAsyncThunk(
  "organizationDocuments/organizationDocumentIsActiveAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const organizationDocuments = await organizationDocumentIsActive(values);
      return organizationDocuments;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
