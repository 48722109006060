import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Chip,
  FormHelperText,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import ReactDatePicker from "react-datepicker";
const fakeData = [];

export const usStates = ["No", "Yes"];

const DatePickerField = ({ field, form, ...other }) => {
  const { name, value } = field;

  const handleChange = (val) => {
    form.setFieldValue(name, val);
  };

  return (
    <>
      <ReactDatePicker
        sx={{ m: 0, p: 2 }}
        className="p-2 form-control"
        {...other}
        selected={value}
        onChange={handleChange}
        dateFormat="dd-MM-yyyy"
        placeholderText="Select Date"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        error={form.touched[name] && form.errors[name]}
      />
      {form.touched[name] && form.errors[name] && (
        <FormHelperText error>{form.errors[name]}</FormHelperText>
      )}
    </>
  );
};

const AllotmentMapping = ({ excelHeader }) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [editedUsers, setEditedUsers] = useState({});

  const caseMappingID = useSelector(
    (state) => state.case?.caseMappingDetails?.mappingDetail
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",

        size: 80,
      },
      {
        accessorKey: "header",
        header: "Document Upload Header",
        enableEditing: false,
      },

      {
        accessorKey: "matchHeader",
        header: "Template Actual Header",
        enableEditing: false,
        Cell: ({ row }) => {
          return (
            <Typography>
              {row?.original?.matchHeader ? row?.original?.header : ""}
              <br />
              {row?.original?.fieldNotNeed && (
                <span className="text-danger"> - Field Not Needed</span>
              )}
            </Typography>
          );
        },
      },
      {
        accessorKey: "alterHeader",
        header: " Template Alter Header",
        enableEditing: false,
      },
      {
        accessorKey: "fieldNotNeed",
        header: "Status",
        enableEditing: false,
        size: 130,
        Cell: ({ cell, row }) => {
          return (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip title="Field Not Needed">
                <ListItem>
                  <Chip
                    label={
                      row?.original?.matchHeader ? "Matched" : "Not Matching"
                    }
                    style={
                      row?.original?.matchHeader
                        ? { backgroundColor: "green", color: "white" }
                        : { backgroundColor: "red", color: "white" }
                    }
                  />
                </ListItem>
              </Tooltip>
            </Box>
          );
        },
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: "checkbox",
          checked: row.original.fieldNotNeeded === true,
          onBlur: (event) => {
            const isChecked = event.target.checked;
            const newValue = isChecked ? true : false;

            setEditedUsers((prevUsers) => ({
              ...prevUsers,
              [cell.row.id]: {
                ...row.original,
                fieldNotNeeded: newValue,
                id: cell.row.id,
              },
            }));
          },
        }),
      },
    ],
    [editedUsers]
  );
  const dispatch = useDispatch();

  const table = useMaterialReactTable(
    {
      columns,
      data: excelHeader ?? [],
      enableRowNumbers: true,
      rowNumberDisplayMode: "original",
      enableFullScreenToggle: false,
      enablePagination: false,
      enableRowSelection: false,
      columnFilterDisplayMode: "popover",
      enableRowVirtualization: true,
      paginationDisplayMode: "pages",
      positionToolbarAlertBanner: "bottom",
      enableStickyHeader: true,
      enableGlobalFilter: true,
      editDisplayMode: "cell" || "table",
      initialState: {
        columnVisibility: { id: false },
        density: "compact",
      },
      keyAccessor: (row, rowIndex) =>
        row.orderStatus + "_" + row.id + "_" + rowIndex,

      createDisplayMode: "table",
      enableEditing: true,

      positionActionsColumn: "last",
      getRowId: (row) => row?.id,

      onCreatingRowCancel: () => setValidationErrors({}),
    },
    []
  );

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

export default AllotmentMapping;
