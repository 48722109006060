import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  allotmentDocumentUpload,
  allotmentOrganizationCaseDetails,
  allotmentOrganizationCaseTemplate,
  deleteAllotmentDeleteCaseDetails,
  selectAllotmentDeleteCaseDetails,
  selectAllotmentOrganizationCaseDetails,
} from "../../../api/allotmentDocuments_api";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { animateCss } from "../../../pages/TosterAlert";
import { Button, styled } from "@mui/material";
import { useDispatch } from "react-redux";

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

export const allotmentDocumentUploadAction = createAsyncThunk(
  "allotmentDocuments/allotmentDocumentUploadAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const data = {
        excelData: values?.excelAlterData,
        mappingData: values?.values,
      };
      const formData = new FormData();

      if (values.uploadedFiles) {
        for (const file of values.uploadedFiles) {
          formData.append("files", file);
        }
      }

      formData.append("data", JSON.stringify(data));

      const result = await allotmentDocumentUpload(formData);

      const serializedHeaders = {
        contentType: result.headers["content-type"],
      };
      return { data: result.data, headers: serializedHeaders };
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const selectAllotmentOrganizationCaseDetailsAction = createAsyncThunk(
  "allotmentDocuments/selectAllotmentOrganizationCaseDetailsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await selectAllotmentOrganizationCaseDetails(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const allotmentOrganizationCaseAction = createAsyncThunk(
  "allotmentDocuments/allotmentOrganizationCaseAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await allotmentOrganizationCaseDetails(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const selectAllotmentDeleteCaseDetailsAction = createAsyncThunk(
  "allotmentDocuments/selectAllotmentDeleteCaseDetailsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await selectAllotmentDeleteCaseDetails(values);

      return result?.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const deleteAllotmentDeleteCaseDetailsAction = createAsyncThunk(
  "allotmentDocuments/deleteAllotmentDeleteCaseDetailsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await deleteAllotmentDeleteCaseDetails(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
