import axios from "axios";
import { api } from "./api_route";

export const allChecklist = async (values) => {
  try {
    const result = await axios.get(`${api}/checklist/`);

    return result.data;
  } catch (error) {
    return [];
  }
};

export const createChecklist = async (values) => {
  try {
    const result = await axios.post(`${api}/checklist/create`, { values });

    return result.data;
  } catch (error) {
    return [];
  }
};

export const updateChecklist = async (values) => {
  try {
    const result = await axios.post(`${api}/checklist/update`, { values });

    return result.data;
  } catch (error) {
    return [];
  }
};

export const isActiveChecklist = async (values) => {
  try {
    const result = await axios.post(`${api}/checklist/isActive`, { values });

    return result.data;
  } catch (error) {
    return [];
  }
};

export const deleteChecklist = async (values) => {
  try {
    const result = await axios.post(`${api}/checklist/delete`, { values });

    return result.data;
  } catch (error) {
    return [];
  }
};

export const caseChecklistDocument = async (values) => {
  try {
    const result = await axios.post(
      `${api}/checklist/caseChecklistDocument`,
      values
    );

    return result.data;
  } catch (error) {
    return [];
  }
};
