import { createSlice } from "@reduxjs/toolkit";
import { animateCss } from "../../../pages/TosterAlert";
import {
  allChecklistAction,
  caseChecklistDocumentAction,
  createChecklistAction,
  deleteChecklistAction,
  isActiveChecklistAction,
  updateChecklistAction,
} from "../../actions/Checklist/checklistAction";

const checklist = {
  list: [],
  name: [],
  listLoading: false,
  createChecklist: {
    loading: false,
    error: "",
  },
};

const checklistSlice = createSlice({
  name: "checklist",
  initialState: checklist,
  reducers: {
    resetChecklist: (state, { payload }) => {
      state = { ...state, ...checklistSlice.createChecklist };
      return state;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(allChecklistAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(allChecklistAction.fulfilled, (state, action) => {
        state.list = action.payload?.data;
        state.name = action.payload?.checklistName;
        state.loading = false;
        return state;
      })
      .addCase(allChecklistAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(caseChecklistDocumentAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(caseChecklistDocumentAction.fulfilled, (state, action) => {
        state.list = action.payload?.data;
        state.name = action.payload?.checklistName;
        state.loading = false;
        return state;
      })
      .addCase(caseChecklistDocumentAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createChecklistAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createChecklistAction.fulfilled, (state, action) => {
        if (action.payload?.data) {
          animateCss("success", "Checklist Create Successfully");
        } else if (!action.payload?.data) {
          animateCss("error", "Checklist Creation Failed");
        }
        state.loading = false;
        return state;
      })
      .addCase(createChecklistAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateChecklistAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateChecklistAction.fulfilled, (state, action) => {
        if (action.payload?.data) {
          animateCss("success", "Checklist Update Successfully");
        } else if (!action.payload?.data) {
          animateCss("error", "Checklist Updation Failed");
        }
        state.loading = false;
        return state;
      })
      .addCase(updateChecklistAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(isActiveChecklistAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(isActiveChecklistAction.fulfilled, (state, action) => {
        if (action.payload?.data) {
          animateCss("success", "Checklist isActive Update Successfully");
        } else if (!action.payload?.data) {
          animateCss("error", "Checklist isActive Updation Failed");
        }
        state.loading = false;
        return state;
      })
      .addCase(isActiveChecklistAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteChecklistAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteChecklistAction.fulfilled, (state, action) => {
        if (action.payload) {
          animateCss("error", "Checklist Deleted Successfully");
        } else if (!action.payload) {
          animateCss("warn", "Checklist Deleted Failed");
        }
        state.loading = false;
        return state;
      })
      .addCase(deleteChecklistAction.rejected, (state, action) => {
        state.loading = false;
      }),
});

export const { resetChecklist } = checklistSlice.actions;
export default checklistSlice.reducer;
