// assets
import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  AppstoreAddOutlined,
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const Profile = {
  id: "Profile",
  title: "Profile",
  type: "none",
  children: [
    {
      id: "Profile",
      title: "Profile",
      type: "collapse",
      url: "/profile",
      icon: "",
      children: [
        {
          id: "Profile",
          title: "View Profile",
          type: "item",
          url: "/profile/viewprofile",
        },
        {
          id: "Profile",
          title: "Change Password",
          type: "item",
          url: "/profile/changepassword",
        },
      ],
    },
  ],
};

export default Profile;
