import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import NotFound from "../pages/NotFoundPage";
import { useEffect } from "react";
import { getUsersRoleRouterAction } from "../redux/actions/UserRouters/UserRoleRouteAction";
import { getUserAction } from "../redux/actions/loginAction";
import { getCookie } from "../helpers/cookie";
import { Box, CircularProgress } from "@mui/material";
import { Triangle } from "react-loader-spinner";

export const ProtectedRoute = ({ children }) => {
  const login = useSelector((state) => state?.login || []);
  const userRoleRouters = useSelector((state) => state.userRoleRouters);

  const isLogggedIn = getCookie("accessToken");
  const userPath = userRoleRouters?.route?.some((userNav) => {
    return userNav.url.some(({ url }) => {
      if (url) {
        return window.location.pathname.includes(url);
      } else if (
        window.location.pathname == "/resetPassword" &&
        login.data.role.role.toLowerCase() == "admin"
      ) {
        return true;
      }
    });
  });

  const loading = userRoleRouters.loading || login.loading;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserAction());
  }, []);

  if (!isLogggedIn) {
    return <Navigate to="/" />;
  } else if (userRoleRouters?.route?.length > 0) {
    if (!userPath) {
      return children;
      // return <Navigate to={userRoleRouters?.landingPage?.[0]?.navPath} />;
    } else {
      return children;
    }
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Ensures it takes up the full viewport height
      }}
    >
      <Triangle
        visible={true}
        height="150"
        width="200"
        color="#a36ceb"
        ariaLabel="triangle-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </Box>
  );
};
export default ProtectedRoute;
