import React from "react";
import { DeleteIcon } from "../../icons.jsx";

export default function FileDeleteButton({ files, file, setDeleted }) {
  const deleteFileHandler = (e) => {
    const updatedFiles = files?.filter((item) => item !== file);
    setDeleted(updatedFiles);
  };
  return (
    <button
      className="grid place-items-center bg-amber-200 py-2 rounded-md w-3/4"
      onClick={deleteFileHandler}
    >
      <DeleteIcon />
    </button>
  );
}
