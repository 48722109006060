import axios from "axios";
import { api } from "./api_route";

export const getUserRole = async (values) => {
  try {
    const result = await axios.get(`${api}/userRole`);
    return result.data;
  } catch (error) {
    return [];
  }
};

export const cretaeUserRole = async (values) => {
  try {
    const result = await axios.post(`${api}/userRole/createUserRole`, {
      values,
    });

    return result.data;
  } catch (error) {
    return [];
  }
};

export const updateUserRole = async (values) => {
  try {
    const result = await axios.post(`${api}/userRole/updatedUserRole`, {
      values,
    });

    return result.data;
  } catch (error) {
    return [];
  }
};

export const updateUserRoleIsActive = async (values) => {
  try {
    const result = await axios.post(`${api}/userRole/updatedUserRoleIsActive`, {
      values,
    });

    return result.data;
  } catch (error) {
    return [];
  }
};

export const deleteUsersRole = async (values) => {
  try {
    const result = await axios.post(`${api}/userRole/deleteUserRole`, {
      values,
    });
    return result.data;
  } catch (error) {
    return [];
  }
};
