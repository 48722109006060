import { createSlice } from "@reduxjs/toolkit";
import {
  OrganizationCaseDetailsAction,
  allAgreementDocumentDeleteAction,
  allAgreementDocumentListAction,
  allCaseDetailsAction,
  allCaseTypeAction,
  caseBulkFilterAction,
  caseDetailsHistoryAction,
  caseTrackingUpdateAction,
  singleCaseDetailsAction,
  updateAppointmentOfArbitratorSec11and12Action,
  updateArbitrationClausAndAgreementAction,
  updateArbitrationDurationAndTimelineManagementAction,
  updateArbitrationEnforcementAndAppealsAction,
  updateArbitrationFeePaymentStatusAction,
  updateArbitrationHoldWithdrawalAndSettlementAction,
  updateArbitrationInterimOrdersReliefsAwardsAction,
  updateArbitrationNoticesKeyDatesAction,
  updateArbitrationWitnessEvidenceAndExpertReportAction,
  updateCaseDetailsAction,
  updateCasePrimaryInformationAction,
  updateInitiationOfArbitrationAction,
} from "../../actions/Case/caseAction";
import {
  allCaseMappingDetailAction,
  caseMappingDetailAction,
  caseMappingDetailIsActiveAction,
  caseMappingDetailUpdateAction,
  caseMappingUploadAction,
  organizationActiveCaseTemplateAction,
  selectedCaseMappingDetailAction,
} from "../../actions/Case/caseMappingAction";
import { animateCss } from "../../../pages/TosterAlert";
import { caseDetailsFindCaseIDAction } from "../../actions/Checklist/checklistAction";
import { createSplitChecklistDocumentAction } from "../../actions/checklistDocuments/checklistDocumentsAction";

const caseInitialState = {
  settings: [],
  history: [],
  editCaseDetails: null,
  caseMappingDetails: {
    allCaseMappingDetails: [],
    mappingDetail: [],
    mappingTemplate: [],
  },
  caseDetails: {
    allData: [],
    pageCount: null,
    caseTemplate: [],
  },
  caseFilter: null,
  allotment: {
    allotmentHeader: [],
    allotmentOrganization: [],
    allotmentOrganizationTemplate: [],
  },
  loading: false,
  data: null,
  createCase: {
    loading: false,
    error: "",
  },
  agreementDocumentList: null,
};

const filter = {
  caseID: true,
  allotmentDocDate: true,
  "organization.organizationName": true,
  "caseType.categoryName": true,
  "caseDetails.ARCNumber": true,
  "caseDetails.PAN/BankAttachmentIADate": true,
  "caseDetails.PAN/BankAttachmentOrderDate": true,
  "caseDetails.RTOIADate": true,
  "caseDetails.RTOOrderDate": true,
  "caseDetails.agreementNumber": true,
  "caseDetails.arbitratorAppointedBy": true,
  "caseDetails.caseName": true,
  "caseDetails.claimAmount": true,
  "caseDetails.claimant": true,
  "caseDetails.nameofArbitrator": true,
  "caseDetails.product": true,
  "caseDetails.productType": true,
  "caseDetails.propertyAttachmentIADate": true,
  "caseDetails.propertyAttachmentOrderDate": true,
  "caseDetails.repoIADate": true,
  "caseDetails.repoOrderDate": true,
  "caseDetails.respondent": true,
  "caseDetails.salaryAttachmentIADate": true,
  "caseDetails.salaryAttachmentOrderDate": true,
  "caseDetails.sec17OrderDate": true,
  "caseDetails.sec17Status": true,
  "caseDetails.tahsildarIADate": true,
  "caseDetails.tahsildarOrderDate": true,
  allocationDate: true,
  caseCloseDate: true,
  categoryName: true,
  "caseStatusID.categoryName": true,
  lrnNoticeDate: true,
  refNoticeDate: true,
  claimFillingDate: true,
  firstHearing: true,
  secondHearing: true,
  thirdHearing: true,
  finalHearing: true,
  awardDate: true,
  stage: true,
  "creator.name": true,
  "updator.name": true,
  updatedAt: true,
};

const caseSlice = createSlice({
  name: "case",
  initialState: caseInitialState,
  reducers: {
    resetMappingDetailsReducer: (state, action) => {
      state.caseMappingDetails.mappingDetail = [];
      state.caseMappingDetails.mappingTemplate = [];
      return state;
    },
    resetMappingAllDetailsReducer: (state, action) => {
      state.caseMappingDetails = {
        allCaseMappingDetails: [],
        mappingDetail: [],
        mappingTemplate: [],
      };

      return state;
    },
    allotmentHeaderReducer: (state, action) => {
      state.allotment.allotmentHeader = action.payload;
    },
    mappingTemplateReducer: (state, action) => {
      state.caseMappingDetails.mappingTemplate =
        action?.payload?.mappingData ?? action.payload;
      state.caseMappingDetails.mappingDetail = action?.payload?.mappingData
        ? action.payload
        : [];
      return state;
    },
    resetSingleCaseDetailsReducer: (state, action) => {
      state.editCaseDetails = null;
      return state;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(allCaseTypeAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(allCaseTypeAction.fulfilled, (state, action) => {
        state.settings = action.payload?.data?.[0];
        state.loading = false;
      })
      .addCase(allCaseTypeAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(allCaseMappingDetailAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(allCaseMappingDetailAction.fulfilled, (state, action) => {
        state.loading = false;
        state.caseMappingDetails.allCaseMappingDetails = action.payload;
      })
      .addCase(allCaseMappingDetailAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(selectedCaseMappingDetailAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(selectedCaseMappingDetailAction.fulfilled, (state, action) => {
        state.loading = false;
        state.caseMappingDetails.allCaseMappingDetails = action.payload;
        return state;
      })
      .addCase(selectedCaseMappingDetailAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(caseMappingUploadAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(caseMappingUploadAction.fulfilled, (state, action) => {
        state.loading = false;
        animateCss("success", "Case Template Created Successfully");
      })
      .addCase(caseMappingUploadAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(
        organizationActiveCaseTemplateAction.pending,
        (state, action) => {
          state.loading = true;
          return state;
        }
      )
      .addCase(
        organizationActiveCaseTemplateAction.fulfilled,
        (state, action) => {
          state.caseMappingDetails.allotmentOrganizationTemplate = [];
          if (action.payload) {
            state.allotment.allotmentOrganizationTemplate =
              action.payload.template;
          }
          if (!action.payload) {
            state.allotment.allotmentOrganizationTemplate = [];
          }

          state.loading = false;
          return state;
        }
      )
      .addCase(
        organizationActiveCaseTemplateAction.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      .addCase(caseMappingDetailUpdateAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(caseMappingDetailUpdateAction.fulfilled, (state, action) => {
        if (action.payload.data) {
          animateCss("success", "Case Template Update Successfully");
          state.caseMappingDetails.mappingDetail = [];
          state.caseMappingDetails.mappingTemplate = [];
        }
        state.loading = false;
      })
      .addCase(caseMappingDetailUpdateAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(caseMappingDetailIsActiveAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(caseMappingDetailIsActiveAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(caseMappingDetailIsActiveAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(OrganizationCaseDetailsAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(
        OrganizationCaseDetailsAction.fulfilled,
        (state, { payload }) => {
          state.caseDetails.allData = payload.data;
          state.caseDetails.pageCount = payload.pageCount;
          state.caseFilter = JSON.parse(payload?.filter) ?? filter;
          state.loading = false;
          return state;
        }
      )
      .addCase(OrganizationCaseDetailsAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(allCaseDetailsAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(allCaseDetailsAction.fulfilled, (state, { payload }) => {
        state.caseDetails.allData = payload.data;
        state.caseDetails.pageCount = payload.pageCount;
        state.caseFilter = JSON.parse(payload?.filter) ?? filter;
        state.loading = false;
        return state;
      })
      .addCase(allCaseDetailsAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(singleCaseDetailsAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(singleCaseDetailsAction.fulfilled, (state, action) => {
        let value = action.payload;
        value.alterCaseDetails = value?.alterCaseDetails
          ? JSON.parse(value?.alterCaseDetails)
          : null;
        state.editCaseDetails = value;
        state.loading = false;
        return state;
      })
      .addCase(singleCaseDetailsAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateCaseDetailsAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(updateCaseDetailsAction.fulfilled, (state, action) => {
        animateCss("success", "Case Detail Update Successfully");
        setTimeout(() => {
          allCaseDetailsAction();
        }, 1000);
        state.loading = false;
      })
      .addCase(updateCaseDetailsAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(caseDetailsHistoryAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(caseDetailsHistoryAction.fulfilled, (state, action) => {
        state.history = action.payload.data;

        state.loading = false;
      })
      .addCase(caseDetailsHistoryAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(caseTrackingUpdateAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(caseTrackingUpdateAction.fulfilled, (state, action) => {
        if (action.payload.data) {
          animateCss("success", "Case Tracking Update Successfully");
        }
        state.loading = false;
      })
      .addCase(caseTrackingUpdateAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(caseDetailsFindCaseIDAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(caseDetailsFindCaseIDAction.fulfilled, (state, action) => {
        state.loading = false;
        createSplitChecklistDocumentAction(action.meta.arg);
        return state;
      })
      .addCase(caseDetailsFindCaseIDAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateCasePrimaryInformationAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(updateCasePrimaryInformationAction.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateCasePrimaryInformationAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateInitiationOfArbitrationAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(updateInitiationOfArbitrationAction.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(updateInitiationOfArbitrationAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(
        updateAppointmentOfArbitratorSec11and12Action.pending,
        (state) => {
          state.loading = true;
          return state;
        }
      )

      .addCase(
        updateAppointmentOfArbitratorSec11and12Action.fulfilled,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(
        updateAppointmentOfArbitratorSec11and12Action.rejected,
        (state) => {
          state.loading = false;
        }
      )
      .addCase(
        updateArbitrationInterimOrdersReliefsAwardsAction.pending,
        (state) => {
          state.loading = true;
          return state;
        }
      )

      .addCase(
        updateArbitrationInterimOrdersReliefsAwardsAction.fulfilled,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(
        updateArbitrationInterimOrdersReliefsAwardsAction.rejected,
        (state) => {
          state.loading = false;
        }
      )
      .addCase(
        updateArbitrationWitnessEvidenceAndExpertReportAction.pending,
        (state) => {
          state.loading = true;
          return state;
        }
      )

      .addCase(
        updateArbitrationWitnessEvidenceAndExpertReportAction.fulfilled,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(
        updateArbitrationWitnessEvidenceAndExpertReportAction.rejected,
        (state) => {
          state.loading = false;
        }
      )
      .addCase(
        updateArbitrationHoldWithdrawalAndSettlementAction.pending,
        (state) => {
          state.loading = true;
          return state;
        }
      )

      .addCase(
        updateArbitrationHoldWithdrawalAndSettlementAction.fulfilled,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(
        updateArbitrationHoldWithdrawalAndSettlementAction.rejected,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(
        updateArbitrationEnforcementAndAppealsAction.pending,
        (state) => {
          state.loading = true;
          return state;
        }
      )

      .addCase(
        updateArbitrationEnforcementAndAppealsAction.fulfilled,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(
        updateArbitrationEnforcementAndAppealsAction.rejected,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(updateArbitrationFeePaymentStatusAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(updateArbitrationFeePaymentStatusAction.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(updateArbitrationFeePaymentStatusAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(updateArbitrationNoticesKeyDatesAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(updateArbitrationNoticesKeyDatesAction.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(updateArbitrationNoticesKeyDatesAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(
        updateArbitrationDurationAndTimelineManagementAction.pending,
        (state) => {
          state.loading = true;
          return state;
        }
      )

      .addCase(
        updateArbitrationDurationAndTimelineManagementAction.fulfilled,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(
        updateArbitrationDurationAndTimelineManagementAction.rejected,
        (state) => {
          state.loading = false;
        }
      )
      .addCase(updateArbitrationClausAndAgreementAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(updateArbitrationClausAndAgreementAction.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(updateArbitrationClausAndAgreementAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(allAgreementDocumentListAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(allAgreementDocumentListAction.fulfilled, (state, action) => {
        state.loading = false;
        state.agreementDocumentList = action.payload.data;
        return state;
      })

      .addCase(allAgreementDocumentListAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(allAgreementDocumentDeleteAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(allAgreementDocumentDeleteAction.fulfilled, (state) => {
        state.loading = false;
        return state;
      })

      .addCase(allAgreementDocumentDeleteAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(caseBulkFilterAction.pending, (state, action) => {
        state.loading = true;

        return state;
      })

      .addCase(caseBulkFilterAction.fulfilled, (state, action) => {
        state.loading = false;

        return state;
      })

      .addCase(caseBulkFilterAction.rejected, (state, action) => {
        state.loading = false;
      }),
});

export const {
  resetMappingDetailsReducer,
  resetMappingAllDetailsReducer,
  allotmentHeaderReducer,
  mappingTemplateReducer,
  resetSingleCaseDetailsReducer,
} = caseSlice.actions;
export default caseSlice.reducer;
