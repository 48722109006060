import axios from "axios";
import { api } from "./api_route";

export const createCaseGrouping = async (values) => {
  try {
    const result = await axios.post(`${api}/templateGrouping/create`, values);

    return result.data;
  } catch (error) {
    return [];
  }
};

export const allCaseGrouping = async (values) => {
  try {
    const result = await axios.get(`${api}/templateGrouping/`);
    return result.data;
  } catch (error) {
    return [];
  }
};

export const updateCaseGrouping = async (values) => {
  try {
    const result = await axios.post(`${api}/templateGrouping/update`, values);
    return result.data;
  } catch (error) {
    return [];
  }
};

export const deleteCaseGrouping = async (values) => {
  try {
    const result = await axios.post(`${api}/templateGrouping/delete`, values);
    return result.data;
  } catch (error) {
    return [];
  }
};
