import { combineReducers } from "redux";
import loginSlice from "./slices/loginSlice";
import usersSlice from "./slices/User/usersSlice";
import formSlice from "./slices/formSlice";
import userRoleSlice from "./slices/User/userRoleSlice";
import userDesignationSlice from "./slices/User/userDesignationSlice";
import organizationSlice from "./slices/Organization/OrganizationSlice";
import UserRoleRouteSlice from "./slices/UserRoute/UserRoleRouteSlice";
import checklistSlice from "./slices/Checklist/checklistSlice";
import caseSlice from "./slices/Case/caseSlice";
import allotmentDocumentSlice from "./slices/AllotmentDocuments/allotmentDocumentSlice";
import templateGroupingSlice from "./slices/TemplateGrouping/TemplateGroupingSlice";
import ChecklistDocumentSlice from "./slices/ChecklistDocuments/ChecklistDocumentSlice";

export const rootReducer = combineReducers({
  login: loginSlice,
  users: usersSlice,
  formData: formSlice,
  userRoles: userRoleSlice,
  userDesignation: userDesignationSlice,
  organization: organizationSlice,
  userRoleRouters: UserRoleRouteSlice,
  checklist: checklistSlice,
  ChecklistDocument: ChecklistDocumentSlice,
  case: caseSlice,
  templateGroupting: templateGroupingSlice,
  allotmentDocument: allotmentDocumentSlice,
});
