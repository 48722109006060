import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  allChecklist,
  caseChecklistDocument,
  createChecklist,
  deleteChecklist,
  isActiveChecklist,
  updateChecklist,
} from "../../../api/checklist_api";
import { caseDetailsFindCaseID } from "../../../api/case_api";
import { createSplitChecklistDocumentAction } from "../checklistDocuments/checklistDocumentsAction";
import { animateCss } from "../../../pages/TosterAlert";

export const allChecklistAction = createAsyncThunk(
  "checklist/allChecklistAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const checklist = await allChecklist();
      return checklist;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const createChecklistAction = createAsyncThunk(
  "checklist/createChecklistAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const checklist = await createChecklist(values);
      return checklist;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateChecklistAction = createAsyncThunk(
  "checklist/updateChecklistAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const checklist = await updateChecklist(values);
      return checklist;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const isActiveChecklistAction = createAsyncThunk(
  "checklist/isActiveChecklistAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const checklist = await isActiveChecklist(values);
      return checklist;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const deleteChecklistAction = createAsyncThunk(
  "checklist/deleteChecklistAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const checklist = await deleteChecklist(values);
      return checklist;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const caseChecklistDocumentAction = createAsyncThunk(
  "checklist/caseChecklistDocumentAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const checklist = await caseChecklistDocument(values);
      return checklist;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const caseDetailsFindCaseIDAction = createAsyncThunk(
  "checklist/caseDetailsFindCaseIDAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await caseDetailsFindCaseID(values.documentID);
      if (result.data.data?.[0].caseID) {
        try {
          createSplitChecklistDocumentAction(values);

          return result.data;
        } catch (err) {}
      } else {
        animateCss("warn", "PDF Split Document limit Exceeding");
      }
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
