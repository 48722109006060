import { createSlice } from "@reduxjs/toolkit";
import { animateCss } from "../../../pages/TosterAlert";
import {
  createUserDesignationAction,
  deleteUserDesignationAction,
  getUserDesignationAction,
  updateDesignationAction,
  updateDesignationIsActiveAction,
} from "../../actions/UserAction/userDesignationAction";
import { deleteUserAction } from "../../actions/UserAction/usersAction";

const userDesignationSliceIntial = {
  designation: [],
  listLoading: false,
  createUserDesignation: {
    loading: false,
    error: "",
  },
};

const usersSlice = createSlice({
  name: "designation",
  initialState: userDesignationSliceIntial,
  reducers: {
    resetCreateUserDesignation: (state, { payload }) => {
      state = { ...state, ...userDesignationSliceIntial.createUserDesignation };
      return state;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getUserDesignationAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserDesignationAction.fulfilled, (state, action) => {
        const value = [];
        state.loading = false;
        action?.payload?.data?.forEach(({ designationName }) => {
          value.push(designationName.toLowerCase().trim());
        });
        state.designation = value;
        return state;
      })
      .addCase(getUserDesignationAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createUserDesignationAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createUserDesignationAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.data) {
          animateCss("success", "User created successfully");
        }

        return state;
      })
      .addCase(createUserDesignationAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateDesignationAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateDesignationAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.data) {
          animateCss("success", "User Updated successfully");
        }

        return state;
      })
      .addCase(updateDesignationAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateDesignationIsActiveAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateDesignationIsActiveAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.IsActive) {
          animateCss("success", "User Designation Active successfully");
        } else if (!action.payload?.IsActive) {
          animateCss("warn", "User Designation Inactive  successfully");
        }

        return state;
      })
      .addCase(updateDesignationIsActiveAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteUserDesignationAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteUserDesignationAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.data) {
          animateCss("success", "Designation Deleted successfully");
        } else if (!action.payload?.data) {
          animateCss(
            "error",
            "Designation Used to Users Profile So Not Deleted ",
            3000
          );
        }

        return state;
      })
      .addCase(deleteUserDesignationAction.rejected, (state, action) => {
        state.loading = false;
      }),
});

export const { resetCreateUser } = usersSlice.actions;
export default usersSlice.reducer;
