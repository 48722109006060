// assets
import {
  ChromeOutlined,
  QuestionOutlined,
  ContainerOutlined,
  DownOutlined,
  UserOutlined,
  UserSwitchOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { IoSettings } from "react-icons/io5";

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const Reports = {
  id: "Settings",
  title: "Admin",
  type: "group",
  children: [
    {
      id: "Settings",
      title: "Settings",
      type: "collapse",
      url: "/shadow",
      icon: IoSettings,
      children: [
        // {
        //   id: "Settings",
        //   title: "Users",
        //   type: "item",
        //   url: "/settings/users",
        //   icon: UserAddOutlined,
        // },
        {
          id: "Settings",
          title: "User Roles",
          type: "item",
          url: "/settings/userroles",
          icon: UserSwitchOutlined,
        },
        {
          id: "Settings",
          title: "User Designation",
          type: "item",
          url: "/settings/userdesignation",
          icon: UserSwitchOutlined,
        },
        {
          id: "Settings",
          title: "User Organization",
          type: "item",
          url: "/settings/userorganization",
          icon: UserSwitchOutlined,
        },
        {
          id: "Settings",
          title: "User Role Mapping",
          type: "item",
          url: "/settings/userrolemapping",
          icon: UserSwitchOutlined,
        },
        {
          id: "Settings",
          title: "Case Checklist",
          type: "item",
          url: "/settings/caseChecklist",
          icon: UserSwitchOutlined,
        },
        {
          id: "Settings",
          title: "Case Template",
          type: "item",
          url: "/settings/caseTemplate",
          icon: UserSwitchOutlined,
        },
        {
          id: "Settings",
          title: "PDF Document",
          type: "item",
          url: "/settings/pdfdocument",
          icon: UserSwitchOutlined,
        },

        // {
        //   id: "Settings",
        //   title: "Reset Password",
        //   type: "item",
        //   url: "/resetPassword",
        // },
        // {
        //   id: "Settings",
        //   title: "Conciliator Notice",
        //   type: "item",
        //   url: "/settings/conciliatornotice",
        //   icon: UserSwitchOutlined,
        // },
      ],
    },
    // {
    //   id: "documentation",
    //   title: "Documentation",
    //   type: "item",
    //   url: "https://codedthemes.gitbook.io/mantis/",
    //   icon: icons.QuestionOutlined,
    //   external: true,
    //   target: true,
    // },
  ],
};

export default Reports;
