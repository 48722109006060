// project import
import dashboard from "./dashboard";
import pages from "./page";
import utilities from "./utilities";
import support from "./support";
import Settings from "./settings";
import Repository from "./repository";
import Profile from "./profile";
import CaseInfo from "./caseInfo";
import home from "./home";
import application from "./application";
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // items: [dashboard, pages, utilities, support]
  // items: [dashboard, utilities, support, Settings, Organization],
  // items: [
  //   home,
  //   dashboard,
  //   application,
  //   Repository,
  //   CaseInfo,
  //   Settings,
  //   Profile,
  // ],
  items: [home, application, Repository, Settings, Profile],
};

export default menuItems;
