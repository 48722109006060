/* eslint-disable no-empty-pattern */
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cretaeUserRole,
  deleteUsersRole,
  getUserRole,
  updateUserRole,
  updateUserRoleIsActive,
} from "../../../api/userRole_api";

export const getUserRoleAction = createAsyncThunk(
  "usersRole/getUserRoleAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await getUserRole();
      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const createUserRoleAction = createAsyncThunk(
  "users/createUserRoleAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await cretaeUserRole(values);
      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateUserRoleAction = createAsyncThunk(
  "usersRole/updateUserRoleAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await updateUserRole(values);

      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateUserRoleIsActiveAction = createAsyncThunk(
  "usersRole/updateUserRoleIsActiveAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await updateUserRoleIsActive(values);
      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const deleteUserRoleAction = createAsyncThunk(
  "usersRole/deleteUserRole",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await deleteUsersRole(values);
      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
