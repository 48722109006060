import axios from "axios";
import { api } from "./api_route";

export const caseMapping = async (values) => {
  try {
    const result = await axios.post(`${api}/casemapping/upload`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const allCaseMappingDetails = async (values) => {
  try {
    const result = await axios.post(`${api}/caseMapping/allDetails`, values);

    return result.data;
  } catch (error) {
    return [];
  }
};

export const selectedCaseMappingDetails = async (values) => {
  try {
    const result = await axios.post(`${api}/caseMapping/selectDetails`, values);

    return result.data;
  } catch (error) {
    return [];
  }
};

export const caseMappingDetailUpdate = async (values) => {
  try {
    const result = await axios.post(`${api}/caseMapping/update`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const caseMappingDetailisActive = async (values) => {
  try {
    const result = await axios.post(`${api}/caseMapping/isActive`, values);
    return result;
  } catch (error) {
    return [];
  }
};

export const organizationActiveCaseTemplate = async (values) => {
  try {
    const result = await axios.post(`${api}/casemapping/caseTemplate`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const allCaseDetails = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/`, values);
    return result;
  } catch (error) {
    return [];
  }
};

export const singleCaseDetails = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/singleCase`, {
      values,
    });

    return JSON.parse(result?.data?.data?.[0]?.result);
  } catch (error) {
    return [];
  }
};

export const allCaseType = async (values) => {
  try {
    const result = await axios.get(`${api}/caseDetails/caseTypes`);

    return result.data;
  } catch (error) {
    return [];
  }
};

export const organizationCaseDetails = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/organzationCase`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateCaseDetails = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/update`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const caseDetailHistory = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/caseHistory`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const caseDetailsFindCaseID = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/findCaseID`, values);
    return result;
  } catch (error) {
    return [];
  }
};

export const caseTrackingUpdate = async (values) => {
  try {
    const result = await axios.post(`${api}/caseDetails/casetracker`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const updateUserCaseFilter = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateUserCaseFilter`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateCasePrimaryInformation = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateCasePrimaryInformation`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateInitiationOfArbitration = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateInitiationOfArbitration`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateAppointmentOfArbitratorSec11and12 = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateAppointmentOfArbitratorSec11and12`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationInterimOrdersReliefsAwards = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationInterimOrdersReliefsAwards`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationWitnessEvidenceAndExpertReport = async (
  values
) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationWitnessEvidenceAndExpertReport`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationHoldWithdrawalAndSettlement = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationHoldWithdrawalAndSettlement`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationEnforcementAndAppeals = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationEnforcementAndAppeals`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationFeePaymentStatus = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationFeePaymentStatus`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationNoticesKeyDates = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationNoticesKeyDates`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationDurationAndTimelineManagement = async (
  values
) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationDurationAndTimelineManagement`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const updateArbitrationClausAndAgreement = async (values) => {
  try {
    var files = new FormData();

    values?.uploadedFiles?.map((document, i) => {
      files.append("file", document);
    });

    files.append("caseDetails", JSON.stringify(values));
    const result = await axios.post(
      `${api}/caseDetails/updateArbitrationClausAndAgreement`,
      files
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const allAgreementDocumentList = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/allAgreementDocumentList`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const allAgreementDocumentDelete = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/allAgreementDocumentDelete`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const caseBulkFilter = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/caseBulkFilter`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};
