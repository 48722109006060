import { CKEditor } from "@ckeditor/ckeditor5-react";
import React from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const RTEditor = ({ value, onChange, ...props }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={value}
      onChange={(event, editor) => {
        onChange?.(editor.getData());
      }}
    />
  );
};
export default RTEditor;
