import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  allOrganization,
  createOrganization,
  deleteOrganization,
  isActiveOrganization,
  updateOrganization,
} from "../../../api/organization_api";

export const allOrganizationAction = createAsyncThunk(
  "organization/allOrganizationAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const organization = await allOrganization(values);
      return organization;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const createOrganizationAction = createAsyncThunk(
  "organization/createOrganizationAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const organization = await createOrganization(values);
      return organization;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateOrganizationAction = createAsyncThunk(
  "organization/updateOrganizationAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const organization = await updateOrganization(values);
      return organization;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const isActiveOrganizationAction = createAsyncThunk(
  "organization/isActiveOrganizationAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const organization = await isActiveOrganization(values);
      return organization;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const deleteOrganizationAction = createAsyncThunk(
  "organization/deleteOrganizationAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const organization = await deleteOrganization(values);
      return organization;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
