import { createSlice } from "@reduxjs/toolkit";
import {
  forgotPasswordAction,
  forgotPasswordOTPValidationAction,
  loginUserAction,
} from "../actions/loginAction";
import { animateCss } from "../../pages/TosterAlert";

const initialState = {
  loading: false,
  message: null,
  data: {},
};
const loginSlice = createSlice({
  name: "login",
  initialState: {
    loading: false,
    message: false,
    data: {},
  },
  reducers: {
    setLogin: (state, { payload }) => {
      state = payload?.data;
      return state;
    },
    logOut: (state, action) => {
      state = initialState;
      return state;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(loginUserAction.pending, (state, action) => {
        state = { ...state, loading: true };
        state.loading = true;
      })
      .addCase(loginUserAction.fulfilled, (state, action) => {
        if (action.payload?.data) {
          state.loading = false;
          state.data = action.payload?.data;

          return state;
        }
      })
      .addCase(loginUserAction.rejected, (state, { payload }) => {
        state.loading = true;
        state.message = false;
        state.error = payload;
      })
      .addCase(forgotPasswordAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(forgotPasswordAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.data?.accepted) {
          state.loading = false;
          state.message = true;
          return state;
        } else if (!action?.payload?.data?.data?.accepted) {
          state.loading = false;
          state.message = false;
          animateCss("error", "Invalid Email Id");
          return state;
        }
      })
      .addCase(forgotPasswordAction.rejected, (state, { payload }) => {
        state.loading = false;
        state.message = false;
        state.error = payload;
        return state;
      })
      .addCase(forgotPasswordOTPValidationAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(forgotPasswordOTPValidationAction.fulfilled, (state, action) => {
        state.loading = false;
        state.message = true;
        if (!action?.payload?.valid) {
          animateCss("error", action.payload.message, 3000);
        } else if (action?.payload?.valid) {
          animateCss("success", action.payload.message, 3000);
        }
        return state;
      })
      .addCase(
        forgotPasswordOTPValidationAction.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.message = false;
          state.error = payload;
          return state;
        }
      ),
});

export const { setLogin, logOut } = loginSlice.actions;
export default loginSlice.reducer;
