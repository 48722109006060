import { lazy } from "react";

import Loadable from "../components/Loadable";
import MinimalLayout from "../layout/MinimalLayout";
import Test from "../pages/Testing";
import Split from "../pages/pdf/pages/pdf-tools/split";
import Removemetadata from "../pages/pdf/pages/pdf-tools/removemetadata";
import FlattenForm from "../pages/pdf/pages/pdf-tools/flattenform";
import Resize from "../pages/pdf/pages/pdf-tools/resize";
import Editmetadata from "../pages/pdf/pages/pdf-tools/editmetadata";
import Addmargin from "../pages/pdf/pages/pdf-tools/addmargin";
import Addpagenumbers from "../pages/pdf/pages/pdf-tools/addpagenumbers";
import BreakPDF from "../pages/pdf/pages/pdf-tools/break";
import FlattenPDFFormHandler from "../pages/pdf/methods/flattenPDFFormHandler";
import Jpgtopdf from "../pages/pdf/pages/pdf-tools/jpgtopdf";
import NotFound from "../pages/NotFoundPage";

const AuthLogin = Loadable(lazy(() => import("../pages/authentication/login")));
const AuthRegister = Loadable(
  lazy(() => import("../pages/authentication/register"))
);
const AuthChangePassword = Loadable(
  lazy(() => import("../pages/authentication/changePassword"))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: <MinimalLayout />,
  errorElement: <NotFound />,
  children: [
    {
      path: "/",
      element: <AuthLogin />,
    },
    {
      path: "/login",
      element: <AuthLogin />,
    },
    {
      path: "/register",
      element: <AuthRegister />,
    },
    {
      path: "/changepassword",
      element: <AuthChangePassword />,
    },
    {
      path: "/test",
      element: <Test />,
    },
    {
      path: "/pdf-tools/split",
      element: <Split />,
    },
    {
      path: "/pdf-tools/resize",
      element: <Resize />,
    },
    {
      path: "/pdf-tools/editmetadata",
      element: <Editmetadata />,
    },
    {
      path: "/pdf-tools/addmargin",
      element: <Addmargin />,
    },
    {
      path: "/pdf-tools/addpagenumber",
      element: <Addpagenumbers />,
    },
    {
      path: "/pdf-tools/breakpdf",
      element: <BreakPDF />,
    },
    {
      path: "/pdf-tools/jpegtopdf",
      element: <Jpgtopdf />,
    },
    {
      path: "/pdf-tools/removemetadata",
      element: <Removemetadata />,
    },
    {
      path: "/pdf-tools/resize",
      element: <Resize />,
    },

    // {
    //   path: "/404",
    //   element: <NotFound />,
    // },
  ],
};

export default LoginRoutes;
