import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  IconButton,
  Slide,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { caseMappingDetailUpdateAction } from "../../../redux/actions/Case/caseMappingAction";
import {
  resetMappingDetailsReducer,
  resetSingleCaseDetailsReducer,
} from "../../../redux/slices/Case/caseSlice";
import { GridDeleteIcon } from "@mui/x-data-grid";
import { allCaseGroupingAction } from "../../../redux/actions/TemplateGrouping/TemplateGroupingAction";
import { updateCaseDetailsAction } from "../../../redux/actions/Case/caseAction";

const fakeData = [];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Example = ({
  setOpenEdit,
  openEdit,
  rawData,
  caseID,
  setNewData,
  setEditCaseTracker,
  newData,
}) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [editedUsers, setEditedUsers] = useState({});
  const [data, setData] = useState([]);

  function formatDate(isoDate) {
    const date = new Date(isoDate);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }

  const editableData = async (update) => {
    try {
      const updatedData = update?.map((item) => {
        return item;
      });

      const convertedValue = {};
      await Promise.all(
        rawData?.map(async (item, index) => {
          const filterValues = updatedData?.find(
            (newData) => newData.Header === item.Header
          );

          const newItem = {
            alterHeader: item.Header,

            // filterValues
            //   ? item.Header.toLowerCase().includes("date")
            //     ? formatDate(new Date(filterValues.value))
            //     : typeof filterValues.value == "string"
            //       ? filterValues.value
            //       : Number(filterValues.value)
            //   :

            fieldNotNeed: filterValues
              ? filterValues.fieldNotNeed
              : item.fieldNotNeed,
            reportsAndTemplates: filterValues
              ? filterValues.reportsAndTemplates
              : item.reportsAndTemplates,
            value: item.value,
            group: {
              id: index + 1,
              group: item.group,
              header: item.Header,
              alterHeader: item.Header,
              fieldNotNeeded: filterValues
                ? filterValues.fieldNotNeed
                : item.fieldNotNeed,
              reportsAndTemplates: filterValues
                ? filterValues.reportsAndTemplates
                : item.reportsAndTemplates,
              // value: item.value,
            },
          };

          if (filterValues && filterValues.group) {
            newItem.value = await filterValues.value;
            newItem.group = {
              id: index + 1,
              header: await filterValues.Header,
              group:
                (await filterValues.group) === "Other Case Details"
                  ? ""
                  : filterValues.group,
              alterHeader: await filterValues.Header,
              fieldNotNeeded: filterValues.fieldNotNeed || item.fieldNotNeed,
              reportsAndTemplates: await filterValues.reportsAndTemplates,
            };
          }

          convertedValue[item.Header] = newItem;
        })
      );

      const finalData = JSON.stringify(convertedValue);
      const value = { finalData, caseID };

      await dispatch(updateCaseDetailsAction(value));

      await setOpenEdit(false);
      await setNewData(false);
      // await setEditCaseTracker(false);
    } catch (err) {}
  };

  let {
    data: fetchedUsers = [],
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
  } = useGetUsers(openEdit);

  const caseMappingID = useSelector(
    (state) => state.case?.caseMappingDetails?.mappingDetail
  );

  const dataSet = () => {
    let changeingValue = openEdit;

    const findValue = [editedUsers]?.filter(
      (item) => item.Header == "TERRITORY NAME"
    );
  };

  const usStates = useSelector((state) => state.templateGroupting.list);

  useEffect(() => {
    setData(openEdit);
    dispatch(allCaseGroupingAction());
  }, []);

  useEffect(() => {
    const values = openEdit.map((singleValue) => {
      const editedUser = editedUsers[singleValue.Header];
      return editedUser ? editedUser : singleValue;
    });
    setOpenEdit(values);
  }, [editedUsers]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "Header",
        header: "Header",
        enableEditing: false,
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: "text",
          required: true,
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],
          onBlur: (event) => {
            const validationError = !validateRequired(event.currentTarget.value)
              ? "Required"
              : undefined;
            setValidationErrors({
              ...validationErrors,
              [cell.id]: validationError,
            });
            const value = row.original;

            setEditedUsers((prevUsers) => ({
              ...prevUsers,
              [cell.row.id]: {
                ...row.original,
                alterHeader: row._valuesCache.alterHeader,
              },
            }));
            updateUsers(Object.values(editedUsers));
          },
        }),
      },
      {
        accessorKey: "group",
        header: "group",
        editVariant: "select",
        editSelectOptions: [
          "",
          ...usStates?.filter(
            (option) => option.toLowerCase().indexOf("group") === -1
          ),
        ],
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          error: !!validationErrors?.group,
          helperText: validationErrors?.group,
          onChange: (event) =>
            setEditedUsers({
              ...editedUsers,
              [row.id]: { ...row.original, group: event.target.value },
            }),
        }),
      },
      {
        accessorKey: "value",
        header: "Value",
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: "text",
          required: true,
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],
          onBlur: (event) => {
            const validationError = !validateRequired(event.currentTarget.value)
              ? "Required"
              : undefined;
            setValidationErrors({
              ...validationErrors,
              [cell.id]: validationError,
            });

            const value = row.original;

            setEditedUsers((prevUsers) => ({
              ...prevUsers,
              [cell.row.id]: {
                ...row.original,
                value: row._valuesCache.value,
                id: cell.row.id,
              },
            }));
          },
        }),
      },
      {
        accessorKey: "fieldNotNeed",
        header: "Field Not Needed",
        enableEditing: false,
        size: 130,
        Cell: ({ cell, row }) => {
          return (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip title="Field Not Needed">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={row.original.fieldNotNeed === true}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        const newValue = isChecked ? true : false;
                        setEditedUsers((prevUsers) => ({
                          ...prevUsers,
                          [cell.row.id]: {
                            ...row.original,
                            fieldNotNeed: newValue,
                            id: row.id,
                          },
                        }));
                      }}
                    />
                  }
                />
              </Tooltip>
            </Box>
          );
        },
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: "checkbox",
          checked: row.original.fieldNotNeeded === true,
          onBlur: (event) => {
            const isChecked = event.target.checked;
            const newValue = isChecked ? true : false;

            setEditedUsers((prevUsers) => ({
              ...prevUsers,
              [cell.row.id]: {
                ...row.original,
                fieldNotNeeded: newValue,
                id: cell.row.id,
              },
            }));

            updateUsers(Object.values(editedUsers));
          },
        }),
      },
      {
        accessorKey: "reportsAndTemplates",
        header: "Report And Templates",
        enableEditing: false,
        Cell: ({ cell, row }) => {
          return (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip title="Report And Templates">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={row.original.reportsAndTemplates === true}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        const newValue = isChecked ? true : false;

                        setEditedUsers((prevUsers) => ({
                          ...prevUsers,
                          [cell.row.id]: {
                            ...row.original,
                            reportsAndTemplates: newValue,
                            id: cell.row.id,
                          },
                        }));

                        updateUsers(Object.values(editedUsers));
                      }}
                    />
                  }
                />
              </Tooltip>
            </Box>
          );
        },
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: "checkbox",
          checked: row.original.reportsAndTemplates === true,
          onBlur: (event) => {
            const isChecked = event.target.checked;
            const newValue = isChecked ? true : false;

            setEditedUsers((prevUsers) => ({
              ...prevUsers,
              [cell.row.id]: {
                ...row.original,
                reportsAndTemplates: newValue,
                id: cell.row.id,
              },
            }));

            updateUsers(Object.values(editedUsers));
          },
        }),
      },
    ],
    [editedUsers]
  );
  const dispatch = useDispatch();
  const caseDeatilsData = useSelector(
    (state) => state.case?.caseMappingDetails?.mappingTemplate
  );

  useEffect(() => {
    const template = caseDeatilsData?.[0];

    if (template) {
      fakeData.push(...template);
    }
  }, []);

  const maxId = Math?.max(...fetchedUsers?.map((item) => item?.id));

  const { mutateAsync: createUser, isPending: isCreatingUser } =
    useCreateUser(maxId);
  const { mutateAsync: updateUsers, isPending: isUpdatingUsers } =
    useUpdateUsers(editedUsers, openEdit);
  const { mutateAsync: deleteUser, isPending: isDeletingUser } =
    useDeleteUser();

  const handleSaveUsers = async (table) => {
    const newValue = table
      .map((values, index) => {
        if (values._valuesCache?.header) {
          return { ...values._valuesCache, id: index };
        } else if (!values._valuesCache?.id && values.original.header) {
          return { ...values.original, id: index };
        } else {
          return null;
        }
      })
      ?.filter((value) => value !== null);

    const template = [newValue];

    const values = { data: template, caseMapping: caseMappingID };
    dispatch(caseMappingDetailUpdateAction(values));

    if (Object.values(validationErrors).some((error) => !!error)) return;
    await updateUsers(Object.values(editedUsers));
    setEditedUsers({});
    dispatch(resetMappingDetailsReducer());
  };

  const table = useMaterialReactTable(
    {
      columns,
      data: openEdit,
      enableRowNumbers: true,
      rowNumberDisplayMode: "original",
      enableFullScreenToggle: false,
      enableRowSelection: false,
      columnFilterDisplayMode: "popover",
      enableRowVirtualization: true,
      paginationDisplayMode: "pages",
      positionToolbarAlertBanner: "bottom",
      enableStickyHeader: true,
      enableGlobalFilter: true,
      editDisplayMode: "cell" || "table",
      enablePagination: false,
      initialState: {
        columnVisibility: { id: false },
        density: "compact",
      },
      keyAccessor: (row, rowIndex) =>
        row.orderStatus + "_" + row.id + "_" + rowIndex,

      createDisplayMode: "table",
      enableEditing: true,

      positionActionsColumn: "last",
      getRowId: (row) => row?.id,
      muiToolbarAlertBannerProps: isLoadingUsersError
        ? {
            color: "error",
            children: "Error loading data",
          }
        : undefined,
      muiTableContainerProps: {
        sx: {
          minHeight: "500px",
        },
      },
      onCreatingRowCancel: () => setValidationErrors({}),

      renderTopToolbarCustomActions: ({ table }) => (
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              // editableData(table.getPrePaginationRowModel()?.rows);

              editableData(table.options.data);
            }}
            disabled={
              Object.keys(editedUsers).length === 0 ||
              Object.values(validationErrors).some((error) => !!error)
            }
          >
            {isUpdatingUsers ? <CircularProgress size={25} /> : "Update"}
          </Button>
          {Object.values(validationErrors).some((error) => !!error) && (
            <Typography color="error">Fix errors before submitting</Typography>
          )}

          <Button
            variant="contained"
            onClick={() => {
              setOpenEdit(false);
            }}
          >
            Back
          </Button>
        </Box>
      ),
      state: {
        isLoading: isLoadingUsers,
        isSaving: isCreatingUser || isUpdatingUsers || isDeletingUser,
        showAlertBanner: isLoadingUsersError,
        showProgressBars: isFetchingUsers,
      },
    },
    [caseDeatilsData]
  );

  return (
    <>
      <Dialog
        open={openEdit}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            width: "90%",
            maxWidth: "90%",
          },
        }}
      >
        <MaterialReactTable table={table} />
      </Dialog>
    </>
  );
};

function useCreateUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (user) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const newUser = {
        ...user,
        id: (Math.random() + 1).toString(36).substring(7),
      };

      queryClient.setQueryData(["users"], (prevUsers) => {
        if (Array.isArray(prevUsers)) {
          return [...prevUsers, newUser];
        } else {
          return [newUser];
        }
      });
      return newUser;
    },

    onMutate: async (newUserInfo) => {
      await queryClient.cancelQueries(["users"]);

      const previousUsers = queryClient.getQueryData(["users"]);

      queryClient.setQueryData(["users"], (prevUsers) => {
        if (Array.isArray(prevUsers)) {
          return [...prevUsers, { ...newUserInfo, id: "optimistic-id" }];
        } else {
          return [{ ...newUserInfo, id: "optimistic-id" }];
        }
      });

      return () => queryClient.setQueryData(["users"], previousUsers);
    },

    onSettled: () => {
      queryClient.invalidateQueries(["users"]);
    },
  });
}

function useGetUsers(caseDeatilsData) {
  let fakeData = [];

  fakeData.push(caseDeatilsData[0]);

  return useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return Promise.resolve(fakeData);
    },
    refetchOnWindowFocus: false,
  });
}

function useUpdateUsers(editedUsers, openEdit) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (users) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return Promise.resolve();
    },
    onMutate: (newUsers) => {
      function findObjectByHeader(obj, headerValue) {
        for (let key in obj) {
          if (obj[key].header === headerValue) {
            return obj[key];
          }
        }
        return null;
      }
      queryClient.setQueryData(["users"], (prevUsers) =>
        openEdit?.map((user) => {
          const newUser = newUsers.find((u) => u.id === user.id);
          let foundObject = findObjectByHeader(editedUsers, user.Header);

          return newUser ? newUser : user;
        })
      );
    },
  });
}

function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userId) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return Promise.resolve();
    },
    onMutate: (userId) => {
      queryClient.setQueryData(["users"], (prevUsers) =>
        prevUsers?.filter((user) => user.id !== userId)
      );
    },
  });
}

const queryClient = new QueryClient();

const CaseDetailEdit = ({ setOpenEdit, openEdit, rawData, caseID }) => {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Example
          setOpenEdit={setOpenEdit}
          openEdit={openEdit}
          rawData={rawData}
          caseID={caseID}
        />
      </QueryClientProvider>
    </div>
  );
};

export default CaseDetailEdit;

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

function validateUser(user) {
  return {
    header: !validateRequired(user.header) ? "Header Name is Required" : "",
    alterHeader: !validateRequired(user.alterHeader)
      ? "Alter Header Name is Required"
      : "",
  };
}
