import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  allCaseMappingDetailAction,
  caseMappingDetailUpdateAction,
} from "../../../redux/actions/Case/caseMappingAction";
import { resetMappingDetailsReducer } from "../../../redux/slices/Case/caseSlice";

const fakeData = [];

export const usStates = ["No", "Yes"];

const Example = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [editedUsers, setEditedUsers] = useState({});
  const usStates = useSelector((state) => state.templateGroupting.list);

  const caseMappingID = useSelector(
    (state) => state.case?.caseMappingDetails?.mappingDetail
  );
  useEffect(() => {
    updateUsers(Object?.values(editedUsers));
    setTimeout(() => {
      dispatch(allCaseMappingDetailAction());
    }, 1000);
  }, [editedUsers]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "header",
        header: "Header",
        enableEditing: false,
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: "text",
          required: true,
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],
          onBlur: (event) => {
            const validationError = !validateRequired(event.currentTarget.value)
              ? "Required"
              : undefined;
            setValidationErrors({
              ...validationErrors,
              [cell.id]: validationError,
            });
            const value = row.original;

            setEditedUsers((prevUsers) => ({
              ...prevUsers,
              [cell.id]: {
                ...value,
                alterHeader: row._valuesCache.alterHeader,
              },
            }));
            updateUsers(Object.values(editedUsers));
          },
        }),
      },
      {
        accessorKey: "group",
        header: "group",
        editVariant: "select",
        editSelectOptions: [
          "",
          ...usStates.filter(
            (option) => option.toLowerCase().indexOf("group") === -1
          ),
        ],
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          error: !!validationErrors?.group,
          helperText: validationErrors?.group,
          onChange: (event) =>
            setEditedUsers({
              ...editedUsers,
              [row.id]: { ...row.original, group: event.target.value },
            }),
        }),
      },
      {
        accessorKey: "alterHeader",
        header: "Alter Header",
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: "text",
          required: true,
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],
          onBlur: (event) => {
            const validationError = !validateRequired(event.currentTarget.value)
              ? "Required"
              : undefined;
            setValidationErrors({
              ...validationErrors,
              [cell.id]: validationError,
            });

            const value = row.original;

            setEditedUsers((prevUsers) => ({
              ...prevUsers,
              [cell.id]: {
                ...value,
                alterHeader: row._valuesCache.alterHeader,
                id: cell.row.id,
              },
            }));
          },
        }),
      },
      {
        accessorKey: "fieldNotNeeded",
        header: "Field Not Needed",
        enableEditing: false,
        size: 130,
        Cell: ({ cell, row }) => {
          return (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip title="Field Not Needed">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={row.original.fieldNotNeeded === true}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        const newValue = isChecked ? true : false;

                        setEditedUsers((prevUsers) => ({
                          ...prevUsers,
                          [cell.id]: {
                            ...row.original,
                            fieldNotNeeded: newValue,
                            id: row.id,
                          },
                        }));
                      }}
                    />
                  }
                />
              </Tooltip>
            </Box>
          );
        },
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: "checkbox",
          checked: row.original.fieldNotNeeded === true,
          onBlur: (event) => {
            const isChecked = event.target.checked;
            const newValue = isChecked ? true : false;

            setEditedUsers((prevUsers) => ({
              ...prevUsers,
              [cell.row.id]: {
                ...row.original,
                fieldNotNeeded: newValue,
                id: cell.row.id,
              },
            }));

            updateUsers(Object.values(editedUsers));
          },
        }),
      },
      {
        accessorKey: "reportsAndTemplates",
        header: "Report And Templates",
        enableEditing: false,
        Cell: ({ cell, row }) => {
          return (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip title="Report And Templates">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={row.original.reportsAndTemplates === true}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        const newValue = isChecked ? true : false;

                        setEditedUsers((prevUsers) => ({
                          ...prevUsers,
                          [cell.row.id]: {
                            ...row.original,
                            reportsAndTemplates: newValue,
                            id: cell.row.id,
                          },
                        }));

                        updateUsers(Object.values(editedUsers));
                      }}
                    />
                  }
                />
              </Tooltip>
            </Box>
          );
        },
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: "checkbox",
          checked: row.original.reportsAndTemplates === true,
          onBlur: (event) => {
            const isChecked = event.target.checked;
            const newValue = isChecked ? true : false;

            setEditedUsers((prevUsers) => ({
              ...prevUsers,
              [cell.row.id]: {
                ...row.original,
                reportsAndTemplates: newValue,
                id: cell.row.id,
              },
            }));

            updateUsers(Object.values(editedUsers));
          },
        }),
      },
    ],
    [editedUsers]
  );
  const dispatch = useDispatch();
  const caseDeatilsData = useSelector(
    (state) => state.case?.caseMappingDetails?.mappingTemplate
  );

  useEffect(() => {
    const template = caseDeatilsData?.[0];

    if (template) {
      fakeData.push(...template);
    }
  }, []);

  const {
    data: fetchedUsers = [],
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
  } = useGetUsers(caseDeatilsData);
  const maxId = Math?.max(...fetchedUsers?.map((item) => item?.id));

  const { mutateAsync: createUser, isPending: isCreatingUser } =
    useCreateUser(maxId);
  const { mutateAsync: updateUsers, isPending: isUpdatingUsers } =
    useUpdateUsers(editedUsers);
  const { mutateAsync: deleteUser, isPending: isDeletingUser } =
    useDeleteUser();

  const handleCreateUser = async ({ values, table }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createUser(values);
    table.setCreatingRow(null);
  };

  const handleSaveUsers = async (table) => {
    //Bug filxed This code only for Filter data Update purpose

    // const newValue = table
    //   .map((values, index) => {
    //     if (values._valuesCache?.header) {
    //       return { ...values._valuesCache, id: index };
    //     } else if (!values._valuesCache?.id && values.original.header) {
    //       return { ...values.original, id: index };
    //     } else {
    //       return null;
    //     }
    //   })
    //   .filter((value) => value !== null);

    // const template = [newValue];

    const template = [table];

    const values = { data: template, caseMapping: caseMappingID };

    dispatch(caseMappingDetailUpdateAction(values));

    if (Object.values(validationErrors).some((error) => !!error)) return;
    await updateUsers(Object.values(editedUsers));
    setEditedUsers({});
    dispatch(resetMappingDetailsReducer());
  };

  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      deleteUser(row.original.id);
    }
  };

  const table = useMaterialReactTable(
    {
      columns,
      data: fetchedUsers ?? caseDeatilsData[0],
      enableRowNumbers: true,
      rowNumberDisplayMode: "original",
      enableFullScreenToggle: false,
      enableRowSelection: false,
      columnFilterDisplayMode: "popover",
      enableRowVirtualization: true,
      paginationDisplayMode: "pages",
      positionToolbarAlertBanner: "bottom",
      enableStickyHeader: true,
      enableGlobalFilter: true,
      editDisplayMode: "cell" || "table",
      enablePagination: false,
      initialState: {
        columnVisibility: { id: false },
        density: "compact",
      },
      keyAccessor: (row, rowIndex) =>
        row.orderStatus + "_" + row.id + "_" + rowIndex,

      createDisplayMode: "table",
      enableEditing: true,

      positionActionsColumn: "last",
      getRowId: (row) => row?.id,
      muiToolbarAlertBannerProps: isLoadingUsersError
        ? {
            color: "error",
            children: "Error loading data",
          }
        : undefined,
      muiTableContainerProps: {
        sx: {
          minHeight: "500px",
        },
      },
      onCreatingRowCancel: () => setValidationErrors({}),

      renderTopToolbarCustomActions: ({ table }) => (
        <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              //Bug filxed This code only for Filter data Update purpose
              // handleSaveUsers(table.getPrePaginationRowModel().rows);

              //Bug filxed Table All data update
              handleSaveUsers(table.options.data);
            }}
            disabled={
              Object.keys(editedUsers).length === 0 ||
              Object.values(validationErrors).some((error) => !!error)
            }
          >
            {isUpdatingUsers ? <CircularProgress size={25} /> : "Update"}
          </Button>
          {Object.values(validationErrors).some((error) => !!error) && (
            <Typography color="error">Fix errors before submitting</Typography>
          )}

          <Button
            variant="contained"
            onClick={() => {
              dispatch(resetMappingDetailsReducer());
            }}
          >
            Back
          </Button>
        </Box>
      ),
      state: {
        isLoading: isLoadingUsers,
        isSaving: isCreatingUser || isUpdatingUsers || isDeletingUser,
        showAlertBanner: isLoadingUsersError,
        showProgressBars: isFetchingUsers,
      },
    },
    [caseDeatilsData]
  );

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

function useCreateUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (user) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const newUser = {
        ...user,
        id: (Math.random() + 1).toString(36).substring(7),
      };

      queryClient.setQueryData(["users"], (prevUsers) => {
        if (Array.isArray(prevUsers)) {
          return [...prevUsers, newUser];
        } else {
          return [newUser];
        }
      });
      return newUser;
    },

    onMutate: async (newUserInfo) => {
      await queryClient.cancelQueries(["users"]);

      const previousUsers = queryClient.getQueryData(["users"]);

      queryClient.setQueryData(["users"], (prevUsers) => {
        if (Array.isArray(prevUsers)) {
          return [...prevUsers, { ...newUserInfo, id: "optimistic-id" }];
        } else {
          return [{ ...newUserInfo, id: "optimistic-id" }];
        }
      });

      return () => queryClient.setQueryData(["users"], previousUsers);
    },

    onSettled: () => {
      queryClient.invalidateQueries(["users"]);
    },
  });
}

function useGetUsers(caseDeatilsData) {
  let fakeData = [];

  fakeData.push(caseDeatilsData[0]);

  return useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return Promise.resolve(fakeData[0]);
    },
    refetchOnWindowFocus: false,
  });
}

function useUpdateUsers(editedUsers) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (users) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return Promise.resolve();
    },
    onMutate: (newUsers) => {
      function findObjectByHeader(obj, headerValue) {
        for (let key in obj) {
          if (obj[key].header === headerValue) {
            return obj[key];
          }
        }
        return null;
      }
      queryClient.setQueryData(["users"], (prevUsers) =>
        prevUsers?.map((user) => {
          const newUser = newUsers.find((u) => u.id === user.id);
          let foundObject = findObjectByHeader(editedUsers, user.header);

          return newUser ? newUser : user;
        })
      );
    },
  });
}

function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userId) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return Promise.resolve();
    },
    onMutate: (userId) => {
      queryClient.setQueryData(["users"], (prevUsers) =>
        prevUsers?.filter((user) => user.id !== userId)
      );
    },
  });
}

const queryClient = new QueryClient();

const EditCaseTemplate = () => {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Example />
      </QueryClientProvider>
    </div>
  );
};

export default EditCaseTemplate;

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

function validateUser(user) {
  return {
    header: !validateRequired(user.header) ? "Header Name is Required" : "",
    alterHeader: !validateRequired(user.alterHeader)
      ? "Alter Header Name is Required"
      : "",
  };
}
