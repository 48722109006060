/* eslint-disable no-empty-pattern */
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cretaeUserDesignation,
  deleteUserDesignation,
  getUserDesignation,
  updateUserDesignation,
  updateUserDesignationIsActive,
} from "../../../api/userDesignation_api";

export const getUserDesignationAction = createAsyncThunk(
  "usersDesignation/getUserDesignation",
  async (values, { rejectWithValue, getState }) => {
    try {
      
      const dsignation = await getUserDesignation();
      return dsignation;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const createUserDesignationAction = createAsyncThunk(
  "usersDesignation/createUserDesignationAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await cretaeUserDesignation(values);
      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateDesignationAction = createAsyncThunk(
  "usersDesignation/updateDesignationAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await updateUserDesignation(values);

      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateDesignationIsActiveAction = createAsyncThunk(
  "usersDesignation/updateDesignationIsActiveAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await updateUserDesignationIsActive(values);
     
      return users?.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const deleteUserDesignationAction = createAsyncThunk(
  "usersDesignation/deleteUserDesignation",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await deleteUserDesignation(values);
      return users.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
