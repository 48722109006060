import PropTypes from "prop-types";
import { useState } from "react";

// material-ui
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// assets
import EditOutlined from "@ant-design/icons/EditOutlined";
import ProfileOutlined from "@ant-design/icons/ProfileOutlined";
import LogoutOutlined from "@ant-design/icons/LogoutOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import WalletOutlined from "@ant-design/icons/WalletOutlined";
import { Link } from "react-router-dom";
import { IconButton, ListItem } from "@mui/material";
import { logOut } from "../../../../../redux/slices/loginSlice";
import { useDispatch } from "react-redux";
import { resetRoleRoute } from "../../../../../redux/slices/UserRoute/UserRoleRouteSlice";
import { resetDataAndLogout } from "../../../../../helpers/auth";
import { Margin } from "@mui/icons-material";

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

export default function ProfileTab() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  const dispatch = useDispatch();

  return (
    <List
      component="nav"
      sx={{ p: 0, "& .MuiListItemIcon-root": { minWidth: 32 } }}
    >
      {/* <ListItemButton
        to={"/profile/changepassword"}
        underline="none"
        className="text-black"
        color="black"
        style={{
          Margin: 0,
          padding: 0,
          textDecoration: "none",
          textDecorationColor: "none",
          textDecorationStyle: "none",
          backgroundColor: "white",
        }}
      >
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={(event) =>
            handleListItemClick(event, 0, "/apps/profiles/user/personal")
          }
        >
          <ListItemIcon>
            <EditOutlined />
          </ListItemIcon>
          <ListItemText primary="Edit Profile" />
        </ListItemButton>
      </ListItemButton> */}

      <ListItem
        button
        component={Link}
        to="/profile/viewprofile"
        // selected={selectedIndex === 1}
        // onClick={(event) =>
        //   handleListItemClick(event, 1, "/apps/profiles/account/basic")
        // }
      >
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="View Profile" />
      </ListItem>

      <ListItem button component={Link} to="/profile/changepassword">
        <ListItemIcon>
          <ProfileOutlined />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </ListItem>

      {/* <ListItem component={Link} to="/profile/changepassword">
        <ListItemIcon>
          <ProfileOutlined />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </ListItem> */}
      {/* <ListItemButton
        selected={selectedIndex === 4}
        onClick={(event) =>
          handleListItemClick(event, 4, "/apps/invoice/details/1")
        }
      >
        <ListItemIcon>
          <WalletOutlined />
        </ListItemIcon>
        <ListItemText primary="Billing" />
      </ListItemButton> */}
      <ListItemButton selected={selectedIndex === 2}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <a
          href="/"
          onClick={async () => {
            resetDataAndLogout();
            await dispatch(logOut(null));
            await dispatch(resetRoleRoute());
          }}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemText primary="Logout" />
        </a>
      </ListItemButton>
    </List>
  );
}

ProfileTab.propTypes = { handleLogout: PropTypes.func };
